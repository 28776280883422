// import {
// 	faQuestionCircle, faInfoCircle
// } from "@fortawesome/pro-solid-svg-icons";

// import {
// 	faAws, faMicrosoft, faGoogle

// } from "@fortawesome/free-brands-svg-icons";

import * as Models from './shared-models';

class AppConstants {
	static readonly appName: string = 'Customer Portal';
	static readonly fullAppName: string = 'Zixi Customer Portal';

	static readonly displayPenTests: boolean = true;
	static readonly showSelfRegistration = true;

	static readonly termsURL = 'https://zixi.com/zixi-software-terms-of-use/';
	static readonly supportURL = 'https://zixi.zendesk.com/hc/en-us';

	static readonly corpName = 'Zixi';
	static readonly corpUrl = 'https://www.zixi.com';

	static readonly twitterUrl = 'https://twitter.com/ZixiNews';
	static readonly linkedInUrl = 'https://www.linkedin.com/company/zixi-llc/';

	static readonly tableDateTimeFmt = 'MMM d, yyyy h:mm a';
	static readonly tableDateFmt = 'MMM d, yyyy';

	static readonly pageDateTimeFmt = 'EEEE MMMM d, yyyy h:mm a';
	static readonly pageDateFmt = 'EEEE MMMM d, yyyy';

	static readonly shortPageDateTimeFmt = 'EEE MMM d, yyyy h:mm a';
	static readonly shortPageDateFmt = 'EEE MMM d, yyyy';

	// static readonly showBxProtocolStatsOverride = 'expose_bx_protocol_stats';

	static readonly xlsNumFormat = '#,##0';
	static readonly bullet = '\u2022';
	// static readonly helpIcon = faQuestionCircle;
	// static readonly infoIcon = faInfoCircle;
	static readonly volumeStorageKey = 'cp-volume';

	static readonly indexPages: number = 5;
	static readonly perPageSelections: number[] = [10, 20, 50, 100, 250, 500, 1000];

	// ****************************************************************************************************************************
	// for server defining routes
	// for client calling server

	static readonly apiUrl: string = '/api/v1';

	static readonly apiUrls = {
		// end points that don't require authorization

		// main endpoint for non-auth'd stuff
		// activation: '/license-key-summary',
		// recommendedBuild: '/recommended-build',

		// main endpoint for non-auth'd stuff
		auth: '/auth',

		// subpoints under auth
		login: '/login',
		sso: '/sso',
		logout: '/logout',
		forgotpassword: '/forgot-password',
		selfregistration: '/self-registration',
		passwordreset: '/reset-password',
		confirmEmail: '/confirm-email',
		autologin: '/auto-login',
		packageinfo: '/package-info',
		unsubscribe: '/unsubscribe',

		aws_marketplacetoken: '/marketplace-token',
		aws_marketplaceregistration: '/marketplace-registration',
		aws_marketplacenotify: '/marketplace-notify',

		azure_marketplacetoken: '/marketplace-token-azure',
		azure_marketplaceregistration: '/marketplace-registration-azure',
		azure_marketplacenotify: '/marketplace-notify-azure',

		gcp_marketplacetoken: '/marketplace-token-gcp',
		gcp_marketplaceregistration: '/marketplace-registration-gcp',
		gcp_marketplacenotify: '/marketplace-notify-gcp',

		// end points that require authorization and varying roles

		// main endpoints for sections of app
		dashboard: '/dashboard',
		users: '/users',
		organizations: '/organizations',
		organizationgroups: '/organization-groups',
		usergroups: '/user-groups',
		licensing: '/licensing',
		licensingadmin: '/licensing-admin',
		zencustomers: '/zen-customers',
		bundles: '/bundles',

		platforms: '/platforms',
		products: '/products',
		builds: '/builds',
		documents: '/documents',
		files: '/files',
		packages: '/packages',
		systemmessages: '/system-messages',
		partneradmin: '/partner-admin',

		downloadlogs: '/download-logs',
		adminlogs: '/admin-logs',
		linklogs: '/link-logs',
		loginlogs: '/login-logs',
		emailqueue: '/email-queue',
		marketplace: '/marketplace',

		calculators: '/calculators',

		appsettings: '/app-settings',

		reports: '/reports',
		backgroundtasks: '/background-tasks',
		quizzes: '/quizzes',

		free: '/free',

		downloads: '/downloads',
		partners: '/partners',
		mysettings: '/my-settings',
		mykeys: '/my-keys',
		filesharing: '/file-sharing',
		myquizzes: '/my-quizzes',
		myquestions: '/my-questions',

	};

	// for building client routes
	static readonly urls = {
		// 'pages/sections' that don't require authorization
		login: 'login',
		sso: 'sso',
		forgotpassword: 'forgot-password',
		selfregistration: 'self-registration',
		passwordreset: 'reset-password',
		confirmEmail: 'confirm-email',
		notfound: 'not-found',
		activation: 'activation',
		unsubscribe: 'unsubscribe',

		aws_marketplaceregistration: 'marketplace-registration',
		azure_marketplaceregistration: 'marketplace-registration-azure',
		gcp_marketplaceregistration: 'marketplace-registration-gcp',

		// 'pages/sections' that require authorization
		dashboard: 'dashboard',
		users: 'users',
		organizations: 'organizations',
		organizationgroups: 'organizations/groups',
		usergroups: 'users/groups',
		zencustomers: 'zen-customers',
		licensing: 'licensing',
		licensingadmin: 'licensing-admin',
		bundles: 'bundles',

		platforms: 'platforms',
		products: 'products',
		builds: 'builds',
		documents: 'documents',
		files: 'files',
		packages: 'packages',
		systemmessages: 'system-messages',
		partneradmin: 'partner-admin',

		downloadlogs: 'download-logs',
		adminlogs: 'admin-logs',
		linklogs: 'link-logs',
		loginlogs: 'login-logs',
		emailqueue: 'email-queue',
		marketplace: 'marketplace',

		calculators: 'calculators',

		appsettings: 'app-settings',

		reports: 'reports',
		backgroundtasks: 'background-tasks',
		quizzes: 'quizzes',

		free: 'free',

		mybuilds: 'my-builds',
		mydocuments: 'my-documents',
		myfilesharing: 'my-file-sharing',
		mykeys: 'my-keys',
		mynotifications: 'my-notifications',
		mypackages: 'my-packages',
		mypartners: 'my-partners',
		mysettings: 'my-settings',
		myquizzes: 'my-quizzes',
		myquestions: 'my-questions',

		// downloads: 'downloads',
		// downloads_builds: 'downloads/builds',
		// downloads_documents: 'downloads/documents',
		// partners: 'partners',
		// mysettings: 'my-settings',
		// mysettings_main: 'my-settings/main',
		// mysettings_notify: 'my-settings/notify',
		// mykeys: 'my-keys',
		// filesharing: 'file-sharing',

	};

	static readonly sectionIcons = {
		dashboard: ['fas', 'tachometer-alt'],
		users: ['fas', 'user'],
		organizations: ['fas', 'building'],
		organizationgroups: ['fas', 'city'],
		usergroups: ['fas', 'users-class'],
		zencustomers: ['fas', 'cloud'],
		licensing: ['fas', 'key'],
		licensingadmin: ['fas', 'key-skeleton'],
		bundles: ['fas', 'file'],

		platforms: ['fas', 'server'],
		products: ['fas', 'cog'],
		builds: ['fas', 'wrench'],
		documents: ['fas', 'books'],
		files: ['fas', 'archive'],
		packages: ['fas', 'box-open'],
		systemmessages: ['fas', 'bullhorn'],
		partneradmin: ['fas', 'puzzle-piece'],

		downloadlogs: ['fas', 'download'],
		adminlogs: ['fas', 'history'],
		linklogs: ['fas', 'external-link-square-alt'],
		loginlogs: ['fas', 'sign-in-alt'],
		emailqueue: ['fas', 'inbox-out'],
		marketplace: ['fas', 'clouds'],

		calculators: ['fas', 'calculator'],

		appsettings: ['fas', 'sliders-h'],

		journals: ['fas', 'book'],

		reports: ['fas', 'file-excel'],
		backgroundtasks: ['fas', 'flag-checkered'],
		quizzes: ['fas', 'graduation-cap'],

		mybuilds: ['fas', 'abacus'],
		mydocuments: ['fas', 'books'],
		myfilesharing: ['fas', 'hands-helping'],
		mykeys: ['fas', 'key'],
		mynotifications: ['fas', 'paper-plane'],
		mypackages: ['fas', 'box-open'],
		mypartners: ['fas', 'puzzle-piece'],
		mysettings: ['fas', 'user-cog'],
		myquizzes: ['fas', 'graduation-cap'],
		myquestions: ['fas', 'clipboard-question'],
		// downloads: ['fas', 'file-download'],
		// downloads_builds: ['fas', 'abacus'],
		// downloads_documents: ['fas', 'books'],
		// partners: ['fas', 'puzzle-piece'],
		// mysettings: ['fas', 'user-cog'],
		// mykeys: ['fas', 'key'],
		// mynotifications: ['fas', 'paper-plane'],
		// filesharing: ['fas', 'hands-helping'],

	};

	static readonly defaultLandingPage = AppConstants.urls.dashboard;

	// ****************************************************************************************************************************
	// user/org cleanup
	// number of days, after which:
	// - a basic user that has never logged in will be deleted
	// - a non-internal organization with no users, no zen masters, no partnerships, no keys
	//   and no record of any download (likely by a deleted user)
	// will be deleted
	static readonly maxDaysUnusedObjects = 60;

	// for self registered basic users not linked to an organization
	static readonly maxShortDaysUnusedObjects = 7;

	// user clean up
	// for users either with with no keys or not attached to an organization
	// and haven't logged in at least 365 days
	static readonly maxDaysStaleUsers = 365;

	// ****************************************************************************************************************************
	// these are internal labels that are also used in the database to set a user's role so
	// if the words are changed
	static readonly basicUserRole = 'basic';
	static readonly staffUserRole = 'staff';
	static readonly adminUserRole = 'admin';

	// the ordering of this array, combined with 'sectionRoles' (below) controls who can use what overall sections of the app via routing
	// some functionality within the sections will be further controlled by the user's role and/or ACLs
	static readonly userRoles = [
		AppConstants.basicUserRole,
		AppConstants.staffUserRole,
		AppConstants.adminUserRole,
	];

	static readonly userRoleLabels = {
		basic: 'Basic',
		staff: 'Staff',
		admin: 'Admin',
	};

	static readonly userRoleShortLabels = {
		basic: 'Basic',
		staff: 'Staff',
		admin: 'Admin',
	};

	static readonly userRoleIcons = {
		basic: ['fas', 'grin-beam'],
		staff: ['fas', 'user-graduate'],
		admin: ['fas', 'user-astronaut']
	};

	static readonly userRoleExtras = {
		basic:
			'Intended for customers and partners. Can download files from Builds and Documents that they\'re allowed to access (via their Organization and/or Packages);',
		staff:
			'Read-only access to most objects in the system.  Add/Edit/Delete/Download access granted via access tags (assigned to user and/or group).',
		admin:
			'Add/Edit/Delete/Download everything.  Can manage users of any role.  Can manage access tags for users and user groups.',
	};

	// what users can be managed by someone of a certain role???
	static readonly manageableRoles = {
		basic: [],
		staff: [AppConstants.basicUserRole],
		admin: [AppConstants.basicUserRole, AppConstants.staffUserRole, AppConstants.adminUserRole],
	};

	static readonly userFlags = [
	];

	// 	{ key: 'show_keys', label: 'User can see keys linked to their Organization' },
	// 	{ key: 'show_billing_codes', label: 'User can see their Organization\'s billing codes' },
	// 	{ key: 'manage_billing_codes', label: 'User can manage their Organization\'s billing codes' }

	// ****************************************************************************************************************************
	// user access control tags

	// these are all the tags implemented in the app...
	static readonly accessControlTags = {
		'access-all-builds-and-documents': 'Can download all Builds and Documents regardless of access',

		'manage-organizations-customer': 'Add/Edit "Customer" Organizations',
		'manage-organizations-partner': 'Add/Edit "Partner" Organizations',
		'manage-organizations-internal': 'Add/Edit "Internal" Organizations',

		'delete-organizations': 'Delete editable Organizations',

		'manage-basic-users-no-org': 'Add/Edit Basic Users not associated with an Organization',

		'manage-basic-users-customer': 'Add/Edit Basic Users from "Customer" Organizations',
		'manage-basic-users-partner': 'Add/Edit Basic Users from "Partner" Organizations',
		'manage-basic-users-internal': 'Add/Edit Basic Users from "Internal" Organizations',

		'delete-unused-basic-users': 'Delete editable Basic Users that have not been welcomed or logged in',
		'delete-any-user': 'Delete any editable User',

		'promote-basic-to-staff': 'Can promote Basic Users to Staff',

		'setup-user-with-sales-engineer-notifications': 'Can setup staff member to get SE/Tech-Rep key notifications',
		'setup-user-with-account-owner-notifications': 'Can setup staff member to get account owner key notifications',

		'manage-partnerships': 'Add/Edit/Delete Partner entries tied to Organizations',
		'manage-system-messages': 'Add/Edit/Delete Dashboard System Messages',
		'add-journals': 'Add journal entries to any object that support them',

		'manage-keys': 'Allowed to create/edit keys with assigned Templates',
		'change-key-template': 'Set/Change a Key\'s Template',
		'set-key-write-access': 'Can grant write access to a key to a staff member that doesn\'t have it',

		'manage-license-key-setup': 'Add/Edit/Delete License Key Products + Properties',
		'manage-key-templates': 'Add/Edit/Delete Key Templates',
		'manage-protocol-sets': 'Manage Protocol Sets',
		'link-protocol-sets-to-keys': 'Can link/unlink one or more protocol sets to keys',

		'manage-commercial': 'Allowed to set/update commercial type/info on license keys',
		'configure-key-for-marketplace': 'Can set/unset a license key to report to a marketplace',

		'run-stats-roll-up': 'Can kick off stats (meter/protocol) daily roll-up',
		'run-bx-pen-tests-task': 'Can kick off broadcaster pen tests',

		'manage-zen-master': 'Can Manage ZEN Master Sites (set/change organization, type, and/or marketplace info)',
		'admin-zen-master': 'Can Create/Launch New ZEN Master Sites and Edit Sites',
		'admin-zen-master-staff': 'Can Manage Zixi Staff Access to ZEN Master',
		'disable-zen-master': 'Can Disable/Shut Down Any ZEN Master Site',
		'enable-zen-master': 'Can Enable/Start Any ZEN Master Site',
		'delete-zen-master': 'Can Delete ZEN Master Sites',
		'disable-zen-master-as-delegate': 'Can Disable/Shut Down a ZEN Master Site if User is SE/Tech-Rep',
		'enable-zen-master-as-delegate': 'Can Enable/Start a ZEN Master Site if User is SE/Tech-Rep',

		'configure-zen-for-marketplace': 'Can set/unset a ZEN Master site to report to a marketplace',

		'create-special-zen-master-keys': 'Can kick off Automated Tasks that Create Special ZEN Master keys',
		'report-marketplace-usage': 'Can kick off Automated Tasks that Report Traffic For Marketplace Subscriptions',

		// 'send-zen-master-shutdown-notice': 'Can send account team ZEN Master shutdown notices',

		'manage-files': 'Upload/Edit Files',
		'manage-builds': 'Add/Edit Builds',
		'manage-documents': 'Add/Edit Documents',
		'delete-unused-files': 'Delete editable Files that have never been downloaded',
		'delete-files': 'Delete any editable File',
		'delete-builds': 'Delete editable Builds',
		'delete-documents': 'Delete editable Documents',
		'can-see-free-tokens': 'Can see free download tokens on files (if set)',

		'manage-user-groups': 'Add/Edit User Groups',
		'manage-organization-groups': 'Add/Edit Organization Groups',
		'manage-packages': 'Add/Edit Packages',
		'manage-products': 'Add/Edit Products related to Builds and Documents',
		'manage-platforms': 'Add/Edit Platforms',

		'delete-user-groups': 'Delete editable User Groups',
		'delete-organization-groups': 'Delete editable Organization Groups',
		'delete-packages': 'Delete editable Packages',
		'delete-products': 'Delete editable Products related to Builds and Documents',
		'delete-platforms': 'Delete editable Platforms',

		'view-billing-codes': 'Can view billing codes (with authorization code) for an Organization',
		'manage-billing-codes': 'Can manage billing codes for editable Organizations',
		'validate-billing-codes': 'Can validate billing codes via the API',

		'send-build-notifications': 'Can send build e-mail notifications',
		'send-document-notifications': 'Can send document e-mail notifications',
		'send-product-notifications': 'Can send product e-mail notifications',
		'send-general-notifications': 'Can send general e-mail notifications',
		'run-automated-notifications': 'Can kick off automated e-mail notifications',
		'manage-email-queue': 'Can manage the e-mail queue (flush queue, update status, direct add)',

		'create-staff-send-bundle': 'Can create bundles to send files to other users as themself',
		'create-group-send-bundle': 'Can create bundles to send files to other users from one of their groups',
		'create-staff-receive-bundle': 'Can create bundles to have files sent to them',
		'create-group-receive-bundle': 'Can create bundles to have files sent to one of their groups',
		'run-automated-bundle-cleanup': 'Can kick off automated file/bundle clean up routines',

		'run-user-clean-up': 'Can kick off automated unused user clean up routine',
		'run-organization-clean-up': 'Can kick off automated unused organization clean up routine',
		'run-email-clean-up': 'Can kick off automated unused e-mail queue clean up routine',

		'run-usage-data-clean-up': 'Can kick off automated clean up of meter and protocol usage data',
		'run-background-task-clean-up': 'Can kick off automated clean up of background task records',

		'run-zen-pull-targets-report': 'Can run the Music Choice Specific Zen Master Pull Targets Report',

		'manage-quizzes': 'Can manage any Quiz/Poll',
		'add-quizzes': 'Can start and manage a Quiz/Poll',

		'run-dashboard-refresh': 'Can kick off automated refresh of dashboard top usage data',
		'run-sanity-check': 'Can kick off system wide sanity check',

		'compare-meters-to-protocol': 'Can compare meter usage to equivalent protocol usage',

	};
	// 'experimental-key-edit': 'Add/Edit Keys (Experimental)',

	static readonly accessControlTagGroupings = [
		{
			grouping: 'Downloads',
			tags: ['access-all-builds-and-documents']
		}, {
			grouping: 'Organizations',
			tags: [
				'manage-organizations-customer',
				'manage-organizations-partner',
				'manage-organizations-internal',
				'delete-organizations']
		}, {
			grouping: 'Users',
			tags: [
				'manage-basic-users-no-org',
				'manage-basic-users-customer',
				'manage-basic-users-partner',
				'manage-basic-users-internal',
				'delete-unused-basic-users',
				'delete-any-user',
				'promote-basic-to-staff',
				'setup-user-with-sales-engineer-notifications',
				'setup-user-with-account-owner-notifications']
		}, {
			grouping: 'License Keys',
			tags: [
				'manage-keys',
				'change-key-template',
				'set-key-write-access',
				'manage-key-templates',
				'manage-license-key-setup',
				'manage-protocol-sets',
				'link-protocol-sets-to-keys',
				'manage-commercial',
				'configure-key-for-marketplace',
				'compare-meters-to-protocol'
			]
		}, {
			grouping: 'ZEN Master',
			tags: [
				'manage-zen-master',

				'admin-zen-master',
				'admin-zen-master-staff',
				'disable-zen-master',
				'enable-zen-master',
				'delete-zen-master',

				'disable-zen-master-as-delegate',
				'enable-zen-master-as-delegate',

				'configure-zen-for-marketplace',

				'run-zen-pull-targets-report',
			]
		}, {
			grouping: 'Misc.',
			tags: [
				'manage-partnerships',
				'manage-system-messages',
				'add-journals',
				'manage-quizzes',
				'add-quizzes'
			]
		}, {
			grouping: 'Billing Codes',
			tags: [
				'view-billing-codes',
				'manage-billing-codes',
				'validate-billing-codes'
			]
		}, {
			grouping: 'Files, Builds and Documents',
			tags: [
				'manage-files',
				'manage-builds',
				'manage-documents',
				'delete-files',
				'delete-unused-files',
				'delete-builds',
				'delete-documents',
				'can-see-free-tokens',
			]
		}, {
			grouping: 'Notifications',
			tags: [
				'send-build-notifications',
				'send-document-notifications',
				'send-general-notifications',
				'send-product-notifications',
			]

		}, {
			grouping: 'File Sharing Bundles',
			tags: [
				'create-staff-send-bundle',
				'create-group-send-bundle',
				'create-staff-receive-bundle',
				'create-group-receive-bundle',
			]
		}, {
			grouping: 'Admin Sections',
			tags: [
				'manage-user-groups',
				'manage-organization-groups',
				'manage-packages',
				'manage-products',
				'manage-platforms',
				'delete-user-groups',
				'delete-organization-groups',
				'delete-packages',
				'delete-products',
				'delete-platforms',
			]
		}, {
			grouping: 'Automated Tasks',
			tags: [
				'run-organization-clean-up',
				'run-user-clean-up',
				'run-stats-roll-up',
				'run-usage-data-clean-up',
				'run-bx-pen-tests-task',
				'create-special-zen-master-keys',
				'report-marketplace-usage',
				'run-background-task-clean-up',
				'run-automated-notifications',
				'manage-email-queue',
				'run-email-clean-up',
				'run-automated-bundle-cleanup',
				'run-dashboard-refresh',
				'run-sanity-check'
			]
		}
	];

	// users with these roles inherit these AC Tags
	static readonly roleAccessControlTags = {
		basic: [],
		staff: [],
		admin: ['*']
	};

	// ****************************************************************************************************************************
	// products

	// these are internal labels that are also used in the database to set a product's type so
	// if the words are changed
	static readonly standardProductType = 'standard';
	static readonly desktopProductType = 'desktop';
	static readonly documentProductType = 'document';
	static readonly mobileProductType = 'mobile';
	static readonly cloudProductType = 'cloud';
	static readonly partnerProductType = 'partner';
	static readonly integratorProductType = 'integration';

	// labels for product's type...
	static readonly standardProductTypeLabel = 'Standard';
	static readonly desktopProductTypeLabel = 'Desktop';
	static readonly documentProductTypeLabel = 'Document';
	static readonly mobileProductTypeLabel = 'Mobile';
	static readonly cloudProductTypeLabel = 'Cloud';
	static readonly partnerProductTypeLabel = 'Partner';
	static readonly integratorProductTypeLabel = 'Integration';

	static readonly productTypes = [
		AppConstants.standardProductType,
		AppConstants.desktopProductType,
		AppConstants.documentProductType,
		// AppConstants.mobileProductType,
		// AppConstants.cloudProductType,
		AppConstants.partnerProductType,
		AppConstants.integratorProductType,
	];

	static readonly productTypeLabels = {
		standard: AppConstants.standardProductTypeLabel,
		desktop: AppConstants.desktopProductTypeLabel,
		document: AppConstants.documentProductTypeLabel,
		mobile: AppConstants.mobileProductTypeLabel,
		cloud: AppConstants.cloudProductTypeLabel,
		partner: AppConstants.partnerProductTypeLabel,
		integration: AppConstants.integratorProductTypeLabel,
	};

	static readonly productFlags = [
		{ key: 'private_only', label: 'This Product will only have private Builds and Documents.' },
		{ key: 'may_have_builds', label: 'This Product may be used for Builds.' },
		{ key: 'may_have_documents', label: 'This Product may be used for Documents.' },
		{ key: 'builds_require_label', label: 'Builds for this Product require a label.' }
	];

	// ****************************************************************************************************************************
	// platforms

	// these are internal labels that are also used in the database to set a plaform's type so
	// if the words are changed
	static readonly generalPlatformType = 'general';
	static readonly toolchainPlatformType = 'toolchain';

	// labels for plaform's type...
	static readonly generalPlatformTypeLabel = 'General';
	static readonly toolchainPlatformTypeLabel = 'Tool-Chain';

	static readonly platformTypes = [
		AppConstants.generalPlatformType,
		AppConstants.toolchainPlatformType,
	];

	static readonly platformTypeLabels = {
		general: AppConstants.generalPlatformTypeLabel,
		toolchain: AppConstants.toolchainPlatformTypeLabel,
	};

	// ****************************************************************************************************************************
	// org's type...simplified...

	static readonly nullOrgTypeForKeySearch = '--null--';

	static readonly organizationTypes = [
		'customer',
		'partner',
		'internal'
	];

	static readonly organizationTypeLabels = {
		customer: 'Customer',
		partner: 'Partner',
		internal: 'Internal'
	};

	// what product types can be assigned to what orgs
	static readonly organizationTypeProductTypes = {
		'**all**': [
			AppConstants.standardProductType,
			AppConstants.desktopProductType,
			AppConstants.documentProductType,
			AppConstants.partnerProductType],
		partner: [
			AppConstants.integratorProductType],
		internal: [
			AppConstants.integratorProductType]
	};

	static readonly strictOrganizationTypes = [
		'customer',
		'partner'
	];

	static readonly organizationFlags = [
		{
			key: 'no_users',
			label: 'No users',
			info: 'This organization is not meant to have users.  This option is not normally used for customer/partner organizations.'
		}, {
			key: 'no_keys',
			label: 'No keys',
			info: 'This organization is not meant to have license keys.  This option is not normally used for customer/partner organizations.'
		}, {
			key: 'no_partnerships',
			label: 'No partnerships',
			info: 'This organization is not meant to have partnerships.  This option is not normally used for customer/partner organizations.'
		}, {
			key: 'no_zen_sites',
			label: 'No zen-sites',
			info: 'This organization is not meant to have ZEN Master sites.  This option is not normally used for customer/partner organizations.'
		}, {
			key: 'no_billing_codes',
			label: 'No billing-codes',
			info: 'This organization is not meant to billing codes.  This option is not normally used for customer/partner organizations.'
		}
	];

	static readonly adminOnlyOrganizationFlags = [
		{
			key: 'restricted',
			label: 'Restricted Access Organization (admin only)',
			info: 'This organization can only be updated by an admin level user.  Furthermore, users attached to this organization can only be managed by admin level users.'
		}
	];

	// ****************************************************************************************************************************
	// packages

	// these are internal labels that are also used in the database to set a packages's type so
	// if the words are changed
	static readonly basicPackageType = 'basic';
	static readonly basicWithKeysPackageType = 'basic_with_keys';

	// labels for packages's type...
	static readonly basicPackageLabel = 'Basic';
	static readonly basicWithKeysPackageLabel = 'Basic (with keys)';

	static readonly packageTypes = [
		AppConstants.basicPackageType,
		AppConstants.basicWithKeysPackageType,
	];

	static readonly packageTypeLabels = {
		basic: AppConstants.basicPackageLabel,
		basic_with_keys: AppConstants.basicWithKeysPackageLabel,
	};

	// ****************************************************************************************************************************
	// Defaults
	// ****************************************************************************************************************************
	static readonly defaultPlatformType: string = AppConstants.generalPlatformType;
	static readonly defaultProductType: string = AppConstants.standardProductType;
	static readonly defaultOrganizationType: string = AppConstants.organizationTypes[0];
	static readonly defaultProductHasDownloads = 1;
	static readonly defaultPackageType: string = AppConstants.basicPackageType;

	static readonly maxFilesToUpload = 20;
	static readonly passwordResetMinutes: number = 60 * 12; // 12 hours
	static readonly welcomeResetMinutes: number = 60 * 24; // 24 hours

	// ****************************************************************************************************************************
	// User Specific
	// ****************************************************************************************************************************

	static readonly salesforceContactIDKey = 'salesforce_contact_id';
	static readonly salesforceLeadIDKey = 'salesforce_lead_id';

	static readonly marketoLeadProcessedKey = 'marketo_lead_processed';

	static readonly marketoLeadIDKey = 'marketo_lead_id';
	static readonly marketoCookieKey = 'marketo_munchkin_cookie';

	static readonly stagedEmailKey = 'STAGED_EMAIL';
	static readonly stagedEmailTokenKey = 'EMAIL_CHANGE_TOKEN';
	static readonly starIconKey = 'star_icon_preference';

	static readonly loginPageStaffIcons: any[] = [
		'hat-wizard',
		'cat-space',
		'gingerbread-man',
		'user-alien',
		'user-cowboy',
		'user-astronaut',
		'user-ninja',
		'badger-honey',
		'rabbit',
		'narwhal',
		'unicorn',
		'grin-beam',
		'coffee',
		'duck',
		'snowman',
		'ghost',
		'hand-spock',
		'mushroom',
		'person-pinball',
		'otter',
		'sheep',
		'monkey',
		'dolphin',
		't-rex',
		'turtle',
		'lobster',
		'dinosaur',
		'squid',
		'alien-8bit',
		'pool-8-ball',
		'robot',
		'squid'
	];

	static readonly favoriteIcons: any[] = [
		'star',
		'badger-honey',
		'beer',
		'burger-soda',
		'cat-space',
		'cocktail',
		'coffee',
		'dragon',
		'dreidel',
		'duck',
		'fist-raised',
		'futbol',
		'ghost',
		'gingerbread-man',
		'grin-beam',
		'hand-spock',
		'narwhal',
		'pastafarianism',
		'pepper-hot',
		'pizza-slice',
		'rabbit',
		'sack-dollar',
		'skull-crossbones',
		'snowman',
		'star-of-david',
		'unicorn',
		'user-alien',
		'user-astronaut',
		'user-cowboy',
		'user-ninja',
		'yin-yang'
	];


	// ****************************************************************************************************************************
	// ZEN Master
	// ****************************************************************************************************************************

	static readonly zenTemplateToken = '##PREFIX##';
	static readonly zenImpersonateUrlTemplate = 'https://zixi.zen.zixi.com/api/impersonate/##PREFIX##';
	static readonly zenLoginUrlTemplate = 'https://##PREFIX##.zen.zixi.com/';

	static readonly zenMasterTypes = [
		'standard-poc',
		'standard-production',
		'zaas-poc',
		'zaas-production',
		'lab',
		'internal'
	];

	static readonly zenMasterTypeLabels = {
		'standard-poc': 'POC (Std)',
		'standard-production': 'Production (Std)',
		'zaas-poc': 'POC (ZaaS)',
		'zaas-production': 'Production (ZaaS)',
		'lab': 'Lab/Test',
		'internal': 'Internal'
	};

	static readonly assessmentThresholdDays = 90;

	static readonly zenMasterAssessments = [
		'Active',
		'Disabled',
		'No logins: <' + AppConstants.assessmentThresholdDays + ' days',
		'No logins: >' + AppConstants.assessmentThresholdDays + ' days',
		'Inactive: >' + AppConstants.assessmentThresholdDays + ' days',
	];

	static readonly zenMasterReportModes = [
		'base',
		'sources',
		'channels',
		'targets',
		'cloud',
		'users',
		'features'
	];

	static readonly zenMasterReportModeLabels = {
		base: 'Components',
		sources: 'Sources',
		channels: 'Channels',
		targets: 'Targets',
		cloud: 'Cloud',
		users: 'Users',
		features: 'Features'
	};

	static readonly zenInstanceTypes = [
		't4g.medium',
		// 't3a.medium',
		// 't3a.xlarge',
		// 't2.medium',
		// 't2.xlarge'
	];

	static readonly restrictedZenNames = [
		'zixi',
		'mediaconnect'
	];

	static readonly zenStaffAccessLevels = [
		{
			level: 'no-account',
			short: 'n/a',
			label: 'No User Account'
		},
		{
			level: 'not-support',
			short: '',
			label: 'No Support Access'
		},
		{
			level: 'support-readonly',
			short: 'r',
			label: 'Support - read only'
		},
		{
			level: 'support-write',
			short: 'w',
			label: 'Support - read and write'
		}
	];


	// ****************************************************************************************************************************
	// License Keys
	// ****************************************************************************************************************************

	// static readonly basicUserProtocolStart: Date = new Date('2021/01/01 00:00:00 UTC');

	// license clean up - clean out licenses for keys with discard_licenses = 1
	// where license was created or last updated more then N days ago
	static readonly maxStaleLicenseDays = 7;

	static readonly hostOfflineMinDays = 1.9;
	static readonly hostOfflineMaxDays = 4;

	static readonly keyChopLength = 7;

	static readonly keyExpiryWarningDays = 30;
	static readonly keyExpiryWarningDaysAlt = 14;
	static readonly keyWarningUsageThreshold = 80;

	static readonly keyActivationsWarningThreshold = 80;

	static readonly monthlyMeterFillUpMinDays = 7;

	static readonly keyWarningPrefixKeyExpiring = 'key_expiring';
	static readonly keyWarningPrefixKeyExpired = 'key_expired';
	static readonly keyWarningPrefixMeterExpiring = 'meter_expiring';
	static readonly keyWarningPrefixMeterExpired = 'meter_expired';

	static readonly keyWarningPrefixMeterMaximum = 'meter_maximum';
	static readonly keyWarningPrefixMeterProjected = 'meter_projected';
	static readonly keyWarningPrefixProtocolProjected = 'protocol_projected';

	static readonly keyWarningPrefixMeterMaximumEndOfMonth = 'meter_maximum_end_of_month';
	static readonly keyWarningPrefixMeterProjectedEndOfMonth = 'meter_projected_end_of_month';
	static readonly keyWarningPrefixProtocolProjectedEndOfMonth = 'protocol_projected_end_of_month';

	static readonly keyWarningPrefixPermanent = 'key_permanent';
	static readonly keyWarningPrefixDisabled = 'key_disabled';

	static readonly classUserEmailDomain = '@zixi.com';

	static readonly numRecentViewedKeys = 20;

	static readonly maxActiveLicenseDays = 2;
	static readonly recentUsedHostsDays = 7;
	static readonly numProtocolsPerBlock = 10;
	static readonly numMonthsPerBlock = 6;

	static readonly maxKeysToFetch = 1000;

	static readonly protocolTrafficMonthsToShow = 12;

	static readonly keyUsageMonthsBack = 36;
	static readonly maxDailyUsageRowsToFetch = 200000;
	static readonly maxHostUsageRowsToFetch = 5000;
	static readonly maxLicenseRowsToFetch = 1000;

	static readonly maxKeysToRegisterPerSubmit = 50;
	static readonly maxKeysPerOrgUsageReport = 30;

	static readonly maxOfflineHostIDsPerShot = 20;

	static readonly maxDaysForRecentLicenses = 2;

	static readonly maxAggregateKeys = 100;

	// stats roll up will ignore rows in usage_records that are greater than this
	// to avoid bug in ZM reporting
	static readonly maxUsageRecordBytes = 2000000000000000; // 2 PB

	// related to creating keys
	static readonly maxKeysToCreate = 5000; // just a practical limit...
	static readonly maxActivationMax = 10000000;  // technically limit in DB could be 2^(32-1)-1 or 2,147,483,647
	static readonly maxPropertyField = 10000000;  // technically limit in DB could be 2^(32-1)-1 or 2,147,483,647

	// setting this to 9000000000000000 to stay under javascript's max integer for number (9007199254740991)
	// if this needs to be bigger, should move to BigInt instead of number
	// probably need to adjust Model for Meter and other places
	static readonly maxMeterLimit = 9000000000000000; //  9 ZB - technically limit in DB could be 2^(64-1)-1 or 9,223,372,036,854,775,807

	static readonly keyTypes = ['demo', 'production', 'lab', 'backup', 'offline-demo', 'offline-production', 'offline-lab', 'offline-backup'];

	static readonly keyInfoFieldLabel = 'Info/Purpose/Label';

	// top usage
	static readonly topUsageCount = 50;
	static readonly topUsageIgnoreProducts: string[] = ['broadcaster_special', 'broadcaster_zenm_aws_mx', 'broadcaster_zenm_generic'];
	static readonly topUsageKeyTypes = ['production', 'demo', 'lab'];
	static readonly topUsageTimePeriods: any[] = [
		{
			value: 'last-7',
			label: 'Last Week',
			numDays: 7
		}, {
			value: 'last-30',
			label: 'Last 30 Days',
			numDays: 30
		}, {
			value: 'last-365',
			label: 'Last Year',
			numDays: 365
		}
	];


	static readonly offlinePrefix = 'offline-';

	static readonly maxKeysForPopovers = 200;

	static readonly penTestCodes: any = {
		'-99': {
			label: 'Not tested',
			icon: ['fas', 'square'],
			css: 'text-secondary'
		},
		'-1': {
			label: 'Unknown',
			icon: ['fas', 'question-square'],
			css: 'text-danger'
		},
		0: {
			label: 'Failed - open to internet, def. username/password',
			icon: ['fas', 'lock-open'],
			css: 'text-danger'
		},
		1: {
			label: 'Passed - open to internet',
			icon: ['fas', 'lock'],
			css: 'text-success'
		},
		2: {
			label: 'Passed - not open to internet',
			icon: ['fas', 'octagon'],
			css: 'text-success'
		},
		3: {
			label: 'Unclear - could not do test - Bx locked',
			icon: ['fas', 'octagon'],
			css: 'text-warning'
		}
	};

	// special keys like the ones that use these prefixes are never enabled...
	static readonly specialKeyPrefixes: string[] = ['offline-', 'zm-', 'mediaconnect-', 'nokey-'];

	static readonly searchableKeyTypesSelections = [
		{ value: 'demo', label: 'Demo' },
		{ value: 'production', label: 'Production' },
		{ value: 'lab', label: 'Lab' }
	];

	static readonly searchablProductSelections = [
		{ value: 'broadcaster*', label: 'Zixi Broadcaster (all Bx key schemes)' },
		{ value: 'receiver', label: 'Zixi Receiver' },
		{ value: 'feeder', label: 'Zixi Feeder' },
		{ value: 'mediaconnect_fx', label: 'MediaConnect Feeder' },
		{ value: 'mediaconnect_rx', label: 'MediaConnect Receiver' },
		{ value: 'zec', label: 'ZEC' }
	];


	static readonly meterTypeObjects = {
		output_mb: {
			label: 'All Outputs',
			fullLabel: 'All Outputs: all egress',
			description: 'All egress traffic. (old)',
			initials: 'AO'
		},
		output_mb_meter: {
			label: 'All Outputs',
			fullLabel: 'All Outputs: all egress',
			description: 'All egress traffic.',
			initials: 'AO'
		},
		protected_mb: {
			label: 'Protected',
			fullLabel: 'Protected: Zixi Push In/Pull Out + RIST + SRT',
			description: 'All Zixi push in, Zixi pull out, RIST in, RIST out, SRT in and SRT out traffic',
			initials: 'P'
		}
	};

	static readonly meterProducts: string[] = Object.keys(AppConstants.meterTypeObjects);


	// static readonly meterLabels = {
	// 	output_mb: 'All Outputs',
	// 	output_mb_meter: 'All Outputs',
	// 	protected_mb: 'Protected: Zixi Push In/Pull Out + RIST + SRT'
	// };

	// static readonly shortMeterLabels = {
	// 	output_mb: 'All Outputs',
	// 	output_mb_meter: 'All Outputs',
	// 	protected_mb: 'Protected'
	// };

	// static readonly meterAbbreviations = {
	// 	output_mb: 'AO',
	// 	output_mb_meter: 'AO',
	// 	protected_mb: 'P'
	// };

	// static readonly extraMeterTypeInfo = {
	// 	output_mb: 'All Outputs: At one point, there was a change to the mechanics of meters and a new meter type was add for \"All Outputs\".  Individual keys will never have both types, but when viewing usage across multiple keys, you might see two separate measurements that are both labeled \"All Outputs\".',
	// 	output_mb_meter: 'All Outputs: At one point, there was a change to the mechanics of meters and a new meter type was add for \"All Outputs\".  Individual keys will never have both types, but when viewing usage across multiple keys, you might see two separate measurements that are both labeled \"All Outputs\".',
	// 	protected_mb: ''
	// };

	static readonly timeProtocolSuffix = '_secs';
	static readonly licenseMetricTypes: string[] = ['meter-data', 'protocol-data', 'protocol-time'];

	static readonly meterResetOptions = [
		{ value: 'never', label: 'Never' },
		{ value: 'monthly', label: 'Monthly' }
	];

	static readonly keyExpiryModes = [
		{ value: 'date', label: 'Fixed Expiration Date' },
		{ value: 'duration', label: 'Duration (# days from issuing license to device)' },
		{ value: 'meter', label: 'Meter Expiration - must be used if key has meter(s)' },
		{ value: 'never', label: 'No Expiry/Permanent' }
	];

	static readonly keyExpiryShortLabels = [
		{ value: 'date', label: 'Fixed Expiration Date' },
		{ value: 'duration', label: 'Duration' },
		{ value: 'meter', label: 'Meter Expiration' },
		{ value: 'never', label: 'No Expiry' }
	];

	static readonly keyExpiryLabels = [
		{ value: 'date', label: 'Fixed Expiration Date - all licenses issued expire on a specific date' },
		{ value: 'duration', label: 'Duration - # days from issuing license to device' },
		{ value: 'meter', label: 'Meter Expiration - use expiration date(s) from meter(s)' },
		{ value: 'never', label: 'No Expiry - permanent licenses' }
	];

	static readonly keyDurationOptions = [
		{ value: null, label: '--' },
		{ value: 2, label: '2 Days' },
		{ value: 7, label: '7 Days' },
		{ value: 14, label: '14 Days' },
		{ value: 21, label: '21 Days' },
		{ value: 90, label: '3 Months' },
		{ value: 365, label: '1 Year' }
	];

	static readonly keyDurationOptionsExtras = AppConstants.keyDurationOptions.concat(
		{ value: -1, label: 'JVC ProHD (13 months)' });

	static readonly expiryButtons = [
		{ amount: 7, unit: 'days', label: '1 wk' },
		{ amount: 14, unit: 'days', label: '2 wk' },
		{ amount: 1, unit: 'months', label: '1 mth' },
		{ amount: 6, unit: 'months', label: '6 mth' },
		{ amount: 1, unit: 'years', label: '1 yr' },
		{ amount: 2, unit: 'years', label: '2 yr' },
		{ amount: 3, unit: 'years', label: '3 yr' },
	];

	static readonly dateMathButtons = [
		{ amount: 7, unit: 'days', label: '+1 week' },
		{ amount: 1, unit: 'months', label: '+1 month' },
		{ amount: 1, unit: 'years', label: '+1 year' },
	];

	static readonly activationMathButtons = [
		{ amount: 1, label: '+1' },
		{ amount: 5, label: '+5' },
		{ amount: 10, label: '+10' },
		{ amount: 25, label: '+25' },
	];

	static readonly protectedProtocolSetName: string = 'Protected Traffic';
	static readonly allOutputsProtocolSetName: string = 'All Outputs Traffic';

	static readonly specialKeySearches = [
		{
			value: 'specials_or_search',
			label: 'Treat specialized filters as OR search instead of AND',
			info: 'Unless this option is selected, all special filter selections will be evaluated together.  In other words, each condition/filter must be true.'
		},

		{
			value: 'key_enabled',
			label: 'Key is enabled',
			info: 'Key is currently valid (activated).'
		},
		{
			value: 'key_disabled',
			label: 'Key is disabled',
			info: 'Key has been deactivated.'
		},

		{
			value: 'has_not_expired',
			label: 'Key hasn\'t expired',
			info: 'Key is enabled and hasn\'t expired - either permanent, duration based, has an expiration that hasn\'t passed or uses meters that haven\'t expired.'
		},
		{
			value: 'has_expired',
			label: 'Key has expired',
			info: 'Key has has expired - either non-permanent with an expiration that has passed or uses meters that have expired.'
		},

		{
			value: 'keys_that_need_attention',
			label: 'Key that might need attention',
			info: 'Look for keys that are enabled and are about to expire (within ' + AppConstants.keyExpiryWarningDays + ' days) '
				+ ' and/or the key\'s meter(s) are at or over ' + AppConstants.keyWarningUsageThreshold + '% its maximum'
				+ ' and/or the key\'s monthly meter(s) look like they might reach their maximum before the end of the month'
				+ ' and/or the key\'s meter is at or over ' + AppConstants.keyWarningUsageThreshold + '% projected threshold'
				+ ' and/or the key\'s monthly meter(s) look like they might reach their projected threshold before the end of the month'
				+ ' and/or the key\'s linked protocol set is at or over ' + AppConstants.keyWarningUsageThreshold + '% projected threshold'
				+ ' and/or the key\'s linked protocol set look like they might reach their projected threshold before the end of the month'
		},

		{
			value: 'about_to_expire',
			label: 'Will expire in next ' + AppConstants.keyExpiryWarningDays + ' days',
			info: 'Key is enabled\n'
				+ ' AND Key has an expiry that\'s within the next ' + AppConstants.keyExpiryWarningDays + ' days'
				+ ' OR Key has a Meter that will expire in the next ' + AppConstants.keyExpiryWarningDays + ' days and does not have a Meter of the same type that hasn\'t expired yet and won\'t in the next ' + AppConstants.keyExpiryWarningDays + ' days.'
		},
		{
			value: 'about_to_expire_alt',
			label: 'Will expire in next ' + AppConstants.keyExpiryWarningDaysAlt + ' days',
			info: 'Key is enabled\n'
				+ ' AND Key has an expiry that\'s within the next ' + AppConstants.keyExpiryWarningDaysAlt + ' days'
				+ ' OR Key has a Meter that will expire in the next ' + AppConstants.keyExpiryWarningDaysAlt + ' days and does not have a Meter of the same type that hasn\'t expired yet and won\'t in the next ' + AppConstants.keyExpiryWarningDaysAlt + ' days.'
		},
		{
			value: 'recently_expired',
			label: 'Has expired in last ' + AppConstants.keyExpiryWarningDays + ' days',
			info: 'Key is enabled\n'
				+ ' AND Key has an expiry that\'s within the last ' + AppConstants.keyExpiryWarningDays + ' days'
				+ ' OR Key has a Meter that has expired in the last ' + AppConstants.keyExpiryWarningDays + ' days and does not have a Meter of the same type that hasn\'t expired yet.'
		},
		{
			value: 'near_usage_limit',
			label: 'Meter at or over ' + AppConstants.keyWarningUsageThreshold + '% maximum',
			info: 'Meters are enforced. The key has at least one unexpired meter that\'s over ' + AppConstants.keyActivationsWarningThreshold + '% of its maximum.'
		},
		{
			value: 'near_usage_limit_include',
			label: 'Meter at or over ' + AppConstants.keyWarningUsageThreshold + '% maximum (include unenforced)',
			info: 'Meters may or may not be enforced. The key has at least one unexpired meter that\'s over ' + AppConstants.keyActivationsWarningThreshold + '% of its maximum.'
		},

		{
			value: 'meter_might_run_out',
			label: 'Monthly meter(s) might reach maximum',
			info: 'Meters are enforced. The key has at least one monthly meter and, based on its rate of use, may exceed its maximum for the month. This warning will only start appearing after ' + AppConstants.monthlyMeterFillUpMinDays + ' days into the month.'
		},
		{
			value: 'meter_might_run_out_include',
			label: 'Monthly meter(s) might reach/pass maximum (include unenforced)',
			info: 'Meters may or may not be enforced. The key has at least one monthly meter and, based on its rate of use, may exceed its maximum for the month. This warning will only start appearing after ' + AppConstants.monthlyMeterFillUpMinDays + ' days into the month.'
		},

		{
			value: 'near_projected',
			label: 'Meter at or over ' + AppConstants.keyWarningUsageThreshold + '% projected threshold',
			info: 'Meters may or may not be enforced. The key has at least one meter with a projected threshold and that meter is over ' + AppConstants.keyActivationsWarningThreshold + '% of its projected threshold.'
		},

		{
			value: 'meter_might_pass_projected',
			label: 'Monthly meter(s) might reach/pass projected threshold',
			info: 'Meters may or may not be enforced. The key has at least one monthly meter and, based on its rate of use, may exceed its projected threshold for the month. This warning will only start appearing after ' + AppConstants.monthlyMeterFillUpMinDays + ' days into the month.'
		},

		{
			value: 'near_projected_protocol',
			label: 'Protocol Set at or over ' + AppConstants.keyWarningUsageThreshold + '% projected monthly threshold',
			info: 'The key has at least one linked protocol set with a projected threshold and monthly total for those protocols is over ' + AppConstants.keyActivationsWarningThreshold + '% of its projected threshold.'
		},

		{
			value: 'protocol_set_might_pass_projected',
			label: 'Protocol Set might reach/pass projected monthly threshold',
			info: 'The key has at least one linked protocol set with a projected threshold and, based on its rate of use, may exceed its projected threshold for the month. This warning will only start appearing after ' + AppConstants.monthlyMeterFillUpMinDays + ' days into the month.'
		},

		{
			value: 'permanent',
			label: 'Permanent - no expiry, duration or meters',
			info: 'Licenses issued with this key will never expire.'
		},

		{
			value: 'set_expiry',
			label: 'Has specific expiry (no meters)',
			info: 'Licenses issued against this key expire on a specific date not set/controlled via meters.'
		},

		{
			value: 'duration',
			label: 'Uses duration based expiry',
			info: 'Licenses issued against this key have a fixed lifespan based on a specific duration from the day they are issued.'
		},

		{
			value: 'has_meter',
			label: 'Has at least one meter',
			info: 'Key has at least one meter defined.'
		},
		{
			value: 'has_meter_monthly',
			label: 'Has at least one meter that resets monthly',
			info: 'Key has at least one meter defined that resets monthly.'
		},
		{
			value: 'has_meter_never',
			label: 'Has at least one meter that never resets',
			info: 'Key has at least one meter defined never resets.'
		},
		{
			value: 'no_meter',
			label: 'Has no meter',
			info: 'Key does not used meters.'
		},

		{
			value: 'uses_projected', label: 'Has Meter with projected usage threshold',
			info: 'Key has at least one meter with a projected usage threshold.'
		},

		{
			value: 'no_projected', label: 'No projected meter(s)',
			info: 'Key uses meters, but does not have a meter with a projected usage threshold.'
		},

		{
			value: 'meters_enforced',
			label: 'Meter limits are enforced',
			info: 'Option to not enforce meter limits has not been enabled.'
		},
		{
			value: 'meters_not_enforced',
			label: 'Meter limits are NOT enforced',
			info: 'Option to not enforce meter limits has been enabled.'
		},

		{
			value: 'has-meter-labels',
			label: 'Has meter labels',
			info: 'At least one of the key\'s meters has a label.'
		},
		{
			value: 'no-meter-labels',
			label: 'No meter labels',
			info: 'The doesn\'t have a meter with a label.'
		},

		{
			value: 'reported-meter-usage',
			label: 'Has reported usage via metering',
			info: 'The key has (or had) at least one meter and its device(s) have reported meter usage. Usage values reported may be zero.'
		},
		{
			value: 'not-reported-meter-usage',
			label: 'Has not reported usage via metering',
			info: 'No device(s) activated with the key have reported meter usage.'
		},

		{
			value: 'reported-protocol-usage',
			label: 'Has reported usage via protocol usage',
			info: 'At least one device activated with the key has reported protocol (v13+) usage. Usage values reported may be zero.'
		},
		{
			value: 'not-reported-protocol-usage',
			label: 'Has not reported usage via protocol usage',
			info: 'No device(s) activated with the key have reported protocol (v13+) usage.'
		},

		{
			value: 'opportunity_id_set',
			label: 'Salesforce opportunity set',
			info: 'A Salesforce opportunity has been linked to this key.'
		},
		{
			value: 'opportunity_id_not_set',
			label: 'Salesforce opportunity NOT set',
			info: 'A Salesforce opportunity has not been linked to this key.'
		},

		{
			value: 'no_org_link',
			label: 'Not linked to an organization',
			info: 'The key has been linked to a ZCP organization.'
		},
		{
			value: 'has_org_link',
			label: 'Linked to an organization',
			info: 'The key has not been linked to a ZCP organization'
		},
		{
			value: 'no_template',
			label: 'Key does not have a template',
			info: 'The key was not created using the ZCP and doesn\'t have a template assigned.\n'
				+ 'Until a template is assigned, the key cannot be managed via the ZCP.'
		},
		{
			value: 'has_template',
			label: 'Key has a template',
			info: 'The key was created using the ZCP or a template was assigned to it after it was created.'
		},
		{
			value: 'reported_billing_code',
			label: 'Reports billing code(s)',
			info: 'At least one Broadcaster using the key has a billing code set on at least one input and/or output and has reported non-zero traffic from that input/output via protocol usage.'
		},

		{
			value: 'bad_billing_code',
			label: 'Keys with Invalid Billing Codes',
			info: 'At least one Broadcaster using the key has a billing code set on at least one input and/or output and is using an invalid authentication code for that billing code.'
		},

		{
			value: 'bad_billing_code_7',
			label: 'Keys with Invalid Billing Codes Reported in the Last Week',
			info: 'At least one Broadcaster using the key has a billing code set on at least one input and/or output and is using an invalid authentication code for that billing code and has reported usage using the invalid authentication code in the last week.'
		},

		{
			value: 'key_not_used',
			label: 'No activations',
			info: 'The key has never been used to create a license.'
		},
		{
			value: 'key_used',
			label: 'At least one activation',
			info: 'The key has been used at least once to create a license.'
		},
		{
			value: 'near_activation_limit',
			label: '# Activations at or over ' + AppConstants.keyActivationsWarningThreshold + '% Maximum',
			info: 'The number of activations used for this key is more than ' + AppConstants.keyActivationsWarningThreshold + '% of total activations for the key.'
		},
		{
			value: 'count_at_max',
			label: 'All activations used',
			info: 'All activations have been used.'
		},
		{
			value: 'count_not_at_max',
			label: 'Activations available',
			info: 'The key has at least one activation available.'
		},
		{
			value: 'high_count',
			label: 'Large (' + AppConstants.maxLicenseRowsToFetch.toLocaleString() + '+) activation count',
			info: 'The key has more than 1,000 activations.'
		},
		{
			value: 'high_max',
			label: 'Large (' + AppConstants.maxLicenseRowsToFetch.toLocaleString() + '+) maximum activations',
			info: 'The key can have more than 1,000 activations.'
		},

		{
			value: 'transcoders_with_x264',
			label: 'Transcode keys with x264',
			info: 'Key has at least one Transcoder enabled AND Key has the \'x264 transcoding\' option enabled.'
		},
		{
			value: 'transcoders_without_x264',
			label: 'Transcode keys without x264',
			info: 'Key has at least one Transcoder enabled AND Key does not have \'x264 transcoding\' option enabled.'
		},
		{
			value: 'discard_licenses',
			label: 'Key discards licenses',
			info: 'If it\'s been more than ' + AppConstants.maxStaleLicenseDays + ' days since the activated license was added/updated, delete the license from the database.'
		},
		{
			value: 'key_is_shared',
			label: 'Shared with user(s)',
			info: 'The key has been shared with (or registered by) at least one user.'
		},
		{
			value: 'key_is_not_shared',
			label: 'Not shared with user(s)',
			info: 'The key has not been shared with (or registered by) any users.'
		},
		{
			value: 'write_access_granted',
			label: 'Extra write access granted',
			info: 'A staff member has granted write access to the key to another staff member that normally wouldn\'t be able to edit the key (due to template access).'
		},
		{
			value: 'not-offline',
			label: 'Standard (not offline) Key for Activations',
			info: 'A key used for standard activations.'
		},
		{
			value: 'offline',
			label: 'Offline Key for Manual Activations',
			info: 'A key used to track/group manual licenses issued to systems that can\'t do a standard activation.'
		},

		{
			value: 'has-meter-irregularities',
			label: 'Has meter/commercial irregularities',
			info: 'Broadcaster key with potential irregularities related to metering and/or standard commercial types.'
		},

		{
			value: 'commercial-type-set',
			label: 'Commercial Type Set',
			info: 'A key that has the "commercial type" field set.'
		},
		{
			value: 'commercial-type-not-set',
			label: 'Commercial Type Not Set',
			info: 'A key that does not have the "commercial type" field set.'
		},

		{
			value: 'commercial-type-not-set-with-guess',
			label: 'Commercial Type Not Set and one suggestion available',
			info: 'A key that does not have the "commercial type" field set, but based on the key\'s meters, a single suggestion is available.'
		},

		{
			value: 'commercial-info-set',
			label: 'Commercial Notes Set',
			info: 'A key where the "commercial notes" field has a value.'
		},
		{
			value: 'commercial-info-not-set',
			label: 'Commercial Notes Not Set',
			info: 'A key where the "commercial notes" field does not have a value.'
		},

		{
			value: 'protocol-set-linked',
			label: 'Protocol Set(s) Linked',
			info: 'A key that has one or more protocol sets linked to it.'
		},
		{
			value: 'protocol-set-linked-with-projected',
			label: 'Protocol Set(s) Linked With Projected Limit Set',
			info: 'A key that has one or more protocol sets linked to it and at least one with a projected monthly limit.'
		},
		{
			value: 'protocol-set-linked-not-projected',
			label: 'Protocol Set(s) Linked Without Projected Limit Set',
			info: 'A key that has one or more protocol sets linked to it and none with a projected monthly limit.'
		},
		{
			value: 'protocol-set-not-linked',
			label: 'Protocol Set(s) Not Linked',
			info: 'A key that does not have any protocol sets linked to it.'
		},

		{
			value: 'bx-failed-pen-test',
			label: 'Failed Pen Test (Default Username and Password)',
			info: 'A key that has at least one activation that appears to be open to the internet and uses the default username and password.'
		},

		{
			value: 'marketplace-configured',
			label: 'Key has been configured for a marketplace ',
			info: 'A key that been configured to report its usage to one of our marketplace partners (like AWS).'
		},

	];

	// other specials...
	// keys not upgraded to v13+
	// keys partially upgraded to v13+
	// keys not expired and meters not expired

	static readonly specialOfflineSearches = [
		// { value: 'about_to_expire', label: 'Will expire in next ' + AppConstants.keyExpiryWarningDays + ' days' },
		// { value: 'recently_expired', label: 'Has expired in last ' + AppConstants.keyExpiryWarningDays + ' days' },

		{ value: 'has_expired', label: 'Expired' },
		{ value: 'not_expired', label: 'Not expired' },
		{ value: 'permanent', label: 'No expiration' },

		// { value: 'has_key', label: 'Not linked to Key' },
		// { value: 'no_key', label: 'Linked to Key' },
	];

	static readonly keyLinkStatuses = {
		'needs-verification': 'Needs Verification',
		'staff-verified': 'Verified',
		'manually-linked': 'Manually Linked',
		'portal-created': 'Portal Created',
	};

	static readonly keyPropertyTypes = [
		'boolean',
		'number',
		'other'
	];

	static readonly keyPropertyTypeLabels = {
		boolean: 'Feature',
		number: 'Limit',
		other: 'Other'
	};

	// 0 - admins see the value of this property, don't show end-users (customers)
	// 1 - only show value of this field to end-users (customers) if it is on/has a value
	// 2 - show value of this field to end-users (customers) whether it is on/off has value/doesn't
	static readonly keyPropShowUserValues = [
		'No',
		'Yes - if set',
		'Yes - always'
	];

	// 0 - product doesn't have this type of stat (meter/protocol)
	// 1 - product has this type of stat (meter/protocol), but only staff can see the stats
	// 2 - product has this type of stat (meter/protocol), and staff + customers(basic users) can see them
	static readonly keyProductStatsValues = [
		'Not Available',
		'Staff Only',
		'Show Everyone'
	];

	static readonly suggestedMeterLabels = [
		'Used for billing',
		'Not used for billing',
		'Not used for billing; Uses other meter(s)',
		'Not used for billing; Uses protocol data',
		'For tracking overages'
	];

	static readonly staffFocusSearchBaseName: string = 'License Keys that Might Need Attention';
	static readonly staffFocusSearchBaseOptions: string = 'products=broadcaster*%2Creceiver%2Cfeeder%2Czec';

	static readonly staffFocusSearchSpecialOptions: string[] = [
		'keys_that_need_attention'
	];

	// 'specials_or_search',
	// 'about_to_expire',
	// 'near_usage_limit_include',
	// 'meter_might_run_out_include',
	// 'near_projected',
	// 'meter_might_pass_projected'

	// note that sice the value fields below are used in the activation table in the license DB, they cannot/should not be changed
	// unless someone is prepared to make the correspoding updates in the activation table

	static readonly keyCommercialTypes: any[] = [
		{
			value: 'no-bitcount-billing',
			label: 'Neither metering nor protocol usage (bit counting) is used for billing',
			short_label: 'NO USAGE/BITCOUNT Billing',
			acronym: 'NBB',
			meterTypes: [],
			info: 'typically, this means that the key\'s other properties (features + limits) enforce the contract\'s terms OR there isn\'t any contract/billing with the key (Fx, Rx, Bx as an Fx, Bx as an Rx, MCFx, MCRx or ZEC)\n'
				+ 'if this is a broadcaster key, document in Commercial Notes why it\'s excluded from billing\n'
				+ 'meter(s) may be excluded from some products (e.g Fx, Rx), in which case disregard the following\n'
				+ 'one ALL OUTPUTS meter is required to enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date based on contract/requirements\n'
				+ 'one PROTECTED meter is required to enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date based on contract/requirements\n'
		},
		{
			value: 'meters-all-outputs-max-limit',
			label: 'Billing based on ALL OUTPUTS meter(s) bit counting and has a maximum limit',
			short_label: 'ALL OUTPUTS Billing with Max. Limit',
			acronym: 'AOML',
			meterTypes: ['output_mb', 'output_mb_meter'],
			info: 'at least one ALL OUTPUTS meter is required to set a maximum limit and enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. used for billing)\n'
				+ '\tmaximum limit(s) set to an appropriate amount (based on contract) and NO projected limit\n'
				+ '\texpiration date(s) based on contract\n'
				+ 'one PROTECTED meter is also required in case PROTECTED meter usage is needed in the future\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date set as maximum expiration date across all ALL OUTPUTS meters'
		},
		{
			value: 'meters-protected-max-limit',
			label: 'Billing based on PROTECTED meter(s) bit counting and has a maximum limit',
			short_label: 'PROTECTED Billing with Max. Limit',
			acronym: 'PRML',
			meterTypes: ['protected_mb'],
			info: 'at least one PROTECTED meter is required to set a maximum limit and enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. used for billing)\n'
				+ '\tmaximum limit(s) set to an appropriate amount (based on contract) and NO projected limit\n'
				+ '\texpiration date(s) based on contract\n'
				+ 'one ALL OUTPUTS meter is also required in case ALL OUTPUTS meter usage is needed in the future\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date set as maximum expiration date across all PROTECTED meters'
		},
		{
			value: 'meters-all-outputs-proj-limit',
			label: 'Billing based on ALL OUTPUTS meter(s) bit counting using projected limit and potential overages',
			short_label: 'ALL OUTPUTS Billing with Proj. Limit',
			acronym: 'AOPL',
			meterTypes: ['output_mb', 'output_mb_meter'],
			info: 'at least one ALL OUTPUTS meter is required to set a projected limit and enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. used for billing)\n'
				+ '\t9.0 ZB maximum limit and projected limit(s) set to an appropriate amount (based on contract)\n'
				+ '\texpiration date(s) based on contract\n'
				+ 'one PROTECTED meter is also required in case PROTECTED meter usage is needed in the future\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date set as maximum expiration date across all ALL OUTPUTS meters'
		},
		{
			value: 'meters-protected-proj-limit',
			label: 'Billing based on PROTECTED meter(s) bit counting using projected limit and potential overages',
			short_label: 'PROTECTED Billing with Proj. Limit',
			acronym: 'PRPL',
			meterTypes: ['protected_mb'],
			info: 'at least one PROTECTED meter is required to set a projected limit and enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. used for billing)\n'
				+ '\t9.0 ZB maximum limit and projected limit(s) set to an appropriate amount (based on contract)\n'
				+ '\texpiration date(s) based on contract\n'
				+ 'one ALL OUTPUTS meter is also required in case ALL OUTPUTS meter usage is needed in the future\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date set as maximum expiration date across all PROTECTED meters'
		},
		{
			value: 'protocol-data',
			label: 'Billing based on Protocol bit counting data',
			short_label: 'PROTOCOL COUNTERS Billing',
			acronym: 'PDAT',
			meterTypes: [],
			info: 'document in Commercial Notes what specific protocol counters that are used for billing\n'
				+ 'a protocol should be defined that represents these specific protocol counters and should be linked to the key\n'
				+ 'one All Outputs meter is required to enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date based on contract\n'
				+ 'one PROTECTED meter is required to enforce check-ins + expiry\n'
				+ '\tappropriate label (e.g. not used for billing)\n'
				+ '\t9.0 ZB maximum limit and NO projected limit\n'
				+ '\texpiration date based on contract\n'
		}
	];

	// ****************************************************************************************************************************
	// Billing Codes
	// ****************************************************************************************************************************

	static readonly billingCodeAuthLength = 12;
	static readonly billingCodeAuthChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	static readonly billingCodeStart: Date = new Date('2021/03/01 00:00:00 UTC');
	// static readonly billingValidCheckStart: Date = new Date('2024-04-28 00:00:00 UTC');
	static readonly billingValidCheckStart: Date = new Date('2021/03/01 00:00:00 UTC');

	static readonly noBillingCodeToken = '---no-code---';
	static readonly groupByBillingCodeToken = '---group-by---';

	// ****************************************************************************************************************************
	// Admin logs
	// ****************************************************************************************************************************

	static readonly journalObjectTypes = [
		'activation',
		'user',
		'organization',
		'partnership',
		'zenmaster',
		'build',
		'document'
	];

	static readonly maxLogsToFetch = 500;

	static readonly adminLogObjTypes = [
		{ value: 'organization', label: 'Organization' },
		{ value: 'user', label: 'User' },
		{ value: 'authentication', label: 'User (Authentication)' },
		{ value: 'bundle', label: 'Shared Files' },
		{ value: 'organization-group', label: 'Organization Group' },
		{ value: 'user-group', label: 'User Group' },
		{ value: 'activation', label: 'Licensing' },
		{ value: 'build', label: 'Build' },
		{ value: 'document', label: 'Document' },
		{ value: 'file', label: 'File' },
		{ value: 'free', label: 'File (Free)' },
		{ value: 'system-message', label: 'Message' },
		{ value: 'package', label: 'Package' },
		{ value: 'platform', label: 'Platform' },
		{ value: 'product', label: 'Product' },
		{ value: 'zenmaster', label: 'ZEN Master' },
		{ value: 'partnership', label: 'Partnership' },
		{ value: 'license-product', label: 'License Product' },
		{ value: 'license-property', label: 'License Property' },
		{ value: 'license-product-property', label: 'License Product Property' },
		{ value: 'key-template', label: 'Key Template' },
		{ value: 'marketplace', label: 'Cloud Marketplace' },
	];

	/*
	'email-queue'
	'licensing'
	'general-notification'
	'protocol-set'
	'quiz'
	'reports'
	*/

	static readonly adminLogActions = [
		{ value: 'add', label: 'Add' },
		{ value: 'update', label: 'Update' },
		{ value: 'delete', label: 'Delete' },
		{ value: 'disable', label: 'Disable' },
		{ value: 'enable', label: 'Enable' },
		{ value: 'subscribe', label: 'Subscribed' },
		{ value: 'unsubscribe', label: 'Unsubscribed' },
		{ value: 'welcome-message', label: 'Welcome Sent' },
		{ value: 'password-reset', label: 'Password Set' },
		{ value: 'add-user', label: 'User Added to Organization' },
		{ value: 'failed login', label: 'Failed Login' },
		{ value: 'failed-email', label: 'Failed Email' },
		{ value: 'self-registration', label: 'User Registers' },
		{ value: 'forgot-password', label: 'Requests Password Reset' },
		{ value: 'send-token', label: 'Requests Password Reset' },

		{ value: 'add-key', label: 'User Generates Key' },
		{ value: 'register-key', label: 'User Registers Key' },
		{ value: 'update-key', label: 'User Updates Key' },
		{ value: 'delete-key', label: 'User Deletes Key' },
		{ value: 'purge-key', label: 'User Purges Key' },
		{ value: 'recover-key', label: 'User Recovers' },

		{ value: 'link-activation', label: 'Link License Key' },
		{ value: 'unlink-activation', label: 'Unlink License Key' },
		{ value: 'link-organization', label: 'Linked to Organization' },

		{ value: 'journal', label: 'Add Journal Entry' },
		{ value: 'add-billing-code', label: 'Add Billing Code' },
		{ value: 'update-billing-code', label: 'Update Billing Code' },
	];

	/*
		'enable-autobuild','1'
		'ERROR','1'
		'thawed','1'
		'undelete-key','1'
		'verify','2'
		'delete-key-write-access','2'
		'update-snooze','2'
		'delete-snooze','2'
		'azure mktpl notify','2'
		'notify-exception','3'
		'add-key-template','3'
		'sort-order','3'
		'activate','4'
		'stats-roll-up-exception','5'
		'update-key-template','5'
		'new-protocols','9'
		'delete-partnership','9'
		'add-partnership','9'
		'gcp webhook','11'
		'delete-zen-domain','11'
		'marketplace','12'
		'frozen','12'
		'disable_autobuild','12'
		'process','13'
		'update-zen-domain','19'
		'unlink-set','21'
		'updated','23'
		'aws webhook','26'
		'exception','26'
		'updated-saved-search','35'
		'unlink-user','35'
		'user-removed','38'
		'registration','42'
		'failed-lookup','46'
		'add-snooze','52'
		'disable-autobuild','52'
		'azure mktpl token','52'
		'delete-saved-search','57'
		'add-key-write-access','65'
		'aws mktpl notify','69'
		'update-partnership','78'
		'update-setting','91'
		'set-key-label','98'
		'share-key','100'
		'link-set','108'
		'access denied','121'
		'add-saved-search','150'
		'link','158'
		'aws mktpl token','162'
		'add-zen-domain','189'
		'user-assigned','271'
		'offline-license','320'
		'update-sales-engineer','336'
		'snoozed-notification','441'
		'remove-key','463'
		'notify','497'
		'update-account-owner','544'
		'update-salesforce-owner','584'
		'reset-meter','784'
		'delete-user','830'
		'run-pen-test','1018'
		'register-hostid','1068'
		'run-report','1709'
		'azure webhook','1881'
	*/


	// ****************************************************************************************************************************
	// dashboard related
	// ****************************************************************************************************************************
	static readonly dashboardRecentItemsDays = 30;
	static readonly dashboardMaxItemsDays = 365;
	static readonly dashboardRecentCount = 25;

	static readonly dashboardMessageRecoverDays = 7;

	// show everything (builds/docs) added in the last recentItemsDays(30) days
	// and anything else added in the last maxItemsDays(120) days until at least
	// recentCount (10) items are shown

	// ****************************************************************************************************************************
	// email queue / notifications
	// ****************************************************************************************************************************

	// static readonly notificationsStaffOnly = true;

	static readonly unSubCleanUpDays = 7;

	static readonly newFeatureAlertedKey = 'notify_alerted';

	static readonly optInOnFirstLogin: boolean = false;

	static readonly notificationDeliveryModes = [
		{ value: 'immediate', label: 'Immediately (send when message is generated)' },
		{ value: 'window', label: 'During Window (wait to send during certains times of the day)' },
	];

	static readonly defNotificationStartHour = 9;
	static readonly defNotificationEndHour = 17;

	static readonly maxEmailsToSendPerRun = 100;
	static readonly maxEmailsToVerifyPerRun = 100;

	static readonly minLicenseWarningDays = 7;
	static readonly defLicenseWarningDays = 28;
	static readonly maxLicenseWarningDays = 42;

	static readonly minLicenseUsagePercentage = 70;
	static readonly defLicenseUsagePercentage = 80;
	static readonly maxLicenseUsagePercentage = 95;

	static readonly unusedZENMasterAssessments = [
		AppConstants.zenMasterAssessments[3],
		AppConstants.zenMasterAssessments[4]
	];

	static readonly notificationMessageTypes = [
		{ value: 'build', label: 'Build' },
		{ value: 'document', label: 'Document' },
		{ value: 'key-expiry', label: 'Key Expiry' },
		{ value: 'key-usage', label: 'Key Meter Maximum Usage' },
		{ value: 'key-projected', label: 'Key Meter Projected Usage' },
		{ value: 'key-protocol-projected', label: 'Key Protocol Set Projected Usage' },
		{ value: 'general', label: 'General' },
		{ value: 'key-report', label: 'Key Report' },
		{ value: 'unused-zen-master-report', label: 'Unused ZEN Master Report' },
		{ value: 'file-sharing', label: 'File Sharing' },
		{ value: 'security', label: 'Security' },
		{ value: 'key-sharing', label: 'Key Sharing' },
	];

	static readonly notificationMessageStatuses = [
		{ value: 'pending', label: 'Pending' },
		{ value: 'sent', label: 'Sent' },
		{ value: 'delivered', label: 'Delivered' },
		{ value: 'failed', label: 'Failed' },
		{ value: 'rejected', label: 'Rejected' },
		{ value: 'on-hold', label: 'On Hold' }
	];

	static readonly notificationPropLabels = {
		deliveryMode: 'Send Messages',
		startHour: 'Do not send before',
		endHour: 'Do not send after',
		timezone: 'Your Time Zone',
		daysOfWeek: 'Days of the Week',

		receiveBuildMessages: 'Software Builds',
		includedBuildProductIDs: 'Just these Products',
		excludedBuildProductIDs: 'Not these Products',
		includedBuildPlatformIDs: 'Just these Platforms',
		excludedBuildPlatformIDs: 'Not these Platforms',

		receiveDocumentMessages: 'Documentation',
		includedDocumentProductIDs: 'Just these Products',
		excludedDocumentProductIDs: 'Not these Products',

		receiveKeyExpirationMessages: 'License Key Expiration',
		expirationDays: 'Start alerting me',

		receiveKeyUsageMessages: 'License Key Meter Approaching or Over Maximum',
		usagePercentage: 'Start alerting me over',

		receiveProjectedKeyUsageMessages: 'License Key Meter Approaching or Over Projected Usage Limit',
		projectedPercentage: 'Start alerting me over',

		receiveProtocolKeyUsageMessages: 'License Key Linked Protocol Set Approaching or Over Projected Usage Limit',
		protocolPercentage: 'Start alerting me over',

		receiveOfflineHostIDsMessages: 'License Key Has System(s) That Have Gone Offline',

		receiveKeysReportMessages: 'License Key Usage Summary Report',
		keysReportFrequency: 'Frequency of Summary Reports',

		receiveGenerallMessages: 'General Notifications',
		autoSubscribe: 'Auto-Subscribe to New Types of Notifications',

		skipEmptyReports: 'Don\'t Send Empty Reports',

		receiveStaffKeyReportMessages: 'License Key Reports',
		keyReportSavedSearches: 'Saved searches',

		receiveStaffZENMasterReportMessages: 'Unused ZEN Master Report',
		includedZenMasterTypes: 'Just these types',

		receiveStaffOrgMeterReportMessages: 'Monthly Organization Meter Usage Report',
		orgMeterReportOrgIDs: 'Organization(s)',
	};

	static readonly notificationPropInfo = {
		deliveryMode: 'Some messages are generated when new items (builds, document) are added.  Others (license key related) are generated at a fixed time of day.  You can chose when you want to receive messages.',
		startHour: '',
		endHour: '',
		timezone: 'The time window you select will be based on this time zone. Click on the clock icon for more information about the IANA Time Zone database.',
		daysOfWeek: 'For daily notifications, you have the option of only getting notifications on specific days.  Leaving all days unchecked means you will get notified every day of the week.',

		receiveBuildMessages: 'Receive notifications regarding new and/or updated software builds.',
		includedBuildProductIDs: 'Optional: Specific products you only want to receive notifications for.',
		excludedBuildProductIDs: 'Optional: Specific products you do not want to receive notifications for.',
		includedBuildPlatformIDs: 'Optional: Specific platforms you only want to receive notifications for.',
		excludedBuildPlatformIDs: 'Optional: Specific platforms you do not want to receive notifications for.',

		receiveDocumentMessages: 'Receive notifications regarding new and/or updated documentation.',
		includedDocumentProductIDs: 'Optional: Specific products you only want to receive notifications for.',
		excludedDocumentProductIDs: 'Optional: Specific products you do not want to receive notifications for.',

		receiveKeyExpirationMessages: 'Receive daily notifications when your license keys are approaching their expiration.',
		expirationDays: '',

		receiveKeyUsageMessages: 'Receive daily notifications when your license keys are approaching or are over their meter\'s maximum limit.',
		usagePercentage: '',

		receiveProjectedKeyUsageMessages: 'Receive daily notifications when your license keys are approaching or are over their meter\'s projected usage limit (if set).',
		projectedPercentage: '',

		receiveProtocolKeyUsageMessages: 'Receive daily notifications when your license keys are approaching or are over their linked protocol set\'s projected usage limit (if set).',
		protocolPercentage: '',

		receiveOfflineHostIDsMessages: 'Receive daily notifications when your license keys have systems (licensed host ID) that are no longer communicating with the Zixi license server.\n'
			+ 'Note that this will alert if a licensed system hasn\'t reported in over the last day, but did report in within the last ' + AppConstants.hostOfflineMaxDays + ' days.\n'
			+ 'A system may not be able to communicate with the Zixi license server due to firewall change or simply because it was shut down.',

		receiveKeysReportMessages: 'Receive e-mail notifications (at your selected frequency) that includes a summary of your keys\' usage data.',
		keysReportFrequency: 'How often do you want to receive these Usage Summary Reports?',

		receiveGenerallMessages: 'Receive general notifications.  These might include end-of-life or system-wide change notices.',
		autoSubscribe: 'When new types of notifications are added to the portal, automatically subscribe to them.',

		skipEmptyReports: 'Skip sending empty reports.\n'
			+ 'For License Key and Unused ZEN Master reports, if there\'s nothing to report, don\'t send an e-mail.',

		receiveStaffKeyReportMessages: 'Receive daily license key reports based on custom saved searches.\n'
			+ 'You must choose at least one saved search.  Saved searches can generated in the Licensing / Search part of the portal.',
		keyReportSavedSearches: 'One or more saved searches to run reports from.',

		receiveStaffZENMasterReportMessages: 'Receive daily reports of enabled ZEN Master sites that appear to be unused (more than 90 days since either it was created and no user has logged in or since the last time someone logged in).',
		includedZenMasterTypes: 'Optional: Only send reports on these types of ZEN Master sites.',

		receiveStaffOrgMeterReportMessages: 'Receive monthly (report for previous month 1st day of the next month) reports (one per selected organization) with information (current amount, max, percentage of max) on meter(s) for the organization\'s key(s).',
		orgMeterReportOrgIDs: 'One or more organizations.',
	};

	static readonly defaultKeysReportFrequency = 'weekly-monday';

	static readonly keysReportFrequencySelections = [
		{ value: 'daily', label: 'Daily' },
		{ value: 'weekly-sunday', label: 'Weekly (on Sundays)' },
		{ value: 'weekly-monday', label: 'Weekly (on Mondays)' },
		{ value: 'monthly-first-day', label: 'Monthly (on the first day of the month)' },
		{ value: 'monthly-last-day', label: 'Monthly (on the last day of the month)' },
	];

	static readonly defaultSnoozeDays: number = 8;

	static readonly keySnoozeTypes = [
		{ value: 'expiry', label: 'Expiration', description: 'Key is approaching its expiration' },
		{ value: 'maximum', label: 'Maximum Meter Usage', description: 'Key is approaching or over its meter\'s maximum limit' },
		{ value: 'projected', label: 'Projected Meter Usage', description: 'Key is approaching or over its meter\'s projected usage limit' },
		{ value: 'protocol', label: 'Projected Protocol Set Usage', description: 'Key is approaching or over its protocol set\'s projected monthly usage limit' },
		{ value: 'offline', label: 'Offline Systems', description: 'Key has one or more systems (licensed host ID) that are no longer communicating with the Zixi license server' },
		{ value: 'all', label: 'All Notifications', description: 'All notifications for this key' },
	];

	// ****************************************************************************************************************************
	// file sharing bundles
	// ****************************************************************************************************************************

	static readonly filesharingStaffOnly = false;

	static readonly bundleLockDays = 7;
	static readonly bundleDeleteDays = 14;

	static readonly bundleCleanUpPostFileDeleteDays = 1;

	static readonly bundleAlertOnIgnoredDays = 4;

	static readonly maxFileSize = 5000000000; // 5 GB

	static readonly bundleTypes = [
		{ value: 'staff-send', label: 'You want to send files and have them come from you directly' },
		{ value: 'group-send', label: 'You want to send files on behalf of your group' },
		{ value: 'staff-receive', label: 'You want to receive files' },
		{ value: 'group-receive', label: 'You want your group to receives files' }
	];

	static readonly bundleTypeDescriptions = {
		'staff-send': 'You want to send files to someone and the files will appear to come from you.',
		'staff-receive': 'You want someone to send you files.',
		'group-send': 'You want to send files to someone and the files will appear to come one of your groups.',
		'group-receive': 'You want someone to send files to you and members of one your groups.'
	};

	// ****************************************************************************************************************************
	// Self Registration
	// ****************************************************************************************************************************
	static selfRegisteredInformation = 'A self-registered user.';

	// valid 'extra' props that can be passed via self-registration
	static readonly selfRegistrationUserProps = [
		'user_reg_first_name',
		'user_reg_last_name',
		'user_reg_phone',
		'user_reg_company',
		'user_reg_country',
		'user_reg_state',
		'user_reg_information',
		'user_reg_referer',
		'user_reg_gclid',
	];

	// 'extra' props that can be passed via self-registration that are optional
	static readonly selfRegistrationOptionalProps = [
		'user_reg_state',
		'user_reg_information',
		'user_reg_referer',
		'user_reg_gclid',
	];

	static readonly countries = [
		{ code: 'US', label: 'United States' },
		{ code: 'CA', label: 'Canada' },
		{ code: 'AF', label: 'Afghanistan' },
		{ code: 'AX', label: 'Aland Islands' },
		{ code: 'AL', label: 'Albania' },
		{ code: 'DZ', label: 'Algeria' },
		{ code: 'AD', label: 'Andorra' },
		{ code: 'AO', label: 'Angola' },
		{ code: 'AI', label: 'Anguilla' },
		{ code: 'AQ', label: 'Antarctica' },
		{ code: 'AG', label: 'Antigua and Barbuda' },
		{ code: 'AR', label: 'Argentina' },
		{ code: 'AM', label: 'Armenia' },
		{ code: 'AW', label: 'Aruba' },
		{ code: 'AU', label: 'Australia' },
		{ code: 'AT', label: 'Austria' },
		{ code: 'AZ', label: 'Azerbaijan' },
		{ code: 'BS', label: 'Bahamas' },
		{ code: 'BH', label: 'Bahrain' },
		{ code: 'BD', label: 'Bangladesh' },
		{ code: 'BB', label: 'Barbados' },
		{ code: 'BY', label: 'Belarus' },
		{ code: 'BE', label: 'Belgium' },
		{ code: 'BZ', label: 'Belize' },
		{ code: 'BJ', label: 'Benin' },
		{ code: 'BM', label: 'Bermuda' },
		{ code: 'BT', label: 'Bhutan' },
		{ code: 'BO', label: 'Bolivia, Plurinational State of' },
		{ code: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
		{ code: 'BA', label: 'Bosnia and Herzegovina' },
		{ code: 'BW', label: 'Botswana' },
		{ code: 'BV', label: 'Bouvet Island' },
		{ code: 'BR', label: 'Brazil' },
		{ code: 'IO', label: 'British Indian Ocean Territory' },
		{ code: 'BN', label: 'Brunei Darussalam' },
		{ code: 'BG', label: 'Bulgaria' },
		{ code: 'BF', label: 'Burkina Faso' },
		{ code: 'BI', label: 'Burundi' },
		{ code: 'KH', label: 'Cambodia' },
		{ code: 'CM', label: 'Cameroon' },
		{ code: 'CV', label: 'Cape Verde' },
		{ code: 'KY', label: 'Cayman Islands' },
		{ code: 'CF', label: 'Central African Republic' },
		{ code: 'TD', label: 'Chad' },
		{ code: 'CL', label: 'Chile' },
		{ code: 'CN', label: 'China' },
		{ code: 'CX', label: 'Christmas Island' },
		{ code: 'CC', label: 'Cocos (Keeling) Islands' },
		{ code: 'CO', label: 'Colombia' },
		{ code: 'KM', label: 'Comoros' },
		{ code: 'CG', label: 'Congo' },
		{ code: 'CD', label: 'Congo, the Democratic Republic of the' },
		{ code: 'CK', label: 'Cook Islands' },
		{ code: 'CR', label: 'Costa Rica' },
		{ code: 'CI', label: 'Cote d\'Ivoire' },
		{ code: 'HR', label: 'Croatia' },
		{ code: 'CU', label: 'Cuba' },
		{ code: 'CW', label: 'Curaçao' },
		{ code: 'CY', label: 'Cyprus' },
		{ code: 'CZ', label: 'Czech Republic' },
		{ code: 'DK', label: 'Denmark' },
		{ code: 'DJ', label: 'Djibouti' },
		{ code: 'DM', label: 'Dominica' },
		{ code: 'DO', label: 'Dominican Republic' },
		{ code: 'EC', label: 'Ecuador' },
		{ code: 'EG', label: 'Egypt' },
		{ code: 'SV', label: 'El Salvador' },
		{ code: 'GQ', label: 'Equatorial Guinea' },
		{ code: 'ER', label: 'Eritrea' },
		{ code: 'EE', label: 'Estonia' },
		{ code: 'ET', label: 'Ethiopia' },
		{ code: 'FK', label: 'Falkland Islands (Malvinas)' },
		{ code: 'FO', label: 'Faroe Islands' },
		{ code: 'FJ', label: 'Fiji' },
		{ code: 'FI', label: 'Finland' },
		{ code: 'FR', label: 'France' },
		{ code: 'GF', label: 'French Guiana' },
		{ code: 'PF', label: 'French Polynesia' },
		{ code: 'TF', label: 'French Southern Territories' },
		{ code: 'GA', label: 'Gabon' },
		{ code: 'GM', label: 'Gambia' },
		{ code: 'GE', label: 'Georgia' },
		{ code: 'DE', label: 'Germany' },
		{ code: 'GH', label: 'Ghana' },
		{ code: 'GI', label: 'Gibraltar' },
		{ code: 'GR', label: 'Greece' },
		{ code: 'GL', label: 'Greenland' },
		{ code: 'GD', label: 'Grenada' },
		{ code: 'GP', label: 'Guadeloupe' },
		{ code: 'GT', label: 'Guatemala' },
		{ code: 'GG', label: 'Guernsey' },
		{ code: 'GN', label: 'Guinea' },
		{ code: 'GW', label: 'Guinea-Bissau' },
		{ code: 'GY', label: 'Guyana' },
		{ code: 'HT', label: 'Haiti' },
		{ code: 'HM', label: 'Heard Island and McDonald Islands' },
		{ code: 'VA', label: 'Holy See (Vatican City State)' },
		{ code: 'HN', label: 'Honduras' },
		{ code: 'HU', label: 'Hungary' },
		{ code: 'IS', label: 'Iceland' },
		{ code: 'IN', label: 'India' },
		{ code: 'ID', label: 'Indonesia' },
		{ code: 'IR', label: 'Iran, Islamic Republic of' },
		{ code: 'IQ', label: 'Iraq' },
		{ code: 'IE', label: 'Ireland' },
		{ code: 'IM', label: 'Isle of Man' },
		{ code: 'IL', label: 'Israel' },
		{ code: 'IT', label: 'Italy' },
		{ code: 'JM', label: 'Jamaica' },
		{ code: 'JP', label: 'Japan' },
		{ code: 'JE', label: 'Jersey' },
		{ code: 'JO', label: 'Jordan' },
		{ code: 'KZ', label: 'Kazakhstan' },
		{ code: 'KE', label: 'Kenya' },
		{ code: 'KI', label: 'Kiribati' },
		{ code: 'KP', label: 'Korea, Democratic People\'s Republic of' },
		{ code: 'KR', label: 'Korea, Republic of' },
		{ code: 'KW', label: 'Kuwait' },
		{ code: 'KG', label: 'Kyrgyzstan' },
		{ code: 'LA', label: 'Lao People\'s Democratic Republic' },
		{ code: 'LV', label: 'Latvia' },
		{ code: 'LB', label: 'Lebanon' },
		{ code: 'LS', label: 'Lesotho' },
		{ code: 'LR', label: 'Liberia' },
		{ code: 'LY', label: 'Libya' },
		{ code: 'LI', label: 'Liechtenstein' },
		{ code: 'LT', label: 'Lithuania' },
		{ code: 'LU', label: 'Luxembourg' },
		{ code: 'MO', label: 'Macao' },
		{ code: 'MK', label: 'Macedonia, the former Yugoslav Republic of' },
		{ code: 'MG', label: 'Madagascar' },
		{ code: 'MW', label: 'Malawi' },
		{ code: 'MY', label: 'Malaysia' },
		{ code: 'MV', label: 'Maldives' },
		{ code: 'ML', label: 'Mali' },
		{ code: 'MT', label: 'Malta' },
		{ code: 'MQ', label: 'Martinique' },
		{ code: 'MR', label: 'Mauritania' },
		{ code: 'MU', label: 'Mauritius' },
		{ code: 'YT', label: 'Mayotte' },
		{ code: 'MX', label: 'Mexico' },
		{ code: 'MD', label: 'Moldova, Republic of' },
		{ code: 'MC', label: 'Monaco' },
		{ code: 'MN', label: 'Mongolia' },
		{ code: 'ME', label: 'Montenegro' },
		{ code: 'MS', label: 'Montserrat' },
		{ code: 'MA', label: 'Morocco' },
		{ code: 'MZ', label: 'Mozambique' },
		{ code: 'MM', label: 'Myanmar' },
		{ code: 'NA', label: 'Namibia' },
		{ code: 'NR', label: 'Nauru' },
		{ code: 'NP', label: 'Nepal' },
		{ code: 'NL', label: 'Netherlands' },
		{ code: 'NC', label: 'New Caledonia' },
		{ code: 'NZ', label: 'New Zealand' },
		{ code: 'NI', label: 'Nicaragua' },
		{ code: 'NE', label: 'Niger' },
		{ code: 'NG', label: 'Nigeria' },
		{ code: 'NU', label: 'Niue' },
		{ code: 'NF', label: 'Norfolk Island' },
		{ code: 'NO', label: 'Norway' },
		{ code: 'OM', label: 'Oman' },
		{ code: 'PK', label: 'Pakistan' },
		{ code: 'PS', label: 'Palestine' },
		{ code: 'PA', label: 'Panama' },
		{ code: 'PG', label: 'Papua New Guinea' },
		{ code: 'PY', label: 'Paraguay' },
		{ code: 'PE', label: 'Peru' },
		{ code: 'PH', label: 'Philippines' },
		{ code: 'PN', label: 'Pitcairn' },
		{ code: 'PL', label: 'Poland' },
		{ code: 'PT', label: 'Portugal' },
		{ code: 'QA', label: 'Qatar' },
		{ code: 'RE', label: 'Reunion' },
		{ code: 'RO', label: 'Romania' },
		{ code: 'RU', label: 'Russian Federation' },
		{ code: 'RW', label: 'Rwanda' },
		{ code: 'BL', label: 'Saint Barthélemy' },
		{ code: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
		{ code: 'KN', label: 'Saint Kitts and Nevis' },
		{ code: 'LC', label: 'Saint Lucia' },
		{ code: 'MF', label: 'Saint Martin (French part)' },
		{ code: 'PM', label: 'Saint Pierre and Miquelon' },
		{ code: 'VC', label: 'Saint Vincent and the Grenadines' },
		{ code: 'WS', label: 'Samoa' },
		{ code: 'SM', label: 'San Marino' },
		{ code: 'ST', label: 'Sao Tome and Principe' },
		{ code: 'SA', label: 'Saudi Arabia' },
		{ code: 'SN', label: 'Senegal' },
		{ code: 'RS', label: 'Serbia' },
		{ code: 'SC', label: 'Seychelles' },
		{ code: 'SL', label: 'Sierra Leone' },
		{ code: 'SG', label: 'Singapore' },
		{ code: 'SX', label: 'Sint Maarten (Dutch part)' },
		{ code: 'SK', label: 'Slovakia' },
		{ code: 'SI', label: 'Slovenia' },
		{ code: 'SB', label: 'Solomon Islands' },
		{ code: 'SO', label: 'Somalia' },
		{ code: 'ZA', label: 'South Africa' },
		{ code: 'GS', label: 'South Georgia and the South Sandwich Islands' },
		{ code: 'SS', label: 'South Sudan' },
		{ code: 'ES', label: 'Spain' },
		{ code: 'LK', label: 'Sri Lanka' },
		{ code: 'SD', label: 'Sudan' },
		{ code: 'SR', label: 'Suriname' },
		{ code: 'SJ', label: 'Svalbard and Jan Mayen' },
		{ code: 'SZ', label: 'Swaziland' },
		{ code: 'SE', label: 'Sweden' },
		{ code: 'CH', label: 'Switzerland' },
		{ code: 'SY', label: 'Syrian Arab Republic' },
		{ code: 'TW', label: 'Taiwan' },
		{ code: 'TJ', label: 'Tajikistan' },
		{ code: 'TZ', label: 'Tanzania, United Republic of' },
		{ code: 'TH', label: 'Thailand' },
		{ code: 'TL', label: 'Timor-Leste' },
		{ code: 'TG', label: 'Togo' },
		{ code: 'TK', label: 'Tokelau' },
		{ code: 'TO', label: 'Tonga' },
		{ code: 'TT', label: 'Trinidad and Tobago' },
		{ code: 'TN', label: 'Tunisia' },
		{ code: 'TR', label: 'Turkey' },
		{ code: 'TM', label: 'Turkmenistan' },
		{ code: 'TC', label: 'Turks and Caicos Islands' },
		{ code: 'TV', label: 'Tuvalu' },
		{ code: 'UG', label: 'Uganda' },
		{ code: 'UA', label: 'Ukraine' },
		{ code: 'AE', label: 'United Arab Emirates' },
		{ code: 'GB', label: 'United Kingdom' },
		{ code: 'UY', label: 'Uruguay' },
		{ code: 'UZ', label: 'Uzbekistan' },
		{ code: 'VU', label: 'Vanuatu' },
		{ code: 'VE', label: 'Venezuela, Bolivarian Republic of' },
		{ code: 'VN', label: 'Viet Nam' },
		{ code: 'VG', label: 'Virgin Islands, British' },
		{ code: 'WF', label: 'Wallis and Futuna' },
		{ code: 'EH', label: 'Western Sahara' },
		{ code: 'YE', label: 'Yemen' },
		{ code: 'ZM', label: 'Zambia' },
		{ code: 'ZW', label: 'Zimbabwe' }
	];

	static readonly usStates = [
		{ code: 'AL', label: 'Alabama' },
		{ code: 'AK', label: 'Alaska' },
		{ code: 'AZ', label: 'Arizona' },
		{ code: 'AR', label: 'Arkansas' },
		{ code: 'CA', label: 'California' },
		{ code: 'CO', label: 'Colorado' },
		{ code: 'CT', label: 'Connecticut' },
		{ code: 'DE', label: 'Delaware' },
		{ code: 'DC', label: 'District of Columbia' },
		{ code: 'FL', label: 'Florida' },
		{ code: 'GA', label: 'Georgia' },
		{ code: 'HI', label: 'Hawaii' },
		{ code: 'ID', label: 'Idaho' },
		{ code: 'IL', label: 'Illinois' },
		{ code: 'IN', label: 'Indiana' },
		{ code: 'IA', label: 'Iowa' },
		{ code: 'KS', label: 'Kansas' },
		{ code: 'KY', label: 'Kentucky' },
		{ code: 'LA', label: 'Louisiana' },
		{ code: 'ME', label: 'Maine' },
		{ code: 'MD', label: 'Maryland' },
		{ code: 'MA', label: 'Massachusetts' },
		{ code: 'MI', label: 'Michigan' },
		{ code: 'MN', label: 'Minnesota' },
		{ code: 'MS', label: 'Mississippi' },
		{ code: 'MO', label: 'Missouri' },
		{ code: 'MT', label: 'Montana' },
		{ code: 'NE', label: 'Nebraska' },
		{ code: 'NV', label: 'Nevada' },
		{ code: 'NH', label: 'New Hampshire' },
		{ code: 'NJ', label: 'New Jersey' },
		{ code: 'NM', label: 'New Mexico' },
		{ code: 'NY', label: 'New York' },
		{ code: 'NC', label: 'North Carolina' },
		{ code: 'ND', label: 'North Dakota' },
		{ code: 'OH', label: 'Ohio' },
		{ code: 'OK', label: 'Oklahoma' },
		{ code: 'OR', label: 'Oregon' },
		{ code: 'PA', label: 'Pennsylvania' },
		{ code: 'PR', label: 'Puerto Rico' },
		{ code: 'RI', label: 'Rhode Island' },
		{ code: 'SC', label: 'South Carolina' },
		{ code: 'SD', label: 'South Dakota' },
		{ code: 'TN', label: 'Tennessee' },
		{ code: 'TX', label: 'Texas' },
		{ code: 'UT', label: 'Utah' },
		{ code: 'VT', label: 'Vermont' },
		{ code: 'VA', label: 'Virginia' },
		{ code: 'WA', label: 'Washington' },
		{ code: 'WV', label: 'West Virginia' },
		{ code: 'WI', label: 'Wisconsin' },
		{ code: 'WY', label: 'Wyoming' }
	];

	static readonly cdnProvinces = [
		{ code: 'AB', label: 'Alberta' },
		{ code: 'BC', label: 'British Columbia' },
		{ code: 'MB', label: 'Manitoba' },
		{ code: 'NB', label: 'New Brunswick' },
		{ code: 'NL', label: 'Newfoundland and Labrador' },
		{ code: 'NS', label: 'Nova Scotia' },
		{ code: 'NT', label: 'Northwest Territories' },
		{ code: 'NU', label: 'Nunavut' },
		{ code: 'ON', label: 'Ontario' },
		{ code: 'PE', label: 'Prince Edward Island' },
		{ code: 'QC', label: 'Quebec' },
		{ code: 'SK', label: 'Saskatchewan' },
		{ code: 'YT', label: 'Yukon' }
	];

	// ****************************************************************************************************************************
	// Misc
	// ****************************************************************************************************************************

	static readonly daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	static readonly months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	static readonly docExtensions = ['doc', 'docx', 'pdf', 'txt', 'html', 'htm', 'rtf', 'ppt', 'pptx', 'ods', 'xls', 'xlsx', 'ts', 'mp4', 'zip'];
	static readonly installerExtensions = ['exe', 'zip', 'dmg', 'gz', 'tgz', 'msi', 'xz', 'pkg'];

	static readonly freeTokenMask: string = '--hidden--';

	// static readonly listRefreshMS = 60000; // 60 seconds
	static readonly listRefreshMS = 45 * 60000; // 45 minutes

	static readonly multiFileUploadElement: string = 'filesToUpload';

	static readonly standardMessageAutoCloseTimeSecs = 15;
	static readonly shortMessageAutoCloseTimeSecs = 5;

	static readonly forbiddenSelfRegistrationDomains = [
		'gmail.com',
		'yahoo.com',
		'hotmail.com',
		'icloud.com',
		'me.com',
		'mac.com',
		'live.com',
		'msn.com',
		'haivision.com',
		'zieper.com',
		'qq.com'
	];

	static readonly hostIDIPNote: string = 'Note: This IP address may be the address of the system that requested the license OR address most recently used to report meter usage (v16+).';

	// settings that the user can see/set...returned with client side version of user
	static readonly authObjectSettings = [
		AppConstants.starIconKey,
		AppConstants.stagedEmailKey,
		AppConstants.newFeatureAlertedKey,
		// AppConstants.showBxProtocolStatsOverride
	];
	// static readonly urlValidationRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


	/* Marketplace */

	/* AWS Marketplace Products */
	static readonly awsMarketPlaceProducts = [
		{
			productCode: '7w2fuo2xp3en2usnq677e88fb',
			label: 'ZEN Master for MediaConnect, Elemental Link and MediaLive - Live Linear',
			object_type: 'zenmaster',
			dimensions: [
				{
					apiKey: 'zm_emx_traffic',
					label: 'ZEN Master using AWS MediaConnect, Elemental Link and MediaLive',
					method: 'mediaconnect_traffic_total_gb'
				},
				{
					apiKey: 'zm_bx_traffic',
					label: 'Zen Master Traffic using Zixi Broadcaster',
					method: 'zenmaster_traffic_total_gb'
				}
			]
		},
		{
			productCode: '5b7jc98tee12bnmzd3tri0ei5',
			label: 'Zixi - Zen Master for CDI/JPEG-XS',
			object_type: 'zenmaster',
			dimensions: [
				{
					apiKey: 'zm_cdi_traffic',
					label: 'Zen Master CDI/JEPG-XS Traffic using Media Connect (per hour)',
					method: 'mediaconnect_cdi_jpgxs_traffic_total_hours'
				}
			]
		},
		{
			productCode: 'duziwy1bbffe6lbpw1b47e34x',
			label: 'Zixi Broadcaster',
			object_type: 'activation',
			dimensions: [
				{
					apiKey: 'bx_traffic',
					label: 'Broadcaster Transport Traffic',
					method: 'broadcaster_key_traffic_total_gb'
				}
			]
		}
	];

	/* Azure Marketplace Products - for testing of multiple marketplaces for now */
	static readonly azureMarketPlaceProducts = [

		// offer ID: zenmaster_traffic_monitor alias: Zixi ZEN Master
		// plan ID: zenmaster_metered_use
		// dimension: zm_bx_traffic

		// offer ID: broadcaster_traffic alias : Zixi Broadcaster
		// plan ID: broadcaster_metered_use
		// dimension: bx_traffic

		// in the case of Azure - the productCode is actually holding the plan ID

		{
			productCode: 'zenmaster_metered_use',
			label: 'Zixi ZEN Master for Live Linear',
			object_type: 'zenmaster',
			dimensions: [
				{
					apiKey: 'zm_bx_traffic',
					label: 'Zen Master Traffic using Zixi Broadcaster',
					method: 'zenmaster_traffic_total_gb'
				}
			]
		},
		{
			productCode: 'broadcaster_metered_use',
			label: 'Zixi Broadcaster',
			object_type: 'activation',
			dimensions: [
				{
					apiKey: 'bx_traffic',
					label: 'Broadcaster Transport Traffic',
					method: 'broadcaster_key_traffic_total_gb'
				}
			]
		}
	];

	/* GCP Marketplace Products - for testing of multiple marketplaces for now */
	static readonly gcpMarketPlaceProducts = [
		{
			productCode: 'zixi-zen-master.endpoints.zixi-gcp-marketplace-public.cloud.goog',
			label: 'Zixi - Zen Master',
			object_type: 'zenmaster',
			dimensions: [
				{
					apiKey: 'zixi-zen-master.endpoints.zixi-gcp-marketplace-public.cloud.goog/zixi_zen_master_endpoints_subscription',
					label: 'Zen Master Traffic using Zixi Broadcaster',
					method: 'zenmaster_traffic_total_gb'
				}
			]
		},
		{
			productCode: 'zixi-broadcaster.endpoints.zixi-gcp-marketplace-public.cloud.goog',
			label: 'Zixi - Broadcaster',
			object_type: 'activation',
			dimensions: [
				{
					apiKey: 'zixi-broadcaster.endpoints.zixi-gcp-marketplace-public.cloud.goog/per_gib_delivered',
					label: 'Broadcaster Transport Traffic',
					method: 'broadcaster_key_traffic_total_gb'
				}
			]
		}
	];

	static readonly marketPlaceSelections: any = [
		{ value: '', label: 'None', icon: null, selections: [], accountLabel: '' },
		{ value: 'aws', label: 'Amazon Web Services', icon: null, selections: AppConstants.awsMarketPlaceProducts, accountLabel: 'Customer ID' },
		{ value: 'azure', label: 'Microsoft Azure', icon: null, selections: AppConstants.azureMarketPlaceProducts, accountLabel: 'Account ID' },
		{ value: 'gcp', label: 'Google Compute Platform', icon: null, selections: AppConstants.gcpMarketPlaceProducts, accountLabel: 'Entitlement ID' }
	];

	static readonly quizTypeSelections: any[] = [
		{
			value: 'quiz',
			shortLabel: 'Quiz',
			label: 'Quiz - a series of questions with correct answers',
			extra: ''
		}, {
			value: 'poll',
			shortLabel: 'Poll/Survery',
			label: 'Poll/Survery - a series of questions',
			extra: ''
		}
	];

	static readonly quizStatusSelections: any[] = [
		{
			value: 'private',
			shortLabel: 'Private',
			label: 'Private - only quiz admins can see this quiz',
			extra: ''
		}, {
			value: 'active',
			shortLabel: 'Active',
			label: 'Active - participants can provide answers',
			extra: ''
		}, {
			value: 'complete',
			shortLabel: 'Complete',
			label: 'Complete - the quiz is finished, participants can see their results',
			extra: ''
		}
	];

	static readonly quizAnswerTypeSelections: any[] = [
		{
			value: 'text',
			shortLabel: 'Text (Short Answer)',
			label: 'Text (Short Answer) - the participant types in an answer in a single line text box',
			extra: ''
		}, {
			value: 'long-text',
			shortLabel: 'Text (Long Answer)',
			label: 'Text (Long Answer) - the participant types in an answer in a multi-line text box',
			extra: ''
		}, {
			value: 'multi-choice-single',
			shortLabel: 'Multiple Choice (Single Answer)',
			label: 'Multiple Choice (Single Answer) - the participant selects an answer from a selection of choices',
			extra: ''
		}, {
			value: 'multi-choice-multi',
			shortLabel: 'Multiple Choice (Multiple Answers)',
			label: 'Multiple Choice (Multiple Answers) - the participant selects one or more answers from a selection of choices',
			extra: ''
		}
	];

	static readonly staffMenuItems: Models.NavItem[] = [
		{
			link: {
				name: 'Users',
				icon: AppConstants.sectionIcons.users,
				router: AppConstants.urls.users,
				iconClass: 'cp-admin-nav-item'
			}
		},
		{
			link: {
				name: 'Organizations',
				icon: AppConstants.sectionIcons.organizations,
				router: AppConstants.urls.organizations,
				iconClass: 'cp-admin-nav-item'
			}
		},
		{
			link: {
				name: 'ZEN Master',
				icon: AppConstants.sectionIcons.zencustomers,
				router: AppConstants.urls.zencustomers,
				iconClass: 'cp-admin-nav-item'
			},
		},
		{
			link: {
				name: 'Shared Files',
				icon: AppConstants.sectionIcons.bundles,
				router: AppConstants.urls.bundles,
				iconClass: 'cp-admin-nav-item'
			}
		},
		{
			link: {
				name: 'Key Search',
				icon: ['fas', 'search'],
				router: AppConstants.urls.licensing,
				iconClass: 'cp-admin-nav-item'
			}
		},

		{
			link: {
				name: 'Licensing',
				icon: AppConstants.sectionIcons.licensing,
				router: '',
				iconClass: 'cp-admin-nav-item'
			},
			isCollapsed: true,
			dropDownMode: true,
			id: 'licensingSubMenu',
			sublinks: [
				{
					name: 'Products',
					icon: AppConstants.sectionIcons.licensingadmin,
					router: AppConstants.urls.licensingadmin + '/products',
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Properties',
					icon: AppConstants.sectionIcons.licensingadmin,
					router: AppConstants.urls.licensingadmin + '/properties',
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Templates',
					icon: AppConstants.sectionIcons.licensingadmin,
					router: AppConstants.urls.licensingadmin + '/templates',
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Protocol Sets',
					icon: AppConstants.sectionIcons.licensingadmin,
					router: AppConstants.urls.licensingadmin + '/protocol-sets',
					iconClass: 'cp-admin-nav-item'
				},
			]
		},
		{
			link: {
				name: 'Objects',
				icon: ['fas', 'cube'],
				router: '',
				iconClass: 'cp-admin-nav-item'
			},
			isCollapsed: true,
			dropDownMode: true,
			id: 'objectsSubMenu',
			sublinks: [
				{
					name: 'Platforms',
					icon: AppConstants.sectionIcons.platforms,
					router: AppConstants.urls.platforms,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Products',
					icon: AppConstants.sectionIcons.products,
					router: AppConstants.urls.products,
					iconClass: 'cp-admin-nav-item'
				},
				{

					name: 'Packages',
					icon: AppConstants.sectionIcons.packages,
					router: AppConstants.urls.packages,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Builds',
					icon: AppConstants.sectionIcons.builds,
					router: AppConstants.urls.builds,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Documents',
					icon: AppConstants.sectionIcons.documents,
					router: AppConstants.urls.documents,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Files',
					icon: AppConstants.sectionIcons.files,
					router: AppConstants.urls.files,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Messages',
					icon: AppConstants.sectionIcons.systemmessages,
					router: AppConstants.urls.systemmessages,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Partners',
					icon: AppConstants.sectionIcons.partneradmin,
					router: AppConstants.urls.partneradmin,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Quizzes + Polls',
					icon: AppConstants.sectionIcons.quizzes,
					router: AppConstants.urls.quizzes,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Marketplace',
					icon: AppConstants.sectionIcons.marketplace,
					router: AppConstants.urls.marketplace + '/info',
					iconClass: 'cp-admin-nav-item'
				},
			]
		},
		{
			link: {
				name: 'Logs/Reports',
				icon: ['fas', 'history'],
				router: '',
				iconClass: 'cp-admin-nav-item'
			},
			isCollapsed: true,
			dropDownMode: true,
			id: 'logsSubMenu',
			sublinks: [
				{
					name: 'System',
					icon: AppConstants.sectionIcons.adminlogs,
					router: AppConstants.urls.adminlogs,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Downloads',
					icon: AppConstants.sectionIcons.downloadlogs,
					router: AppConstants.urls.downloadlogs,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Clicks',
					icon: AppConstants.sectionIcons.linklogs,
					router: AppConstants.urls.linklogs,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Logins',
					icon: AppConstants.sectionIcons.loginlogs,
					router: AppConstants.urls.loginlogs,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Notifications',
					icon: AppConstants.sectionIcons.emailqueue,
					router: AppConstants.urls.emailqueue,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Marketplace',
					icon: AppConstants.sectionIcons.marketplace,
					router: AppConstants.urls.marketplace + '/logs',
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Tasks',
					icon: AppConstants.sectionIcons.backgroundtasks,
					router: AppConstants.urls.backgroundtasks,
					iconClass: 'cp-admin-nav-item'
				},
				{
					name: 'Reports',
					icon: AppConstants.sectionIcons.reports,
					router: AppConstants.urls.reports,
					iconClass: 'cp-admin-nav-item'
				}
			]
		},
		{
			link: {
				name: 'Traffic Calc',
				icon: AppConstants.sectionIcons.calculators,
				router: AppConstants.urls.calculators + '/traffic-calc',
				iconClass: 'cp-admin-nav-item'
			}
		}
	];

	static readonly basicMenuItems: Models.NavItem[] = [
		{
			link: {
				name: 'Software', // aka builds aka installers aka releases
				icon: AppConstants.sectionIcons.mybuilds,
				router: AppConstants.urls.mybuilds,
				iconClass: 'cp-user-nav-item'
			}
		},
		{
			link: {
				name: 'Documentation',
				icon: AppConstants.sectionIcons.mydocuments,
				router: AppConstants.urls.mydocuments,
				iconClass: 'cp-user-nav-item'
			}
		},
		{
			link: {
				name: 'Partners',
				icon: AppConstants.sectionIcons.mypartners,
				router: AppConstants.urls.mypartners,
				iconClass: 'cp-user-nav-item'
			},
		},
		{
			link: {
				name: 'License Keys',
				icon: AppConstants.sectionIcons.mykeys,
				router: AppConstants.urls.mykeys,
				iconClass: 'cp-user-nav-item'
			},
		},
		{
			link: {
				name: 'File Sharing',
				icon: AppConstants.sectionIcons.myfilesharing,
				router: AppConstants.urls.myfilesharing,
				iconClass: 'cp-user-nav-item'
			}
		},
		{
			link: {
				name: 'My Settings',
				icon: AppConstants.sectionIcons.mysettings,
				router: AppConstants.urls.mysettings,
				iconClass: 'cp-user-nav-item'
			},
		},
		{
			link: {
				name: 'Packages',
				icon: AppConstants.sectionIcons.mypackages,
				router: AppConstants.urls.mypackages,
				iconClass: 'cp-user-nav-item'
			}
		},
		{
			link: {
				name: 'Notifications',
				icon: AppConstants.sectionIcons.mynotifications,
				router: AppConstants.urls.mynotifications,
				iconClass: 'cp-user-nav-item'
			}
		}
	];

	static readonly staffExtraMenuItems: Models.NavItem[] = [
		{
			link: {
				name: 'Quizzes + Polls',
				icon: AppConstants.sectionIcons.myquizzes,
				router: AppConstants.urls.myquizzes,
				iconClass: 'cp-user-nav-item'
			}
		},
		{
			link: {
				name: 'Ask Zixi...',
				icon: AppConstants.sectionIcons.myquestions,
				router: AppConstants.urls.myquestions,
				iconClass: 'cp-user-nav-item'
			}
		}

	];

	static readonly reportTypes: any[] = [
		{
			className: 'AllDownloadsReport',
			label: 'All Downloads Report',
			description: '',
			modal: '',
			showInList: false,
			staffOnly: true,
			filePrefix: 'Downloads-'
		}, {
			className: 'KeyLicensesReport',
			label: 'Key Licenses Report',
			description: 'This is a report of all licenses issued against a single key.',
			modal: '',
			showInList: false,
			staffOnly: false,
			filePrefix: 'Key-Licenses-'
		}, {
			className: 'KeyUsageSummaryReport',
			label: 'Key Usage Summary Report',
			description: 'This is a report that summarizes usage for a single key broken down by host ID.',
			modal: '',
			showInList: false,
			staffOnly: true,
			filePrefix: 'Key-Usage-Summary-'
		}, {
			className: 'LicenseUsageReport',
			label: 'License Usage Report',
			description: 'This is a report of license key usage.',
			modal: '',
			showInList: false,
			staffOnly: false,
			filePrefix: 'License-Key-Usage-'
		}, {
			className: 'ProductDownloadsReport',
			label: 'Product Downloads Report',
			description: 'This is a report of all downloads of a product.',
			modal: '',
			showInList: false,
			staffOnly: true,
			filePrefix: 'Product-Downloads-'
		}, {
			className: 'ZenPullTargetsReport',
			label: 'Zen Pull Targets Report',
			description: 'This is a specialized report that (currently) only applies to Music Choice.',
			modal: '',
			showInList: false,
			staffOnly: true,
			filePrefix: 'Zen-Pull-Targets-Report-'
		},

		{
			className: 'BxVersionReport',
			label: 'Active Broadcaster Versions',
			description: 'Report of all customer/partner keys with active Broadcaster keys with a summary of Broadcaster versions.',
			modal: 'BxVersionReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Bx-Versions-'
		}, {
			className: 'KeysSalesforceReport',
			label: 'Active Customer/Partner Broadcaster Keys and Connection to Salesforce',
			description: 'Report of all active non-internal broadcaster keys that are linked to a Salesforce opportunitity and how it relates to the key\'s organization\'s Salesforce account.\n'
				+ ' Also includes the opportunitity\'s sales engineer(s) and stage along with POC and Contract end dates (if set).',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Keys-Salesforce-'
		}, {
			className: 'ParentAccountsReport',
			label: 'Organizations and Saleforce Account Parent/Children',
			description: 'ZCP Organizations linked to Salesforce accounts and info on if the account has a parent account and/or child accounts.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Organizations-SF-Parent-Accounts-'
		}, {
			className: 'StaffActivityReport',
			label: 'Staff Activity',
			description: 'Report of staff accessing the customer portal.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Staff-Portal-Use-'
		}, {
			className: 'ZenMasterSitesReport',
			label: 'ZEN Master Sites',
			description: 'This reports lists the deployed ZEN Master sites/customers.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'ZEN-Master-Sites-'
		}, {
			className: 'PortalUsersReport',
			label: 'Portal Users',
			description: 'This is a report of all portal users.  The report is broken down by non-Organizatin Users, non-Staff Organization Users, Active Staff, Former/Disabled Staff',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Portal-Users-'
		}, {
			className: 'PortalOrganizationsReport',
			label: 'Portal Organizations',
			description: 'This is a report of all portal organizations.  Also includes breakdown of organizations (if any) that share a common Salesforce account.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Portal-Organizations-'
		}, {
			className: 'BillingCodesUsageSummaryReport',
			label: 'Billing Code Summary',
			description: 'This report summarizes the use of billing codes.  It shows which keys have reported data against the billing code, the amount of traffic and the earliest and latest dates that traffic was reported. It also identifies billing codes that have never been used.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Billing-Codes-Usage-summary-'
		}, {
			className: 'ActiveExpiredDisabledReport',
			label: 'Report on Expired/Disabled Keys Still Active',
			description: 'Report of keys with broadcasters still actively reporting back to license server after key has expired and/or has been disabled.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Active-Expired-Disabled-'
		}, {
			className: 'PenTestResultsReport',
			label: 'Report on Pen Tests',
			description: 'Latest results of penetration tests.  Includes one sheet with all failures (open to the internet + using default username/password) and one sheet with all open to the internet, but not using default username + password.',
			modal: '',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Pen-Test-Results-'
		}, {
			className: 'CommercialAnalysisReport',
			label: 'Commercial Analysis on Production Broadcaster Keys',
			description: 'This report has info/exceptions/warnings based on production broadcasters keys that have a commercial type set.',
			modal: 'CommercialAnalysisReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Commerical-Analysis-'
		}, {
			className: 'TranscodeReport',
			label: 'Transcode Report',
			description: 'Report on use of H264 and Nvidia transcoding.',
			modal: 'TranscodeReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Transcode-'
		}, {
			className: 'IncidentsReport',
			label: 'Incidents Report',
			description: 'Report on ZEN Master incidents.',
			modal: 'IncidentsReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'Incidents-'
		}, {
			className: 'UsagePatternsReport',
			label: 'Usage Patterns',
			description: 'Report usage patterns with license keys over time.',
			modal: 'UsagePatternsReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'UsagePatterns-'
		}, {
			className: 'SystemWideUsageReport',
			label: 'System Wide Usage Across All Production Broadcaster, ZEN Master Generic Usage or ZEN Master MediaConnect Usage Keys',
			description: 'This report summarizes usage across all Production Broadcaster, ZEN Master Generic Usage or ZEN Master MediaConnect Usage Keys for a specified time period.',
			modal: 'SystemWideUsageReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'System-Wide-Usage-'
		}, {
			className: 'AccountReviewReport',
			label: 'Account Review',
			description: 'Report on one or more accounts(organizations) over a specific period of time.',
			modal: 'AccountReviewReportModal',
			showInList: true,
			staffOnly: true,
			filePrefix: 'AccountReview-'
		}
	];

	static readonly actionIcons: any = {
		add: 'plus',
		edit: 'pencil-alt',
		copy: 'copy',
		delete: 'trash',
		refresh: 'redo',
		bulk: 'folder-plus',
		remove: 'times',
		submit: 'check',
		formInvalid: 'exclamation-triangle',
		cancel: 'times'
	};

}

export default AppConstants;
