// tslint:disable: variable-name

import AppConstants from './app-constants';

export interface PlatformFile { platform_id: number; file_id: number; label: string; }
export interface ProductPlatform { product_id: number; platform_id: number; }
// export interface UiAlert { msg: string; msgClass: string; msgCode: string; msgTime: Date; autoCloseSeconds: number; }
export interface UiAlert { msg: string; msgClass: string; msgCode: string; msgTime: Date; autoCloseSeconds: number; timeMsg: string }
export interface AutoBuildResponse { msg: string; msgClass: string; buildID: number; fileObj: File }

export interface MailFailure { timestamp: Date; recipient: string; subject: string; message: string; description: string; }

export interface NavLink { name: string; icon: any[]; router?: string; iconClass?: string; }
export interface NavItem { link: NavLink; sublinks?: NavLink[]; isCollapsed?: boolean; id?: string; dropDownMode?: boolean; }

export class AppSetting {
	constructor(
		public setting_key: string,
		public setting_value: string,
		public edited_by: number,
		public edited_on: Date
	) { }
}

export class APIKey {
	constructor(
		public user_id: number,
		public api_key: string,
		public added_by: number = 0,
		public added_on: Date = null,
	) { }
}

export class AuthUser {
	constructor(
		public id: number,
		public email: string,
		public name: string,
		public role: string,
		public env: string,
		public settings: UserSetting[] = [],
		public mergedAccessTags: string[] = []
	) { }
}

export class Organization {
	constructor(
		public id: number,
		public otype: string,
		public name: string,
		public information: string,
		public salesforce_account_id: string,
		public is_enabled: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public last_download: Date = null,
		public salesforce_account_owner: string = '',
		public salesforce_account_owner_id: number = 0,
		public salesforce_se: string = '',
		public salesforce_se_id: number = 0,
		public flags: any = null,
		public email_domains: string = '',
		public parent_org_id: number = 0,

		public is_favorite: number = 0,
		public is_onboarded: number = 0,

		public product_platforms: ProductPlatform[] = [],
		// public zen_domains: ZenDomain[] = [],
		// public partnerships: Partnership[] = [],

		public build_ids: number[] = [],
		public document_ids: number[] = [],
		public group_ids: number[] = [],
		// public tech_support_ids: number[] = [],

		public num_partnerships: number = 0,
		public num_zen_domains: number = 0,
		public num_active_zen_domains: number = 0,
		public num_users: number = 0,
		public num_welcomed: number = 0,
		public num_keys: number = 0,
		public num_billing_codes: number = 0
	) { }
}

export class UserSetting {
	constructor(
		public user_id: number,
		public setting_key: string,
		public setting_value: string,
		public edited_by: number = 0,
		public edited_on: Date = null,
	) { }
}

export class User {
	constructor(
		public id: number,
		public org_id: number,
		public email: string,
		public password: string,
		public salt: string,
		public role: string,
		public name: string,
		public title: string,
		public information: string,
		public is_enabled: number = 1,
		public is_deleted: number = 0,
		public last_login: Date = null,
		public reset_password_token: string = '',
		public reset_password_expires: Date = null,
		public use_sso: number = 0,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public last_download: Date = null,
		public notifications: NotificationSettings = null,
		public flags: any = null,

		public org_name: string = '',
		public num_keys: number = 0,

		public settings: UserSetting[] = [],
		public package_ids: number[] = [],
		public group_ids: number[] = [],
		public is_onboarded: number = 0,

		public directAccessTags: string[] = [],
		public roleAccessTags: string[] = [],
		public groupAccessTags: string[] = [],
		public mergedAccessTags: string[] = [],

		public settingsByKey: any = {}
	) { }
}

export class Platform {
	constructor(
		public id: number,
		public ptype: string,
		public name: string,
		public information: string,
		public filename_keyword: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public product_ids: number[] = []
	) { }
}

export class Product {
	constructor(
		public id: number,
		public ptype: string,
		public name: string,
		public information: string,
		public filename_prefix: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public flags: any = null,
		public download_build_description: string = '',
		public download_document_description: string = '',
		public sort_order: number = 0,

		public platform_ids: number[] = [],
		public build_ids: number[] = [],
		public document_ids: number[] = []
	) { }
}

// public has_downloads: number,
// public private_only: number,

export class Build {
	constructor(
		public id: number,
		public product_id: number,
		public version: string,
		public label: string,
		public information: string,
		public release_notes: string,
		public release_notes_file_id: number,
		public is_enabled: number,
		public is_private: number,
		public is_frozen: number = 0,
		public is_retired: number = 0,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public last_download: Date = null,

		public prod_name: string = '',

		public platform_files: PlatformFile[] = [],
		public org_ids: number[] = [],
		public package_ids: number[] = [],
		public group_ids: number[] = []
	) { }
}

export class File {
	constructor(
		public id: number,
		public name: string,
		public location_info: string,
		public size: number,
		public is_deleted: number = 0,
		public md5: string = '',
		public free_access_token: string = '',
		public disable_autobuild: number = 0,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public last_download: Date = null,
		public downloads: number = 0,
		public product: string = '',
		public platform: string = '',
		public version: string = '',
		public uses: string[] = []
	) { }
}

export class Document {
	constructor(
		public id: number,
		public product_id: number,
		public dtype: string,
		public file_id: number,
		public label: string,
		public link: string,
		public is_enabled: number,
		public is_private: number,
		public can_open: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public last_download: Date = null,

		public prod_name: string = '',

		public org_ids: number[] = [],
		public package_ids: number[] = [],
		public group_ids: number[] = []
	) { }
}

export class DownloadableBuild {
	constructor(
		public build: Build,
		public product: Product,
		public platforms: Platform[],
		public files: File[],
		public is_direct: boolean = false
	) { }
}

export class DownloadableDocument {
	constructor(
		public document: Document,
		public product: Product,
		public file: File,
		public is_direct: boolean = false
	) { }
}

export class OrganizationGroup {
	constructor(
		public id: number,
		public gtype: string,
		public name: string,
		public information: string,
		public is_enabled: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public product_platforms: ProductPlatform[] = [],
		public build_ids: number[] = [],
		public document_ids: number[] = [],
		public org_ids: number[] = [],
		public manager_ids: number[] = []
	) { }
}

export class UserGroup {
	constructor(
		public id: number,
		public gtype: string,
		public name: string,
		public information: string,
		public is_enabled: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public accessTags: string[] = [],
		public user_ids: number[] = [],
		public manager_ids: number[] = []
	) { }
}

// export class PackageSetting {
// 	constructor(
// 		public user_id: number,
// 		public setting_key: string,
// 		public setting_value: string,
// 		public edited_by: number = 0,
// 		public edited_on: Date = null,
// 	) { }
// }

export class Package {
	constructor(
		public id: number,
		public ptype: string,
		public access_code: string,
		public name: string,
		public information: string,
		public is_enabled: number,
		public user_description: string = '',
		public allow_subscribe: number = 1,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public key_templates: PackageKeyTemplate[] = [],
		public product_platforms: ProductPlatform[] = [],

		public build_ids: number[] = [],
		public document_ids: number[] = [],
		public num_users: number = 0
	) { }
}

export class PackageKeyTemplate {
	constructor(
		public id: number,
		public package_id: number,
		public template_id: number,
		public name: string,
		public allow_multiple: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
	) { }
}
// deprecated
export class PackageActivationRule {
	constructor(
		public id: number,
		public package_id: number,
		public name: string,
		public key_product: string,
		public key_type: string,
		public private_key: string,
		public allow_multiple: number,

		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
	) { }
}

export class DownloadLog {
	constructor(
		public initiated_on: Date,
		public user_id: number,
		public file_id: number,
		public file_name: string,
		public file_size: number,
		public user_info: string,
		public location: string = '',
		public browser: string = ''
	) { }
}

export class AdminLog {
	constructor(
		public added_by: number,
		public added_on: Date,
		public obj_type: string,
		public obj_id: number,
		public action: string,
		public information: string,
		public user_info: string,
		public obj_name: string
	) { }
}

export class JournalEntry {
	constructor(
		public id: number,
		public obj_type: string,
		public obj_id: number,
		public information: string,
		public added_by: number,
		public added_on: Date,
	) { }
}

export class LoginHistory {
	constructor(
		public login_on: Date,
		public org_id: number,
		public user_id: number,
		public user_info: string,
		public location: string,
		public browser: string,
		public tz_offset: number,
		public tz_id: string
	) { }
}

export class Partnership {
	constructor(
		public id: number,
		public org_id: number,
		public name: string,
		public information: string,
		public is_listed: number,
		public extras: PartnershipExtras = null,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		// public link: string = '',
		// public version: string = '',
		// public status: string = '',
		// public build_version: string = '',
		// public itype: string = '',
		// public dtype: string = '',
		// public document_link: string = '',
	) { }
}

export class PartnershipExtras {
	constructor(
		public link: string = '',
		public document_link: string = '',
		public version: string = '',
		public status: string = '',
		public build_version: string = '',
		public itype: string = '',
		public dtype: string = '',
		public zm_certified: number = 0,
		public sdk_features: string = ''
	) { }
}

export class PartnerDirectoryItem {
	constructor(
		public org_name: string = '',
		public partnership: Partnership
	) { }
}

export class DownloadLink {
	constructor(
		public url: string,
		public expires: Date,
		public expiresMinutes: number,
		public sampleCommands: string[]
	) { }
}

export class LinkLog {
	constructor(
		public clicked_by: number,
		public clicked_on: Date,
		public url: string,
		public user_info: string,
		public location: string = '',
		public browser: string = ''
	) { }
}

export class UserLicenseKey {
	constructor(
		public id: number,
		public user_id: number,
		public package_id: number,
		public activation_id: number,
		public activation_key: string,
		public add_method: string,
		public label: string,
		public is_deleted: number = 0,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public activation: LPActivation = null,
		public added_by_info: string = '',
		public user: User = null
	) { }
}

export class UserLicenseKeySnooze {
	constructor(
		public id: number,
		public user_id: number,
		public user_key_id: number,
		public expires_at: Date = null,
		public snooze_type: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null
	) { }
}

export class UserHostId {
	constructor(
		public id: number,
		public user_id: number,
		public hostid: string,
		public label: string,
		public add_method: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null
	) { }
}

export class SystemMessage {
	constructor(
		public id: number,
		public message: string,
		public url: string,
		public start_at: Date,
		public end_at: Date,
		public can_ack: number,
		public is_enabled: number,
		public staff_only: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null
	) { }
}

export class SavedLicenseSearch {
	constructor(
		public id: number,
		public user_id: number,
		public name: string,
		public parameters: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null
	) { }
}

export class EmailQueueEntry {
	constructor(
		public id: number,
		public message_status: string,
		public message_type: string,
		public obj_type: string,
		public obj_id: number,
		public user_id: number,
		public message_from: string,
		public message_to: string,
		public message_cc: string,
		public message_bcc: string,
		public message_subject: string,
		public message_text_body: string,
		public message_html_body: string,
		public not_before: Date,

		public unsub_code: string = '',
		public message_id: string = '',
		public sent_at: Date = null,

		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public num_attachments: number = 0,
		public attachments: EmailQueueAttachment[] = []
	) { }
}

export class EmailQueueAttachment {
	constructor(
		public id: number,
		public email_queue_id: number,
		public file_name: string,
		public file_body: string,
		public added_by: number = 0,
		public added_on: Date = null
	) { }
}

export class NotificationSettings {
	constructor(
		public deliveryMode: string = 'immediate',
		public startHour: number = AppConstants.defNotificationStartHour,
		public endHour: number = AppConstants.defNotificationEndHour,

		public timezone: string = '',
		public daysOfWeek: string[] = [],

		public autoSubscribe: boolean = true,

		public receiveBuildMessages: boolean = true,
		public includedBuildProductIDs: number[] = [],
		public excludedBuildProductIDs: number[] = [],
		public includedBuildPlatformIDs: number[] = [],
		public excludedBuildPlatformIDs: number[] = [],

		public receiveDocumentMessages: boolean = true,
		public includedDocumentProductIDs: number[] = [],
		public excludedDocumentProductIDs: number[] = [],

		public receiveKeyExpirationMessages: boolean = true,
		public expirationDays: number = AppConstants.defLicenseWarningDays,

		public receiveKeyUsageMessages: boolean = true,
		public usagePercentage: number = AppConstants.defLicenseUsagePercentage,

		public receiveProjectedKeyUsageMessages: boolean = true,
		public projectedPercentage: number = AppConstants.defLicenseUsagePercentage,

		public receiveProtocolKeyUsageMessages: boolean = true,
		public protocolPercentage: number = AppConstants.defLicenseUsagePercentage,

		public receiveOfflineHostIDsMessages: boolean = true,

		public receiveKeysReportMessages: boolean = true,
		public keysReportFrequency: string = AppConstants.defaultKeysReportFrequency,

		public receiveGenerallMessages: boolean = true,

		public skipEmptyReports: boolean = false,

		public receiveStaffKeyReportMessages: boolean = false,
		public keyReportSavedSearches: number[] = [],

		public receiveStaffZENMasterReportMessages: boolean = false,
		public includedZenMasterTypes: string[] = [],

		public receiveStaffOrgMeterReportMessages: boolean = false,
		public orgMeterReportOrgIDs: number[] = []

	) { }
}

export class KeySearchSettings {
	constructor(
		public productsFilter: string[] = [],
		public typesFilter: string[] = [],
		public keyFilter = '',
		public hostidFilter = '',
		public textFilter = '',
		public reportDays: number = 0,
		public usageDays: number = 0,
		public touchedDays: number = 0,
		public notTouchedDays: number = 0,
		public expiryDays: number = 0,
		public usagePercent: number = 0,
		public updatedDays: number = 0,
		public userIDs: number[] = [],
		public protocolsFilter: string[] = [],
		public specialFilter: string[] = [],
		public orgIDs: number[] = [],
		public acctOwnerIDs: number[] = [],
		public techRepIDs: number[] = [],
		public andBooleanKeyProperties: string[] = [],
		public orBooleanKeyProperties: string[] = [],
		public zcpTouchedBy: number[] = [],
		public orgTypeFilter: string[] = [],
		public billingCodeFilter: string[] = [],
		public keyTemplateFilter: number[] = [],
		public commercialTypeFilter: string[] = [],
		public protocolSetFilter: number[] = [],
	) { }
}

export class Bundle {
	constructor(
		public id: number,
		public btype: string,
		public sub_type: string,
		public information: string,
		public s3_key: string,
		public staff_id: number = 0,
		public org_id: number = 0,
		public lock_at: Date = null,
		public delete_at: Date = null,
		public deleted_at: Date = null,
		public notified_at: Date = null,
		public alerted_at: Date = null,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public user_ids: number[] = [],
		public files: BundleFile[] = [],

		public staff_name: string = '',
		public org_name: string = '',
		public user_names: string[] = []
	) { }
}

export class BundleFile {
	constructor(
		public id: number,
		public bundle_id: number,
		public name: string,
		public size: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public last_download: Date = null
	) { }
}

export class BackgroundTask {
	constructor(
		public id: number,
		public task_type: string,
		public info: string,
		public results: string,
		public affected: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public ended_on: Date = null
	) { }
}

export class KeyWriteAccess {
	constructor(
		public activation_id: number,
		public user_id: number,
		public added_by: number = 0,
		public added_on: Date = null
	) { }
}

// *******************************************************************
// Objects from external Systems (zen master, licensing, etc.)
// *******************************************************************

export class ZenDomain {
	constructor(
		public id: number,
		public org_id: number,
		public zm_customer_id: number,
		public prefix: string,
		public zmtype: string,
		public information: string,

		public aws_mediaconnect_usage_key: string = '',
		public generic_zm_usage_key: string = '',

		public marketplace: MarketPlaceConfiguration = null,

		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
	) { }
}

export class ZenBuild {
	constructor(
		public build_id: number,
		public product_name: string,
		public platform_name: string,
		public version: string,
		public is_private: number,
		public file_id: number,
		public file_name: string,
		public bucket: string,
		public added_on: Date = null
	) { }
}

/*
	removing:
		host_ecdsa_public
		host_ecdsa_private
		host_rsa_public
		host_rsa_private
		broadcasters_secret
		dtls_acm_cert_arn

		public domainer_instance_type: string = '',
		public domainer_ami: string = '',

	*/

// from the ZB DB customer table
export class ZenMasterCustomer {
	constructor(
		public id: number = 0,
		public name: string = '',
		public dns_prefix: string = '',
		public created_at: Date = null,
		public updated_at: Date = null,
		public is_enabled: number = 0,
		public is_deleted: number = 0,
		public eip: string = '',
		public aws_role_arn: string = '',
		public aws_external_id: string = '',
		public instance_id: string = '',
		public content_analysis: number = 0,
		public monitor_id: string = '',
		public monitor_time: Date = null,
		public debug_monitor: number = 0,
		public traceroute_history: number = 0,
		public max_bx_version: string = '',
		public allow_zixi_support: number = 0,
		public automation: number = 0,
		public allow_zixi_support_write: number = 0,
		public enterprise: number = 0,
		public live_events: number = 0,
		public allow_agentz: number = 0,
		public allow_non_sso_login: number = 0,

		public environment: string = '',
		public zcp_type: string = '',
		public zcp_org_id: number = 0,

		public aws_mediaconnect_usage_key: string = '',
		public generic_zm_usage_key: string = '',

		public marketplace: MarketPlaceConfiguration = null,

		public created_days: number = 0,
		public last_login_days: number = 0,
		public assessment: string = '',
		public num_active_bx: number = 0
	) { }
}

// from the ZB DB user table
export class ZenMasterUser {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public email: string,
		public created_at: Date,
		public updated_at: Date,
		public is_enabled: number,
		public is_admin: number,
		public is_zixi_admin: number,
		public is_zixi_support: number,
		public is_zixi_support_write: number,
		public is_deleted: number,
		public sso_id: string,
		public last_login_at: Date,

		public zcp_user_id: number = 0,
		public zcp_user_org_id: number = 0,
		public zen_customer: ZenMasterCustomer = null
	) { }
}

// from the ZB DB broadcaster_cluster, versions, aws_accounts, azure_accounts, gcp_accounts tables
export class ZenMasterCluster {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public version: string,
		public config_name: string,
		public cloud_type: string,
		public instance_type: string,
		public primary_size: number,
		public backup_size: number,
		public aws_account_id: number,

		public zen_customer: ZenMasterCustomer = null,
		public tags: ZenTag[] = []
	) { }
}

export class ZenTag {
	constructor(
		public id: number,
		public name: string
	) { }
}

// # id, customer_id, name, broadcaster_cluster_id, streaming_ip, resource_tag_id, api_user, api_password, remote_access_key_id, tunnel_id, state, isActive, created_at, updated_at, is_enabled, instance_id, is_deleted, muted, muted_until, is_backup, private_ip, flapping, input_bw_limit, output_bw_limit, tunnel_username, cloud_id, monitoring_mem_limit, monitoring_cpu_limit, monitoring_hdd_limit, monitoring_gpu_limit, monitoring_gpu_dec_limit, monitoring_gpu_enc_limit, monitoring_gpu_mem_limit, zone, instance_type, clustered_tunnel_id
// '257', '43', 'Dish1', '255', NULL, '69', 'admin', 'zixiplanet123', '139', '414', 'pending', '0', '2018-03-09 19:13:24', '2021-11-12 15:12:28', '0', 'YI57DTukv', '0', '0', NULL, '0', NULL, '2020-11-20 00:06:55', NULL, NULL, 'Dish1-dish1', NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL

// # id, customer_id, name, broadcaster_cluster_id, state,  created_at, updated_at, is_enabled, is_backup

export class ZenMasterBroadcaster {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public broadcaster_cluster_id: number,
		public state: string,
		public created_at: Date = null,
		public updated_at: Date = null,
		public is_enabled: number,
		public is_backup: number,
		public maintenance: number
	) { }
}

// from the ZB DB source table
export class ZenMasterSource {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public source_type: string,

		public zen_customer: ZenMasterCustomer = null
	) { }
}

// from the ZB DB source table
export class ZenMasterChannel {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public channel_type: string,

		public zen_customer: ZenMasterCustomer = null
	) { }
}

// from the ZB DB source table
export class ZenMasterTarget {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public target_type: string,

		public zen_customer: ZenMasterCustomer = null
	) { }
}

// generic Model for searching
export class ZenMasterObject {
	constructor(
		public object_type: string,
		public id: number,
		public customer_id: number,
		public name: string,
		public sub_type: string,

		public zen_customer: ZenMasterCustomer = null
	) { }
}

export class ZenIncident {
	constructor(
		public id: number,
		public customer_id: number,
		public name: string,
		public start_time: Date,
		public end_time: Date,
		// public triggering_object_id: number,
		// public triggering_object_type: string,
		// public triggering_object_name: string,
		// public triggering_error_code: string,
		// public triggering_error_group: string,
		// public triggering_error_short_message: string,
		public triggering_error_message: string,
		public likely_cause: string,
		public state: string,
		public created_at: Date,
		public updated_at: Date,
		public is_zixi: number,
		// public created_by_user_id: number,
		// public modified_by_user_id: number,
		// public configuration_json: string,
		// public nd_active_incident_id: string,
		public num_objects: number = 0
	) { }
}

// **************************************************
// from license DB
// **************************************************

// show_meters/show_protocols = 0 : does not/cannot have meters/protocol stats
// show_meters/show_protocols = 1 : has meters/protocol stats, don't show end-users (customers)
// show_meters/show_protocols = 2 : has meters/protocol stats, show end-users (customers)
export class LicenseProduct {
	constructor(
		public id: number,
		public name: string,
		public label: string,
		public basic_label: string,
		public description: string,
		public show_meters: number,
		public show_protocols: number,
		public meter_products: string,
		public build_product_id: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public license_filename: string = '',
		public min_version: string = '',
		public fulfillment_version: string = '',
		public fulfillment_product: string = '',
		public include_refresh: number = 0,

		public num_properties: number = 0,
		public meterProductsArr: string[] = []
	) { }
}

export class LicenseProperty {
	constructor(
		public id: number,
		public name: string,
		public ptype: string,
		public label: string,
		public units: string,
		public description: string,
		public sort_order: number,
		public paired_with: number,
		public allow_unlimited: number,
		public special_property: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public fulfillment_name: string = '',
		public num_products: number = 0
	) { }
}

// can_be_changed : 0/1 when user creates key, can they set this property?

// show_user = 0 - admins see the value of this property, don't show end-users (customers)
// show_user = 1 - only show value of this field to end-users (customers) if it is on/has a value
// show_user = 2 - show value of this field to end-users (customers) whether it is on/off has value/doesn't

// min_value = for numeric fields if it should have a minimum
// max_value =
export class LicenseProductProperty {
	constructor(
		public product_id: number,
		public property_id: number,
		public default_value_text: string,
		public default_value_num: number,
		public can_be_changed: number,
		public show_user: number,
		public min_value: number,
		public max_value: number,
		public selections: string,
		public allow_unlimited: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public product: LicenseProduct = null,
		public property: LicenseProperty = null
	) { }
}

export class BillingCode {
	constructor(
		public id: number,
		public zcp_org_id: number,
		public billing_code: string,
		public auth_code: string,
		public label: string,
		public is_enabled: number,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public backups: BillingCodeBackup[] = [],
		public org_name: string = '',
	) { }
}

export class BillingCodeBackup {
	constructor(
		public billing_code: string,
		public auth_code: string,
		public added_by: number = 0,
		public added_on: Date = null
	) { }
}


// from the LP DB activations table
export class LPActivation {
	constructor(
		public id: number,
		public product: string,
		public parameters: string,
		public user_id: number,
		public key: string,
		public customer: string,
		public notes: string,
		public enabled: number,
		public count: number,
		public max: number,
		public created_at: Date,
		public updated_at: Date,
		public contacts: string,
		public expires_at: Date,
		public duration: number,
		public usage_notified_at: Date,
		public ecommerce_reference: string,
		public ecommerce_product: string,
		public opportunity_id: string,
		public type: string,

		public zcp_org_id: number = 0,
		public zcp_template_id: number = 0,
		public zcp_created_by: number = 0,
		public zcp_updated_by: number = 0,
		public zcp_updated_at: Date = null,
		public zcp_link_status: string = '',

		public first_meter_report: Date = null,
		public last_meter_report: Date = null,
		public first_protocol_report: Date = null,
		public last_protocol_report: Date = null,

		public first_meter_usage: Date = null,
		public last_meter_usage: Date = null,
		public first_protocol_usage: Date = null,
		public last_protocol_usage: Date = null,

		public info: string = '',
		public discard_licenses: number = 0,

		public commercial_type: string = '',
		public commercial_info: string = '',

		public ext_label: string = '',
		public ext_notes: string = '',

		public meters: LPMeter[] = [],
		public licenses: LPLicense[] = [],

		public org_name: string = '',
		public parsedParameters: any = null,
		public num_users: number = 0,
		public num_invalid: number = 0,

		public marketplace: MarketPlaceConfiguration = null,
		public protocolSets: ActivationProtocolSet[] = [],
		public billingCodes: ActivationBillingCode[] = [],
	) { }
}

// from the LP DB licenses table

export class LPUser {
	constructor(
		public id: number,
		public email: string,
	) { }
}

export class LPLicense {
	constructor(
		public id: number,
		public hostid: string,
		public customer: string,
		public notes: string,
		public fulfillment: string,
		public created_at: Date,
		public updated_at: Date,
		public user_id: number,
		public expires_at: Date,
		public product: string,
		public parameters: string,
		public activation_id: number,
		public type: string,
		public ip: string,
		public starts_at: Date,

		public last_meter_bx_version: string = '',
		public last_protocol_bx_version: string = '',

		public first_meter_report: Date = null,
		public last_meter_report: Date = null,
		public first_protocol_report: Date = null,
		public last_protocol_report: Date = null,

		public first_meter_usage: Date = null,
		public last_meter_usage: Date = null,
		public first_protocol_usage: Date = null,
		public last_protocol_usage: Date = null,

		public extraProperties: any = null
	) { }
}

// from the LP DB meters table
export class LPMeter {
	constructor(
		public id: number,
		public product: string,
		public enabled: number,
		public activation_id: number,
		public expires_at: Date,
		public limit: number,
		public used: number,
		public created_at: Date,
		public updated_at: Date,
		public expiration_notified_at: Date,
		public ecommerce_reference: string,
		public ecommerce_product: string,
		public resets: string,
		public starts_at: Date,
		public last_reset: Date,
		public projected: number = 0,
		public label: string = ''
	) { }
}

export class LPMeterReportByDay {
	constructor(
		public activation_id: number,
		public hostid: string,
		public product: string,
		public year: number,
		public month: number,
		public day: number,
		public used: number
	) { }
}

// public avg_rate: number,
// public max_rate: number


export class LPMeterReportByMonth {
	constructor(
		public activation_id: number,
		public hostid: string,
		public product: string,
		public year: number,
		public month: number,
		public used: number,
		public billing_code: string = ''
	) { }
}

export class LPUsageRecord {
	constructor(
		public id: number,
		public product: string,
		public hostid: string,
		public activation_key: string,
		public bx_version: string,
		public created_at: Date,
		public updated_at: Date,
		public billing_code: string,
		public billing_password: string,
		public device_type: number,
		public brand: string
	) { }
}

// public avg_rate: number,
// public max_rate: number

export class LPVersionHistory {
	constructor(
		public first_reported: Date,
		public last_reported: Date,
		public version: string
	) { }
}

export class LPLicenseFileInfo {
	constructor(
		public meterTypes: string[] = [],
		public meterNames: string[] = [],
		public meterInitials: string[] = [],
		public format: string = '',
		public zlmid: string = '',
		public key: string = '',
		public hostid: string = ''
	) { }
}

export class LPInvalidBillingCodeCache {
	constructor(
		public activation_id: number,
		public hostid: string,
		public billing_code: string,
		public min_day: Date,
		public max_day: Date,
		public total_used: number,
		public num_records: number,
		public updated_at: Date
	) { }
}

export class TopUsageEntry {
	constructor(
		public activation_id: number,
		public hostid: string,
		public totalUsed: number,
		public startDate: Date = null,
		public endDate: Date = null,
		public activation: LPActivation = null,
		public zcp_org_id: number = 0
	) { }
}

export class DailyUsageEntry {
	constructor(
		public theDate: Date = null,
		public totalUsed: number,
		public startDate: Date = null,
		public endDate: Date = null,
	) { }
}

export class KeyTemplate {
	constructor(
		public id: number,
		public product: string,
		public name: string,
		public description: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public last_used: Date = null,
		public settings: KeyTemplateSettings = null,
		public user_ids: number[] = [],
		public user_group_ids: number[] = [],
		public num_keys: number = 0

	) { }
}

export class KeyTemplateSettings {
	constructor(
		public requiresOrganization: boolean = true,
		public editOnly: boolean = false,
		public quickKey: boolean = false,
		public keyTypes: string[] = [],
		public defaultKeyType: string = '',
		public productProperties: LicenseProductProperty[] = [],
		public limitOrgIDs: number[] = [],
		public limitOrgGroupIDs: number[] = [],
		public excludeOrgIDs: number[] = [],
		public excludeOrgGroupIDs: number[] = [],
		public orgTypes: string[] = [],
		public requiresSalesforceOpportunity: boolean = false,
		public salesforceOpportunityMustMatch: boolean = false,
		public defaultActivations: number = 1,
		public minActivations: number = 1,
		public maxActivations: number = AppConstants.maxActivationMax,
		public maxKeys: number = 1,
		public expiryModes: string[] = [],
		public defaultExpiryMode: string = '',
		public defaultExpirationCount: number = 0,
		public defaultExpirationUnit: string = 'days',
		public maxExpirationCount: number = 0,
		public maxExpirationUnit: string = 'days',
		public limitMeterProducts: string[] = [],
		public requiredMeterProducts: string[] = [],
		public defaultMeterLimit: number = 0,
		public limitMeterReset: string[] = [],
		public warningDaysOfWeek: string[] = [],

		public nonCheckAllFeatures: number[] = [],
		public nonAllUnlimitedLimits: number[] = [],
		public defaultCommercialType: string = '',
		public defaultCommercialInfo: string = '',
		public requiresCommercialType: boolean = false,
		public requiresMeterLabels: boolean = false,
		public defaultMeterLabel: string = '',
		public requiresLinkedUsers: boolean = false,
		public defaultShareWithSelf: boolean = false,
	) {
	}
}

export class ProtocolSet {
	constructor(
		public id: number,
		public name: string,
		public description: string,
		public share_mode: string,
		public protocols: string,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,
		public protocolsArr: string[] = [],
		public num_keys: number = 0
	) {
	}
}

export class ActivationProtocolSet {
	constructor(
		public activation_id: number,
		public set_id: number,
		public data_type: string,
		public projected: number = 0,
		public current_usage: number = 0,
		public last_usage_update: Date = null,
		public added_by: number = 0,
		public added_on: Date = null
	) {
	}
}

export class ActivationBillingCode {
	constructor(
		public activation_id: number,
		public billing_code: string,
		public added_by: number = 0,
		public added_on: Date = null
	) {
	}
}

export class BroadcasterPenTest {
	constructor(
		public activation_id: number,
		public hostid: string,
		public ip: string,
		public username: string,
		public test_code: number,
		public test_msg: string,
		public added_on: Date,
		public last_checked_on: Date,

		public newResult: boolean = false
	) { }
}

export class MarketPlaceConfiguration {
	constructor(
		public marketplace: string = '',
		public accountIdentifier: string,
		public productIdentifiers: string[] = [],
		public firstReport: Date = null,
		public latestReport: Date = null,
		public protocolSetId: number = 0
	) { }
}


export class MarketplaceUsageReport {
	constructor(
		public id: number = 0,
		public object_type: string = '',
		public object_id: number = 0,
		public object_name: string = '',
		public marketplace: string = '',
		public customer_id: string = '',
		public product_id: string = '',
		public dimension_api_name: string = '',
		public report_start: Date = null,
		public report_end: Date = null,
		public reported: number = 0,
		public receipt: string = '',
		public added_by: number = 0,
		public added_on: Date = null
	) { }
}

export class Quiz {
	constructor(
		public id: number,
		public name: string,
		public description: string,
		public status: string,
		public config: QuizConfig,
		public added_by: number = 0,
		public added_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null,

		public responses: QuizResponse[] = []
	) { }
}

export class QuizConfig {
	constructor(
		public quizType: string = 'quiz',
		public adminIds: number[] = [],
		public allStaff: boolean = false,
		public userIds: number[] = [],
		public groupIds: number[] = [],
		public questions: QuizQuestion[] = []
	) { }
}

export class ZixiQuestion {
	constructor(
		public id: number,
		public user_id: number,
		public status: string,
		public question: string,
		public response: any = null,
		public helpful: number = 0,
		public hidden: number = 0,
		public added_by: number = 0,
		public added_on: Date = null,
		public answered_on: Date = null,
		public edited_by: number = 0,
		public edited_on: Date = null
	) { }
}

export class QuizQuestion {
	constructor(
		public id: string = '',
		public answerType: string = 'text',
		public text: string = '',
		public moreInfo: string = '',
		public explanation: string = '',
		public choices: string[] = [],
		public answers: string[] = []
	) { }
}

export class QuizResponse {
	constructor(
		public quiz_id: number,
		public user_id: number,
		public status: string,
		public answers: any,
		public added_on: Date = null,
		public edited_on: Date = null,
	) { }
}

export class TopUsageCacheEntry {
	constructor(
		public time_period: string,
		public start_date: Date = null,
		public end_date: Date = null,
		public key_type: string,
		public object_type: string,
		public object_id: number,
		public object_name: string,
		public activation_id: number,
		public used: number,
		public added_on: Date = null
	) { }
}

export class ZixiVersion {
	constructor(
		public major: number = 0,
		public minor: number = 0,
		public build: number = 0,
		public startedWithOne: boolean = false,
		public suffix: string = ''
	) { }
}

export class TableDisplayOptions {
	constructor(
		public sortBy: string = '',
		public sortDir: string = 'asc',
		public pageNum: number = 1,
		public perPage: number = 20,
		public textFilter: string = '',
	) { }
}; // TableDisplayOptions

export class TablePagingOptions {
	constructor(
		public numPages: number = 0,
		public pages: number[] = [],
		public pagingInfo: string = ''
	) { }
}; // TablePagingOptions

