import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { LicensingService } from '../licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { AuthService } from 'client/app/services/auth.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { AppSettingsService } from '../../app-settings/app-settings.service';
import { ReportsService } from '../../reports/reports.service';
import { MyKeysService } from '../../my-keys/my-keys.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MarketplaceService } from '../../marketplace/marketplace.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';
import { LicensesTableComponent } from 'client/app/components/shared/licenses-table/licenses-table.component';
import { MarketplaceLogsTableComponent } from 'client/app/components/shared/marketplace-logs-table/marketplace-logs-table.component';
import { KeyFeaturesComponent } from 'client/app/components/shared/key-features/key-features.component';
import { KeyMetersComponent } from 'client/app/components/shared/key-meters/key-meters.component';

@Component({
	selector: 'app-activation-multi',
	templateUrl: './activation-multi.component.html',
	styleUrls: ['./activation-multi.component.scss']
})
export class ActivationMultiComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	popOverTools = PopOverTools;
	sharedLicenseTools = SharedLicenseTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;
	@ViewChild('allLicensesTable') allLicensesTable: LicensesTableComponent = null;
	@ViewChild('activeLicensesTable') activeLicensesTable: LicensesTableComponent = null;
	@ViewChild('searchLicensesTable') searchLicensesTable: LicensesTableComponent = null;
	@ViewChild(MarketplaceLogsTableComponent) marketPlaceLogsTable: MarketplaceLogsTableComponent = null;
	@ViewChild('keyFeatures') keyFeatures: KeyFeaturesComponent = null;
	@ViewChild('hostFeatures') hostFeatures: KeyFeaturesComponent = null;
	@ViewChild(KeyMetersComponent) keyMeters: KeyMetersComponent = null;

	staffMode: boolean = true; // determimes if certain things are shown in UI/code that's identical between staff and non-staff pages

	pageMode: string = 'activation'; // or host or aggregate or ip

	loading = true;
	tabID: string = '';

	firstLoad: boolean = true;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	userKey: Models.UserLicenseKey; // for shared code for non-staff mode

	// *********************************************************
	// for activation page mode
	// *********************************************************
	id: number; // activation ID
	activation: Models.LPActivation;
	organization: Models.Organization;
	organizationForUsers: Models.Organization;

	allTemplates: Models.KeyTemplate[] = []; // all of them
	template: Models.KeyTemplate; // template for the key
	otherTemplates: Models.KeyTemplate[] = []; // other templates the key could be changed to

	expiryMode = '';

	canManageKeyViaTemplate: boolean = false; // full blown access/template based able to edit key
	canManageKey: boolean = false; // either write access via template or specific write access

	// canSetOrganization: boolean = false; // old way of linking to Orgs
	canSetTemplate: boolean = false; // limited
	canAddJournals: boolean = false;
	canSetWriteAccess: boolean = false;
	canManageCommercial: boolean = false;
	canRunPenTest: boolean = false;
	canConfigureMarketplace = false;

	showOfflineButton: boolean = false; // special for offline keys
	showDownloadRecentButton: boolean = false;

	showMeters: boolean = false;

	canShowMeterProtocolEquiv: boolean = false;
	showMeterProtocolEquiv: boolean = false;

	userKeys: Models.UserLicenseKey[] = [];
	otherOrgUsers: Models.User[] = [];

	accountOwner: Models.User = null;
	accountSE: Models.User = null;
	adminLogs: Models.AdminLog[] = [];
	journals: Models.JournalEntry[] = [];

	lpUser = '';
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;
	updatedDateToShow: Date = null;

	writeAccessUsers: Models.User[] = [];

	searching: boolean = false;
	showSearchResults: boolean = false;
	searchForm: UntypedFormGroup;
	searchResults: Models.LPLicense[] = [];
	hostidFilter = '';

	activeLicenses: Models.LPLicense[] = [];

	keyWarnings = [];
	meterWarnings = [];
	nonEnforcedWarnings = [];

	recentUsedHosts: string[] = [];
	recentHostsPopup = 'Number of Activations (host IDs) reporting non-zero traffic (either via meter or protocol) over the last '
		+ AppConstants.recentUsedHostsDays + ' days';
	recentHostIconPopup = 'Reporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days';
	offlineHostIconPopup = 'Might be offline or unable to communicate with license server.  Hasn\'t reported in last day, but did report in within the last ' + AppConstants.hostOfflineMaxDays + ' days.';
	upgradeIconPopup = 'Reporting in over the last ' + AppConstants.recentUsedHostsDays + ' days via meter(s), but not protocols.  Protocol reporting is blocked or may need upgrade to v13 or later.';

	showFirstDates: boolean = false;

	showMaxHostsExtra: boolean = false;
	fullLicenseCount: number = 0;

	salesforceAccountInfoBlock = '';
	salesforceOpportunityInfoBlock = '';
	sfFetching: boolean = false;
	salesForceUrl = '';

	orgSearching: boolean = false;

	keyProduct: Models.LicenseProduct;
	keyProductProperties: Models.LicenseProductProperty[];

	showOrgLinkButton: boolean = true;
	orgLinkMatches = [];
	showNoMatches: boolean = false;

	keyExpiryCalc: Date;
	licExpiryCalc: Date;

	hostIdCounts: any = {};
	licenseCounters: any = {};
	licenseMeterInitials: any = {};
	licenseMeterNames: any = {};
	licenseFormats: any = {};

	meterLabelsByType: any = {};
	anyMeterLabels: boolean = false;

	// TBD comp????
	// meter summary
	// activeMeterTypes: string[] = [];
	// // activeMeterCount: number = 0;
	// meterSummaryInfo: any = {};
	// hasMonthlyMeters: boolean = false;
	// hasNeverMeters: boolean = false;
	// // TBD - comp???
	// meterHighlites: any = {};

	// showTimeIntoMonth: boolean = false;
	// percentTimeIntoMonth: number = 0;

	disableForm: UntypedFormGroup;
	showDisableMsg: boolean = false;

	bcSummaryByHostId: any[] = [];

	checkingCompliance: boolean = false;
	isCompliant: boolean = true;
	// templateComplianceIssues: string[] = null;

	commercialTypeInfo: any = null;
	commercialGuesses: any = null;

	usageSummaryForm: UntypedFormGroup;
	usageSummaryDataTypeSelections: any[] = [];

	keySnoozes: Models.UserLicenseKeySnooze[] = [];
	userSnoozes = {};

	marketplaceLabel: string = '';
	marketplaceAccountIdentifier: string = '';
	marketplaceProducts: string[] = [];
	marketPlaceAccountIdLabel: string = 'ID';
	lastMarketplaceUsageReported: Date = null;
	marketplaceLogs: Models.MarketplaceUsageReport[] = [];
	productCache: any = {};
	selectedMarketPlaceProtocolSet: Models.ProtocolSet = null;

	canReportToMarketplace: boolean = false;
	manualMarketplaceForm: UntypedFormGroup;

	prevId: number = -1;
	nextId: number = -1;
	prevNote: string = '';
	nextNote: string = '';

	// *********************************************************
	// for hostid/ip page mode
	// *********************************************************

	hostid: string;
	ip: string;

	licenses: Models.LPLicense[] = [];

	keys: Models.LPActivation[] = [];

	hostKeys: any = {};

	activationIDsForObject: number[] = [];
	activationIDsWithUsage: number[] = [];

	filterID: number = 0;
	filterKey: string = '';
	otherKeyCount: number = 0;

	latestID: number = 0;
	showLinkOfflineLicense = false;
	linkOfflineForm: UntypedFormGroup;

	meterTypesPerActivation: any = {};
	expiryModePerActivation: any = {};
	expiryExtraActivation: any = {};

	// version history...
	versionHistory: Models.LPVersionHistory[] = [];

	keyProducts: Models.LicenseProduct[] = [];

	selectedLicenseLoading = false;
	selectedLicenseID = 0;
	showSelectedKeyLicFeatures = false;

	selectedNotesLicenseID = 0;
	showSelectedKeyLicNotesCol = false;
	selectedKeyLicNotes = '';

	showFileFootnote = false;

	labels: Models.UserHostId[] = [];

	allOrgs: Models.Organization[] = [];
	staffUsers: Models.User[] = [];

	// *********************************************************
	// for aggregate page mode
	// *********************************************************

	aggregateForm: UntypedFormGroup;
	keysAndHostIds: string = '';
	resolving: boolean = false;
	keysAndHostIdsCombos: any[] = [];
	activationIdAndHostIdsCombos: any[] = [];
	resolveProblems: string[] = [];
	resolveData: any = {};

	aggregateSelectionMode: boolean = true;
	aggregateSelectionForm: UntypedFormGroup;

	// *********************************************************
	// common usage stuff
	// *********************************************************

	rateNumFmt = '1.1-1';
	percFmt = '1.1-1';

	showNoUseDays: boolean = true;
	showRawData: boolean = false;

	timeModes: any[] = [
		{ value: 'month', label: 'Month' },
		{ value: 'quarter', label: 'Quarter' },
		{ value: 'year', label: 'Year' }
	];
	timeMode: string = 'month';
	timeModeLabel: string = 'Month';

	showSetsButton: boolean = true;

	// all defined sets
	availableSets: Models.ProtocolSet[] = [];

	// combined linked + session sets
	selectedProtocolSets: Models.ProtocolSet[] = [];
	selectedChronoSets: Models.ProtocolSet[] = [];

	// phantom sets for doing meter equiv.
	phantomSets: Models.ProtocolSet[] = [];
	phantomSetIds: any = {};

	// just session sets
	supportsSessionSet: boolean = true;

	sessionSetIdentifier: string = '';
	sessionProtocolSets: Models.ProtocolSet[] = [];
	sessionChronoSets: Models.ProtocolSet[] = [];

	// just linked sets
	supportsLinkedSet: boolean = false;
	linkedProtocolSets: Models.ProtocolSet[] = [];
	linkedChronoSets: Models.ProtocolSet[] = [];

	protocolSetsPosition: string = 'top';
	canLinkProtocolSets: boolean = false;

	penTestRunning: boolean = false;

	allPenTests: Models.BroadcasterPenTest[] = [];
	failedPenTests: Models.BroadcasterPenTest[] = [];
	failedPenTestIPs: string[] = [];

	// penTestsById: any = {};
	// penTestCodeById: any = {};

	protocolShowAllMonths: boolean = false;

	// *********************************************************
	// meter usage for key
	showMeterTraffic = false;
	byDayMeterBuffer = 200;
	byMonthMeterBuffer = 200;

	monthlyMeterUsage: Models.LPMeterReportByMonth[] = [];
	chunkedMeterUsage: Models.LPMeterReportByMonth[] = [];
	meterProductsToShow: string[] = [];

	byDayMeterLoading = false;
	byDayMeterProduct = '';
	byDayMeterMonth = 0;
	byDayMeterYear = 0;
	byDayMeterUsage: Models.LPMeterReportByDay[] = [];
	byDayMeterTotal = 0;
	byDayMeterAvgAbsChg = 0;

	showMeterTypeExtra = false;

	showOverageCounts: any = {};
	monthlyProjectedAmounts: any = {};

	// *********************************************************
	// protocol (data) usage for key
	rotateAxis: boolean = true;
	protocolReady: boolean = false;

	showProtocolTraffic = false;
	byDayProtocolBuffer = 210;
	byMonthProtocolBuffer = 210;

	monthlyProtocolUsage: Models.LPMeterReportByMonth[] = [];
	chunkedProtocolUsage: Models.LPMeterReportByMonth[] = [];
	protocolProducts: string[] = [];
	protocolProductsToShow: string[] = [];
	protocolProductsVisible: string[] = [];
	onlyShowTotals: boolean = false;
	onlyShowTotalsStorageKey: string = 'activationView.onlyShowTotals';

	protocolTimeChunks: any[] = [];
	protocolTimeChunksToShow: any[] = [];

	byDayProtocolLoading: boolean = false;
	byDayProtocolProduct = '';
	byDayProtocolMonth = 0;
	byDayProtocolYear = 0;
	byDayProtocolUsage: Models.LPMeterReportByDay[] = [];
	byDayProtocolTotal = 0;
	byDayProtocolAvgAbsChg = 0;

	hasIncomingProtocolData: boolean = false;
	hasTranscodeProtocolData: boolean = false;
	hasOutgoingProtocolData: boolean = false;

	// *********************************************************
	// protocol (chrono) usage for key
	showChronoTraffic: boolean = false;
	byDayChronoBuffer = 210;
	byMonthChronoBuffer = 210;

	monthlyChronoUsage: Models.LPMeterReportByMonth[] = [];
	chunkedChronoUsage: Models.LPMeterReportByMonth[] = [];
	chronoProducts: string[] = [];
	chronoProductsToShow: string[] = [];

	chronoTimeChunks: any[] = [];
	chronoTimeChunksToShow: any[] = [];
	chronoMonthTotals = {};

	byDayChronoLoading: boolean = false;
	byDayChronoProduct = '';
	byDayChronoMonth = 0;
	byDayChronoYear = 0;
	byDayChronoUsage: Models.LPMeterReportByDay[] = [];
	byDayChronoTotal = 0;
	byDayChronoAvgAbsChg = 0;

	hasIncomingChronoData: boolean = false;
	hasTranscodeChronoData: boolean = false;
	hasOutgoingChronoData: boolean = false;

	// *********************************************************
	// billing code related
	billingCodesByCode: any = {};

	billingCodeDisplay: string = 'code'; // or label

	// 0 - means show all usage together, -1 - means show no-code usage, # - show usage for that b-code
	usedProtocolBillingCodes: Models.BillingCode[] = [];
	activeProtocolBillingCode: number = -1;
	activeProtocolBillingCodeLabel = 'All Usage Data';
	activeProtocolBillingCodeShortLabel = '';
	protocolBillingCodeToSearch: string = '';

	usedChronoBillingCodes: Models.BillingCode[] = [];
	activeChronoBillingCode: number = -1;
	activeChronoBillingCodeLabel = 'All Usage Data';
	activeChronoBillingCodeShortLabel = '';
	chronoBillingCodeToSearch: string = '';

	invalidBillingCodeEntries: Models.LPInvalidBillingCodeCache[] = [];

	// *********************************************************
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private licensingAdminService: LicensingAdminService,
		private licensingService: LicensingService,
		private myKeysService: MyKeysService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private adminLogsService: AdminLogsService,
		private reportsService: ReportsService,
		private marketplaceService: MarketplaceService,
		private appSettingsService: AppSettingsService) {

		this.route.queryParams.subscribe(params => {
			this.tabID = null;
			if (params['tabID']) this.tabID = params['tabID'];
		});

		this.route.paramMap.subscribe(params => {
			this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

			/*
			/XXX - page mode is 'activation' & id is ###
			/activation|hostid|aggregate/XXX - page mode is one of 3 and id is +XXX or hostid is XXX or (eventually) collection id is +XXX
			/aggregate - page mode is aggregate and manual entry is available...
			*/
			const pagemodeArg = params.get('pagemode');
			const objidArg = params.get('objid');

			// console.log('in route pm=' + this.pageMode);
			// console.log('in route pagemodeArg=' + pagemodeArg);


			if (['activation', 'hostid', 'host', 'activation', 'ip', 'aggregate'].includes(pagemodeArg)) {
				// // this.pageMode = pagemodeArg;
				// if (this.pageMode === 'host') this.pageMode = 'hostid';

				if (pagemodeArg === 'activation') {
					this.pageMode = 'activation';
					this.id = +objidArg;
				} else if (['hostid', 'host'].includes(pagemodeArg)) {
					this.pageMode = 'hostid';
					this.hostid = objidArg;
					if (params.get('filterID') && params.get('filterID') !== '') {
						this.filterID = +params.get('filterID');
						if (isNaN(this.filterID)) this.filterID = 0;
					} // if
				} else if (pagemodeArg === 'ip') {
					this.pageMode = 'ip';
					this.ip = objidArg;
				} else if (pagemodeArg === 'aggregate') {
					this.pageMode = 'aggregate';
				} // if

			} else {
				this.pageMode = 'activation';
				this.id = +pagemodeArg;
			} // if

			if (this.id && isNaN(this.id)) this.id = null;

			// console.log('pageMode=' + this.pageMode);
			// console.log('id=' + this.id);
			// console.log('hostid=' + this.hostid);

			this.loadData();
		});
	}

	// *********************************************************
	ngOnInit(): void {
		// console.log('in ngOnInit pm=' + this.pageMode);

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-activationView.settings'))
				settings = JSON.parse(localStorage.getItem('cp-activationView.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('rotateAxis')) this.rotateAxis = settings.rotateAxis;
		if (settingKeys.includes('showRawData')) this.showRawData = settings.showRawData;
		if (settingKeys.includes('protocolSetsPosition')) this.protocolSetsPosition = settings.protocolSetsPosition;
		if (settingKeys.includes('billingCodeDisplay')) this.billingCodeDisplay = settings.billingCodeDisplay;
		if (settingKeys.includes('onlyShowTotals')) this.onlyShowTotals = settings.onlyShowTotals;
		if (settingKeys.includes('showNoUseDays')) this.showNoUseDays = settings.showNoUseDays;
		if (settingKeys.includes('showMeterProtocolEquiv')) this.showMeterProtocolEquiv = settings.showMeterProtocolEquiv;

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.canAddJournals = ValidationTools.checkAccess(this.authUser, 'add-journals');
			this.canLinkProtocolSets = ValidationTools.checkAccess(this.authUser, 'link-protocol-sets-to-keys');
			this.canRunPenTest = ValidationTools.checkAccess(this.authUser, 'manage-keys');
			this.canShowMeterProtocolEquiv = ValidationTools.checkAccess(this.authUser, 'compare-meters-to-protocol');
		});
	}

	// *********************************************************
	saveSettings() {
		let settings: any = {
			rotateAxis: this.rotateAxis,
			showRawData: this.showRawData,
			protocolSetsPosition: this.protocolSetsPosition,
			billingCodeDisplay: this.billingCodeDisplay,
			onlyShowTotals: this.onlyShowTotals,
			showNoUseDays: this.showNoUseDays,
			showMeterProtocolEquiv: this.showMeterProtocolEquiv
		};
		localStorage.setItem('cp-activationView.settings', JSON.stringify(settings));
	}

	// *********************************************************
	ngOnDestroy(): void {
		// console.log('in ngOnDestroy pm=' + this.pageMode);

		this.uiAlertsService.clearMsgByCode('compliance');

		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// *********************************************************
	async loadData() {
		try {
			this.loading = true;

			if (this.allLicensesTable)
				this.allLicensesTable.updateContent([], 'cp-staff-key-all-licenses', { staffMode: true, showMeters: this.showMeters, protocolReady: this.protocolReady });

			if (this.activeLicensesTable)
				this.activeLicensesTable.updateContent([], 'cp-staff-key-active-licenses', { staffMode: true, showMeters: this.showMeters, protocolReady: this.protocolReady });

			if (this.searchLicensesTable)
				this.searchLicensesTable.updateContent([], 'cp-staff-key-search-licenses', { staffMode: true, showMeters: this.showMeters, protocolReady: this.protocolReady });

			this.resetMeterUsage();
			this.resetProtocolUsage();
			this.resetTimeUsage();

			this.availableSets = await this.licensingAdminService.getProtocolSets();

			this.phantomSets = [];
			for (const pSet of this.availableSets) {
				if (pSet.name === AppConstants.protectedProtocolSetName) {
					this.phantomSets.push(pSet);
					this.phantomSetIds['protected_mb'] = pSet.id;
				} else if (pSet.name === AppConstants.allOutputsProtocolSetName) {
					this.phantomSets.push(pSet);
					this.phantomSetIds['output_mb'] = pSet.id;
					this.phantomSetIds['output_mb_meter'] = pSet.id;
				} // if
			} // for

			if (this.phantomSets.length !== 2) this.canShowMeterProtocolEquiv = false;

			this.linkedProtocolSets = [];
			this.linkedChronoSets = [];
			this.sessionProtocolSets = [];
			this.sessionChronoSets = [];

			if (this.pageMode === 'activation')
				this.sessionSetIdentifier = this.id.toString();
			else if (this.pageMode === 'hostid')
				this.sessionSetIdentifier = this.hostid;
			else if (this.pageMode === 'aggregate')
				this.sessionSetIdentifier = 'aggregate';

			if (this.pageMode === 'activation') {
				const linkedSets: Models.ActivationProtocolSet[] = await this.licensingService.getActivationProtocolSets(this.id);
				for (const linkedSet of linkedSets) {
					const idx = MiscTools.findIndex(this.availableSets, linkedSet.set_id);
					if (idx !== -1) {
						if (!isNaN(linkedSet.projected)) this.availableSets[idx]['projected'] = linkedSet.projected;
						if (!isNaN(linkedSet.current_usage)) this.availableSets[idx]['current_usage'] = linkedSet.current_usage;

						if (linkedSet.data_type === 'protocol-data')
							this.linkedProtocolSets.push(this.availableSets[idx]);
						else if (linkedSet.data_type === 'protocol-time')
							this.linkedChronoSets.push(this.availableSets[idx]);
					} // if
				} // for
				this.supportsLinkedSet = true;
			} // if

			if (localStorage.getItem('CLEAN.activationView.sessionProtocolSets.' + this.sessionSetIdentifier) != null) {
				const tmpIds: number[] = JSON.parse(localStorage.getItem('CLEAN.activationView.sessionProtocolSets.' + this.sessionSetIdentifier));
				for (const setId of tmpIds) {
					const idx = MiscTools.findIndex(this.availableSets, setId);
					if (idx !== -1) this.sessionProtocolSets.push(this.availableSets[idx]);
				} // for
			} // if

			if (localStorage.getItem('CLEAN.activationView.sessionChronoSets.' + this.sessionSetIdentifier) != null) {
				const tmpIds: number[] = JSON.parse(localStorage.getItem('CLEAN.activationView.sessionChronoSets.' + this.sessionSetIdentifier));
				for (const setId of tmpIds) {
					const idx = MiscTools.findIndex(this.availableSets, setId);
					if (idx !== -1) this.sessionChronoSets.push(this.availableSets[idx]);
				} // for
			} // if

			this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);
			this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);

			this.allOrgs = this.organizationsService.getAll();
			this.staffUsers = this.usersService.getForRole('staff');
			this.keyProducts = await this.licensingAdminService.getProducts();
			this.canConfigureMarketplace = false;
			this.canReportToMarketplace = false;

			if (this.pageMode === 'activation') {
				// console.log('in load AA pm=' + this.pageMode);

				let lastSearchIds: number[] = [];
				if (localStorage.getItem('CLEAN.licensingSearch.latestIDs'))
					lastSearchIds = JSON.parse(localStorage.getItem('CLEAN.licensingSearch.latestIDs'));

				this.prevId = -1;
				this.nextId = -1;
				if (lastSearchIds && lastSearchIds.length > 1) {
					const idx = lastSearchIds.indexOf(this.id);
					if (idx !== -1) {
						if (idx > 0)
							this.prevId = lastSearchIds[idx - 1];
						else
							this.prevId = lastSearchIds[lastSearchIds.length - 1];

						if (idx === lastSearchIds.length - 1)
							this.nextId = lastSearchIds[0];
						else
							this.nextId = lastSearchIds[idx + 1];

						this.prevNote = 'Based on last key search (sorted newest to oldest), go to previous key.';
						this.nextNote = 'Based on last key search (sorted newest to oldest), go to next key.';

						this.prevNote += ' Current key is # ' + (idx + 1) + ' of ' + lastSearchIds.length;
						this.nextNote += ' Current key is # ' + (idx + 1) + ' of ' + lastSearchIds.length;
					} // if
				} // if

				this.salesForceUrl = await this.appSettingsService.getSalesForceUrl();

				this.activation = await this.licensingService.getOne(this.id);
				if (!this.activation || this.activation == null || this.activation.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
					return;
				} // if

				// // const linkedSets: Models.ActivationProtocolSet[] = await this.licensingService.getActivationProtocolSets(this.id);
				// for (const linkedSet of this.activation.protocolSets) {
				// 	const idx = MiscTools.findIndex(this.availableSets, linkedSet.set_id);
				// 	if (idx !== -1) {
				// 		if (!isNaN(linkedSet.projected))
				// 			this.availableSets[idx]['projected'] = linkedSet.projected;
				// 		if (linkedSet.data_type === 'protocol-data')
				// 			this.linkedProtocolSets.push(this.availableSets[idx]);
				// 		else if (linkedSet.data_type === 'protocol-time')
				// 			this.linkedChronoSets.push(this.availableSets[idx]);
				// 	} // if
				// } // for
				// this.supportsLinkedSet = true;
				if (this.linkedProtocolSets.length > 0 || this.linkedChronoSets.length > 0) this.showMeters = true;

				if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
					document.getElementById(this.tabID).click();
					this.tabID = '';
				} // if

				this.updatedDateToShow = this.activation.updated_at;
				if (this.activation.zcp_updated_at != null) this.updatedDateToShow = this.activation.zcp_updated_at;

				this.commercialTypeInfo = null;
				if (this.activation.commercial_type && this.activation.commercial_type !== '') {
					const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', this.activation.commercial_type);
					if (cIdx !== -1) {
						this.commercialTypeInfo = MiscTools.deepClone(AppConstants.keyCommercialTypes[cIdx]);
						this.commercialTypeInfo.label = (cIdx + 1) + '. ' + this.commercialTypeInfo.label;
					} // if
				} // if

				this.commercialGuesses = LicenseValidationTools.workOutCommercialType(this.activation);

				this.activation.meters.sort(SharedLicenseTools.meterSort);
				this.keyExpiryCalc = SharedLicenseTools.getKeyExpiration(this.activation);
				this.licExpiryCalc = SharedLicenseTools.getLicenseExpiration(this.activation);

				this.invalidBillingCodeEntries = await this.licensingService.getInvalidBillingCodeCache(this.activation.id, '', '');

				this.invalidBillingCodeEntries.sort((a, b) => (a.billing_code < b.billing_code) ? 1 : -1);
				this.invalidBillingCodeEntries.sort((a, b) => (a.hostid > b.hostid) ? 1 : -1);

				this.allTemplates = await this.licensingAdminService.getTemplates();
				this.otherTemplates = [];
				this.template = null;
				if (this.activation.zcp_template_id && this.activation.zcp_template_id !== 0) {
					const idx = MiscTools.findIndex(this.allTemplates, this.activation.zcp_template_id);
					if (idx !== -1) this.template = this.allTemplates[idx];
				} // if
				for (const template of this.allTemplates)
					if (template.product === this.activation.product
						&& (!this.activation.zcp_template_id || this.activation.zcp_template_id === 0 || template.id !== this.activation.zcp_template_id))
						this.otherTemplates.push(template);

				const userTemplateIDs = await this.licensingAdminService.getUserTemplateIDs();
				const writeAccessArr: Models.KeyWriteAccess[] = await this.licensingService.getKeyWriteAccess(this.id);

				this.writeAccessUsers = [];
				for (const writeAccess of writeAccessArr) {
					const u = this.usersService.getOne(writeAccess.user_id);
					if (u) this.writeAccessUsers.push(u);
				} // for

				// let's figure out access.....
				this.canManageKeyViaTemplate = ValidationTools.checkAccess(this.authUser, 'manage-keys')
					&& userTemplateIDs && this.template && userTemplateIDs.includes(this.template.id);

				if (this.canManageKeyViaTemplate) {
					this.canManageKey = true;
					this.canSetWriteAccess = ValidationTools.checkAccess(this.authUser, 'set-key-write-access');
				} else {
					const idx = MiscTools.findIndexGeneric(writeAccessArr, 'user_id', this.authUser.id);
					this.canManageKey = ValidationTools.checkAccess(this.authUser, 'manage-keys') && idx !== -1;
				} // if

				this.canManageCommercial = ValidationTools.checkAccess(this.authUser, 'manage-commercial');
				this.canSetTemplate = !this.template && ValidationTools.checkAccess(this.authUser, 'change-key-template')
					&& !this.activation.type.startsWith(AppConstants.offlinePrefix)
					&& !SharedLicenseTools.isSpecialKey(this.activation);

				if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
					document.getElementById(this.tabID).click();
					this.tabID = '';
				} // if

				const now = new Date();

				this.showOfflineButton = this.canManageKey && this.activation.type.startsWith(AppConstants.offlinePrefix)
					&& this.activation.count < this.activation.max
					&& (this.keyExpiryCalc == null || this.keyExpiryCalc.getTime() > now.getTime());

				this.expiryMode = SharedLicenseTools.getExpiryMode(this.activation);

				if (this.expiryMode === 'meter') {
					this.showOverageCounts = {};
					for (const product of AppConstants.meterProducts) {
						this.showOverageCounts[product] = 0;
					} // for

					for (const meter of this.activation.meters) {
						if (meter.enabled === 1 && meter.resets === 'monthly' && +meter.projected > 0)
							this.showOverageCounts[meter.product]++;

						let starts = new Date(meter.created_at);
						if (meter.starts_at) starts = new Date(meter.starts_at);
						const exp = new Date(meter.expires_at);
						if (meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime() && meter.label && meter.label !== '') {
							this.anyMeterLabels = true;
							if (!this.meterLabelsByType[meter.product]) this.meterLabelsByType[meter.product] = [];
							if (!this.meterLabelsByType[meter.product].includes(meter.label)) this.meterLabelsByType[meter.product].push(meter.label);
						} // if
					} // for
				} // if

				if (!this.activation.parsedParameters)
					this.activation.parsedParameters = SharedLicenseTools.parseRubyHash(this.activation.parameters);

				this.keyProduct = await this.licensingAdminService.getProductByName(this.activation.product);
				if (this.keyProduct) {
					this.keyProductProperties = await this.licensingAdminService.getProductProperties(false, this.keyProduct.id);

					if (this.keyProduct.show_meters && this.keyProduct.show_meters > 0) {
						this.showMeters = true;
					} // if

					this.protocolReady = this.keyProduct.show_protocols > 0;

					this.canConfigureMarketplace = ValidationTools.checkAccess(this.authUser, 'configure-key-for-marketplace')
						&& this.keyProduct.name.startsWith('broadcaster')
						&& this.keyProduct.fulfillment_product != null
						&& this.keyProduct.fulfillment_product.startsWith('broadcaster');
				} // if

				if (this.keyMeters)
					this.keyMeters.updateContent(this.activation, this.linkedProtocolSets, this.linkedChronoSets, { staffMode: true });

				await this.workoutHostIDProperties();

				await MiscTools.delay(50);
				if (this.keyFeatures)
					this.keyFeatures.updateContent(this.activation.parsedParameters, this.activation.product, { staffMode: true, hostMode: false });

				if (this.allLicensesTable)
					this.allLicensesTable.updateContent(this.activation.licenses, 'cp-staff-key-all-licenses', { staffMode: true, showMeters: this.showMeters, protocolReady: this.protocolReady });

				if (this.activeLicensesTable)
					this.activeLicensesTable.updateContent(this.activeLicenses, 'cp-staff-key-active-licenses', { staffMode: true, showMeters: this.showMeters, protocolReady: this.protocolReady });

				this.organization = null;
				this.accountOwner = null;
				this.accountSE = null;
				let canRegisterUserKeys = false;
				if (this.activation.zcp_org_id && this.activation.zcp_org_id !== 0) {
					this.organization = this.organizationsService.getOne(this.activation.zcp_org_id);
					if (this.organization && this.organization.salesforce_account_owner_id && this.organization.salesforce_account_owner_id !== 0)
						this.accountOwner = this.usersService.getOne(this.organization.salesforce_account_owner_id);

					if (this.organization && this.organization.salesforce_se_id && this.organization.salesforce_se_id !== 0)
						this.accountSE = this.usersService.getOne(this.organization.salesforce_se_id);

					if (this.organization) {
						if (ValidationTools.hasFlag(this.organization, 'restricted'))
							canRegisterUserKeys = ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole);
						else
							canRegisterUserKeys = ValidationTools.checkAccess(this.authUser, 'manage-basic-users-' + this.organization.otype);
					} // if
				} // if

				this.addedByUser = null;
				if (this.activation.zcp_created_by && this.activation.zcp_created_by !== 0)
					this.addedByUser = this.usersService.getOne(this.activation.zcp_created_by);

				this.editedByUser = null;
				if (this.activation.zcp_updated_by && this.activation.zcp_updated_by !== 0)
					this.editedByUser = this.usersService.getOne(this.activation.zcp_updated_by);

				this.keyWarnings = LicenseValidationTools.getKeyWarnings(this.activation, 'staff-enforced', 0, 0);

				if (this.keyWarnings.length === 0)
					this.nonEnforcedWarnings = LicenseValidationTools.getKeyWarnings(this.activation, 'staff-all', 0, 0);
				// this.gapWarnings = LicenseValidationTools.getKeyGapWarnings(this.activation);
				this.meterWarnings = LicenseValidationTools.getMeterCommTypeWarnings(this.activation);

				if (this.firstLoad && this.keyWarnings.length > 0 && this.authUser.role === 'admin') {
					const keyWarningsWithCode = LicenseValidationTools.getKeyWarnings(this.activation, 'staff-enforced', 0, 0, false);

					// console.log(keyWarningsWithCode);
					let soundToPlay: string = 'Sad_Trombone-Joe_Lamb-665429450.mp3';
					for (const w of keyWarningsWithCode) {
						if (w.startsWith(AppConstants.keyWarningPrefixKeyExpiring + ':')
							|| w.startsWith(AppConstants.keyWarningPrefixMeterExpiring + ':'))
							soundToPlay = 'dun-dun-dun-sound-effect-brass_8nFBccR.mp3';

						else if (w.startsWith(AppConstants.keyWarningPrefixMeterMaximum + ':')
							|| w.startsWith(AppConstants.keyWarningPrefixMeterProjected + ':')
							|| w.startsWith(AppConstants.keyWarningPrefixMeterMaximumEndOfMonth + ':')
							|| w.startsWith(AppConstants.keyWarningPrefixMeterProjectedEndOfMonth + ':'))
							soundToPlay = 'Woop Woop-SoundBible.com-198943467.mp3';
					} // for

					// Sad_Trombone-Joe_Lamb-665429450.mp3
					// 'dun-dun-dun-sound-effect-brass_8nFBccR.mp3';
					// let soundToPlay: string = 'Woop Woop-SoundBible.com-198943467.mp3';

					this.playSound(soundToPlay);
				} // if

				// for (const warning of this.keyWarnings)
				// 	if (warning.includes(AppConstants.keyWarningUsageBeforeEOMKeyword))
				// 		this.showTimeIntoMonth = true;

				this.marketplaceLogs = [];
				this.marketplaceLabel = '';
				this.marketplaceAccountIdentifier = '';
				this.marketplaceProducts = [];
				this.lastMarketplaceUsageReported = null;
				this.selectedMarketPlaceProtocolSet = null;
				if (this.activation.marketplace != null && this.activation.marketplace.marketplace !== ''
					&& this.activation.marketplace.accountIdentifier && this.activation.marketplace.accountIdentifier !== ''
					&& this.activation.marketplace.productIdentifiers && this.activation.marketplace.productIdentifiers.length > 0) {

					this.marketplaceAccountIdentifier = this.activation.marketplace.accountIdentifier;
					this.marketplaceLabel = this.activation.marketplace.marketplace;
					let productSelections: any[] = [];
					const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', this.activation.marketplace.marketplace);
					if (idx1 !== -1) {
						this.marketplaceLabel = AppConstants.marketPlaceSelections[idx1].label + ' (' + this.activation.marketplace.marketplace + ')';
						productSelections = AppConstants.marketPlaceSelections[idx1].selections;
						this.marketPlaceAccountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
					} // if

					for (const prodCode of this.activation.marketplace.productIdentifiers) {
						const idx = MiscTools.findIndexGeneric(productSelections, 'productCode', prodCode);
						if (idx === -1)
							this.marketplaceProducts.push('Unknown product code (' + prodCode + ')');
						else
							this.marketplaceProducts.push(productSelections[idx].label + '(' + prodCode + ')');
					} // for

					if (this.activation.marketplace.latestReport != null)
						this.lastMarketplaceUsageReported = new Date(this.activation.marketplace.latestReport);
					if (this.lastMarketplaceUsageReported != null && isNaN(this.lastMarketplaceUsageReported.getTime())) this.lastMarketplaceUsageReported = null;

					this.canReportToMarketplace = ValidationTools.checkAccess(this.authUser, 'report-marketplace-usage');

					let protocolSetId: number = +this.activation.marketplace.protocolSetId;
					if (isNaN(protocolSetId)) protocolSetId = 0;
					if (protocolSetId !== 0)
						this.selectedMarketPlaceProtocolSet = await this.licensingAdminService.getProtocolSet(protocolSetId);

					this.manualMarketplaceForm = new UntypedFormGroup({
						amountGB: new UntypedFormControl('', [Validators.required]),
						reportStart: new UntypedFormControl('', [Validators.required]),
						reportEnd: new UntypedFormControl('', [Validators.required])
					});
				} // if

				if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
					document.getElementById(this.tabID).click();
					this.tabID = '';
				} // if

				this.checkCompliance(-1, false);

				this.searchForm = new UntypedFormGroup({
					hostidFilter: new UntypedFormControl(this.hostidFilter)
				});

				// get usage data
				if (this.showMeters) {
					this.monthlyMeterUsage = await this.licensingService.getActivationMonthlyUsage(this.id, 'meter-data');
					await this.workoutMeterUsage();
					this.showMeterTraffic = this.meterProductsToShow.length !== 0;
				}  // if

				if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
					document.getElementById(this.tabID).click();
					this.tabID = '';
				} // if

				if (this.protocolReady) {
					this.monthlyProtocolUsage = await this.licensingService.getActivationMonthlyUsage(this.id, 'protocol-data', AppConstants.groupByBillingCodeToken);
					await this.workoutProtocolUsage();
					this.showProtocolTraffic = this.protocolProducts.length !== 0;

					this.monthlyChronoUsage = await this.licensingService.getActivationMonthlyUsage(this.id, 'protocol-time', AppConstants.groupByBillingCodeToken);
					await this.workoutChronoUsage();
					this.showChronoTraffic = this.chronoProducts.length !== 0;
				} // if

				if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
					document.getElementById(this.tabID).click();
					this.tabID = '';
				} // if

				// make up list of potential dataTypes
				// each meter type + all protocols, totals & protocol sets
				// pre-select each meter type + overall total or locked protocol sets (if any)

				const dataTypes: string[] = [];
				this.usageSummaryDataTypeSelections = [];
				for (const meterProduct of this.meterProductsToShow) {
					this.usageSummaryDataTypeSelections.push({
						value: 'meter:' + meterProduct,
						label: SharedLicenseTools.niceProtocol(meterProduct, true) + ' (M)'
					});
					dataTypes.push('meter:' + meterProduct);
				} // for
				for (const protocolProduct of this.protocolProductsToShow) {
					this.usageSummaryDataTypeSelections.push({
						value: 'protocol:' + protocolProduct,
						label: SharedLicenseTools.niceProtocol(protocolProduct, true) + ' (P)'
					});
				} // for

				for (const pSet of this.availableSets) {
					this.usageSummaryDataTypeSelections.push({
						value: 'protocol:protocolset-' + pSet.id,
						label: pSet.name + ' (PS)'
					});
				} // for

				if (dataTypes.length > 0)
					this.usageSummaryForm = new UntypedFormGroup({
						dataTypes: new UntypedFormControl(dataTypes, [Validators.required])
					});

				this.recentUsedHosts = [];
				if (this.showMeters || this.protocolReady)
					this.recentUsedHosts = await this.licensingService.getActiveHostIDs(this.id);

				this.lpUser = '';
				if (this.activation.user_id && this.activation.user_id !== 0)
					this.lpUser = await this.licensingService.getClassicLicenseUser(this.activation.user_id);

				this.keySnoozes = await this.licensingService.getUserKeySnoozesForKey(this.id);
				this.userSnoozes = {};
				for (const snooze of this.keySnoozes) {
					if (!this.userSnoozes[snooze.user_id]) this.userSnoozes[snooze.user_id] = [];
					this.userSnoozes[snooze.user_id].push(snooze);
				} // for

				await this.loadUserKeys();

				this.adminLogs = await this.adminLogsService.getLogs(['activation'], this.id);

				this.journals = await this.adminLogsService.getJournals('activation', this.id);
				if (this.journalsTable1)
					this.journalsTable1.updateContent(this.journals);

				this.marketplaceLogs = await this.marketplaceService.getMarketplaceLogs('activation', this.id);

				this.disableForm = new UntypedFormGroup({
					confirmation: new UntypedFormControl(null, [Validators.required])
				});

				// console.log('in load C pm=' + this.pageMode);

				if (this.usedProtocolBillingCodes.length > 0 || this.usedChronoBillingCodes.length > 0) {
					this.loading = false; // stop loading spinner at this point and let bc summary run (slow)
					this.bcSummaryByHostId = await this.licensingService.getBillingCodeSummaryForKeyByHostId(this.id);
				} // if

			} else if (this.pageMode === 'hostid' || this.pageMode === 'ip') {

				if (this.pageMode === 'hostid')
					this.keys = await this.licensingService.getHostActivations(this.hostid);
				else if (this.pageMode === 'ip')
					this.keys = await this.licensingService.getIPActivations(this.ip);

				this.hostKeys = {};

				if (this.pageMode === 'hostid')
					this.licenses = await this.licensingService.getHostLicenses(this.hostid);
				else if (this.pageMode === 'ip')
					this.licenses = await this.licensingService.getIPLicenses(this.ip);

				// newest first
				this.licenses.sort((a, b) => ((new Date(a.updated_at)).getTime() < (new Date(b.updated_at)).getTime()) ? 1 : -1);

				let hasKey = false;
				let hasUsage = false;
				this.showFileFootnote = false;
				for (const item of this.licenses) {
					if (this.latestID === 0) this.latestID = item.id;

					const licenseFileInfo = SharedLicenseTools.getLicenseFileInfo(item.fulfillment);
					this.licenseMeterInitials[item.id] = licenseFileInfo.meterInitials.join(',');
					this.licenseMeterNames[item.id] = licenseFileInfo.meterNames.join(',');
					this.licenseFormats[item.id] = licenseFileInfo.format;

					this.expiryModePerActivation[item.id] = '';
					this.meterTypesPerActivation[item.id] = '';

					if (item.activation_id && item.activation_id > 0) {
						const idx = MiscTools.findIndex(this.keys, item.activation_id);
						if (idx !== -1) {
							hasKey = true;
							this.hostKeys[item.id] = this.keys[idx];
							this.meterTypesPerActivation[item.id] = this.getMeterProductNamesForActivation(this.keys[idx]);
							this.expiryModePerActivation[item.id] = SharedLicenseTools.getExpiryMode(this.keys[idx]);

							if (!this.activationIDsForObject.includes(item.activation_id)) this.activationIDsForObject.push(item.activation_id);

							if (this.doesActivationSupportUsage(this.keys[idx]) && !this.activationIDsWithUsage.includes(item.activation_id))
								this.activationIDsWithUsage.push(item.activation_id);
						} // if
					} else {
						// if the most recent license issued isn't tied to a key
						// offer option to do it...
						if (this.pageMode === 'hostid' && this.latestID === item.id && ValidationTools.checkAccess(this.authUser, 'manage-keys'))
							this.showLinkOfflineLicense = true;
					} // if

					if (this.expiryModePerActivation[item.id] === 'meter')
						this.expiryExtraActivation[item.id] = 'Licenses issued for keys with meters will regularly update their expiration date as they report meter usage.';
					else
						this.expiryExtraActivation[item.id] = '';

					if (item.fulfillment && item.fulfillment !== '')
						this.showFileFootnote = true;

					if (this.pageMode === 'hostid' && (item.first_meter_report != null || item.first_protocol_report != null))
						hasUsage = true;
				} // for

				// get labels
				if (this.pageMode === 'hostid') {
					this.labels = await this.licensingService.getHostLabels(this.hostid);

					this.linkOfflineForm = new UntypedFormGroup({
						linkKey: new UntypedFormControl('', [Validators.required])
					});
				} // if

				if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
					document.getElementById(this.tabID).click();
					this.tabID = '';
				} // if

				if (this.pageMode === 'hostid')
					await this.getHostIdPenTests();
				else if (this.pageMode === 'ip')
					await this.getIPPenTests();

				// get usage data
				if (this.pageMode === 'hostid' && hasUsage) {
					this.loading = false;
					// if (this.activationIDsWithUsage.length > 0)
					// 	await this.filterTraffic(this.activationIDsWithUsage[0]);
					// else
					// 	await this.filterTraffic(0);

					await this.filterTraffic(this.filterID);

					this.invalidBillingCodeEntries = await this.licensingService.getInvalidBillingCodeCache(this.filterID, this.hostid, '');

					this.loading = false; // stop loading spinner at this point and let version history (slow) run it's course

					this.versionHistory = await this.licensingService.getVersionHistory(this.hostid);

					// console.log(this.versionHistory);
				} // if
			} else if (this.pageMode === 'aggregate') {
				const keysAndHostIds = localStorage.getItem('cp-licensing.keysAndHostIds');
				if (keysAndHostIds) this.keysAndHostIds = keysAndHostIds.trim();

				this.aggregateForm = new UntypedFormGroup({
					keysAndHostIds: new UntypedFormControl(this.keysAndHostIds)
				});

				this.aggregateSelectionForm = new UntypedFormGroup({});

				if (this.keysAndHostIds && this.keysAndHostIds !== '') {
					await this.processKeysAndHostIds();
				} //if
			} // if

			if (this.tabID && this.tabID !== '' && document.getElementById(this.tabID)) {
				document.getElementById(this.tabID).click();
				this.tabID = '';
			} // if

			this.workoutUsageBuffers();
		} catch (exc) {
			console.log(exc);
		}

		this.firstLoad = false;
		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-activation-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		if (this.marketPlaceLogsTable)
			this.marketPlaceLogsTable.updateContent(this.marketplaceLogs, 'cp-key-mktplace-logs', { showObject: false });
	}

	/*
		showDataValue
		hasNotStarted
		meterStatus
	
	*/



	// *********************************************************
	// for activation page mode
	// *********************************************************

	// *********************************************************
	async loadUserKeys() {
		this.userKeys = await this.licensingService.getKeyUsers(this.id);

		let canRegisterUserKeys = ValidationTools.checkAccess(this.authUser, 'manage-keys');;
		this.otherOrgUsers = [];
		if (canRegisterUserKeys && this.organization) {
			this.organizationForUsers = this.organization;
			const allOrgUsers = this.usersService.getUsersForOrganization(this.activation.zcp_org_id);
			for (const user of allOrgUsers) {
				if (user.is_enabled === 1) {
					const idx = MiscTools.findIndexGeneric(this.userKeys, 'user_id', user.id);
					if (idx === -1)
						this.otherOrgUsers.push(user);
				} // if
			} // for

			if (this.otherOrgUsers.length === 0 && this.organization && this.organization.parent_org_id && this.organization.parent_org_id !== 0) {
				this.organizationForUsers = this.organizationsService.getOne(this.organization.parent_org_id);
				const parentOrgUsers = this.usersService.getUsersForOrganization(this.organization.parent_org_id);
				for (const user of parentOrgUsers) {
					if (user.is_enabled === 1) {
						const idx = MiscTools.findIndexGeneric(this.userKeys, 'user_id', user.id);
						if (idx === -1)
							this.otherOrgUsers.push(user);
					} // if
				} // for
			} // if
		} // if
	}

	// *********************************************************
	getOverallPenTestCode(penTest: Models.BroadcasterPenTest[]) {
		let anyOpenToInternet: boolean = false;
		let anyNotOpenToInternet: boolean = false;

		for (const p of penTest) {
			if (p.test_code === 0) {
				return 0;
			} else if (p.test_code === 1) {
				anyOpenToInternet = true;
			} else if (p.test_code === 2) {
				anyNotOpenToInternet = true;
			} // if
		} // for

		if (anyOpenToInternet)
			return 1;
		else if (anyNotOpenToInternet)
			return 2;

		return -99;
	}

	// *********************************************************
	async getActivationPenTests() {
		this.allPenTests = await this.licensingService.getActivationPenTests(this.id);
		this.failedPenTests = [];
		this.failedPenTestIPs = [];

		for (const item of this.activation.licenses) {
			// see if this hostid + ip has a failed pen test...
			const idx = MiscTools.findIndexGenericDouble(this.allPenTests, 'hostid', item.hostid, 'ip', item.ip);
			if (idx !== -1) { // has at least on failed pen test
				const testsForThisHostIP: Models.BroadcasterPenTest[] = [];
				for (const p of this.allPenTests)
					if (p.activation_id === item.activation_id && item.hostid === p.hostid && item.ip === p.ip)
						testsForThisHostIP.push(p);

				for (const p of testsForThisHostIP) {
					if (p.test_code === 0) {
						if (!this.failedPenTestIPs.includes(p.ip)) this.failedPenTestIPs.push(p.ip);
						const idx2 = MiscTools.findIndexGenericTriple(this.failedPenTests, 'hostid', p.hostid, 'ip', p.ip, 'username', p.username);
						if (idx2 == -1) this.failedPenTests.push(p);
					} // if
				} // for

				if (this.hostIdCounts[item.hostid] === 1 || this.licenseCounters[item.id] === this.hostIdCounts[item.hostid]) {
					item['__penTest'] = testsForThisHostIP;
					item['__penTestCode'] = this.getOverallPenTestCode(testsForThisHostIP);
					item['__penTestLabel'] = AppConstants.penTestCodes[item['__penTestCode']].label;
				} // if
			} // if
		} // for
	} //

	// *********************************************************
	async getHostIdPenTests() {
		this.allPenTests = await this.licensingService.getHostIdPenTests(this.hostid);
		this.failedPenTests = [];
		this.failedPenTestIPs = [];
		for (const item of this.licenses) {
			const idx = MiscTools.findIndexGenericTriple(this.allPenTests, 'activation_id', item.activation_id, 'hostid', item.hostid, 'ip', item.ip);
			if (idx !== -1 && item.product.startsWith('broadcaster')) { // has at least on failed pen test
				const testsForThisHostIP: Models.BroadcasterPenTest[] = [];
				for (const p of this.allPenTests)
					if (p.activation_id === item.activation_id && item.hostid === p.hostid && item.ip === p.ip)
						testsForThisHostIP.push(p);

				for (const p of testsForThisHostIP) {
					if (p.test_code === 0) {
						if (!this.failedPenTestIPs.includes(p.ip)) this.failedPenTestIPs.push(p.ip);
						const idx2 = MiscTools.findIndexGenericTriple(this.failedPenTests, 'hostid', p.hostid, 'ip', p.ip, 'username', p.username);
						if (idx2 == -1) this.failedPenTests.push(p);
					} // if
				} // for

				item['__penTest'] = testsForThisHostIP;
				item['__penTestCode'] = this.getOverallPenTestCode(testsForThisHostIP);
				item['__penTestLabel'] = AppConstants.penTestCodes[item['__penTestCode']].label;
			} // if
		} // for
	} //

	// *********************************************************
	async getIPPenTests() {
		this.allPenTests = await this.licensingService.getIPPenTests(this.ip);
		this.failedPenTests = [];
		this.failedPenTestIPs = [];
		for (const item of this.licenses) {
			const idx = MiscTools.findIndexGenericTriple(this.allPenTests, 'activation_id', item.activation_id, 'hostid', item.hostid, 'ip', item.ip);
			if (idx !== -1 && item.product.startsWith('broadcaster')) { // has at least on failed pen test
				const testsForThisHostIP: Models.BroadcasterPenTest[] = [];
				for (const p of this.allPenTests)
					if (p.activation_id === item.activation_id && item.hostid === p.hostid && item.ip === p.ip)
						testsForThisHostIP.push(p);

				for (const p of testsForThisHostIP) {
					if (p.test_code === 0) {
						if (!this.failedPenTestIPs.includes(p.ip)) this.failedPenTestIPs.push(p.ip);
						const idx2 = MiscTools.findIndexGenericTriple(this.failedPenTests, 'hostid', p.hostid, 'ip', p.ip, 'username', p.username);
						if (idx2 == -1) this.failedPenTests.push(p);
					} // if
				} // for

				item['__penTest'] = testsForThisHostIP;
				item['__penTestCode'] = this.getOverallPenTestCode(testsForThisHostIP);
				item['__penTestLabel'] = AppConstants.penTestCodes[item['__penTestCode']].label;
			} // if
		} // for
	} //

	// *********************************************************
	async runPenTestOnIPs(ips: string[], username: string) {
		this.penTestRunning = true;

		try {
			await this.licensingService.runPenTestOnIPs(ips, username);

			if (this.pageMode === 'activation') {
				await this.getActivationPenTests();
			} else if (this.pageMode === 'hostid') {
				await this.getHostIdPenTests();
			} else if (this.pageMode === 'ip') {
				await this.getIPPenTests();
			} // if

			this.uiAlertsService.addMsg('Testing finished.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} catch (e) {
			if (e.message)
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			else
				this.uiAlertsService.addMsg('There was a problem', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		this.penTestRunning = false;
	} //

	// *********************************************************
	async shareKeyWithUser(userToShareWith: Models.User) {
		if (confirm('Are you sure you want to share this key with this user?')) {
			try {
				this.loading = true;
				await this.usersService.registerKeys(userToShareWith.id, [{ key: this.activation.key, label: '' }], false, 'zcp-staff-from-key-view');
				await this.loadUserKeys();
				this.uiAlertsService.addMsg('This key has been shared with ' + userToShareWith.name + '.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} catch (e) {
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
			this.loading = false;
		} // if
	} //

	// *********************************************************
	async doSearch() {
		this.hostidFilter = this.searchForm.value.hostidFilter;

		if (!this.hostidFilter || this.hostidFilter === '')
			return;

		this.searching = true;
		this.showSearchResults = false;

		const tmpSearchResults: Models.LPLicense[] = await this.licensingService.searchHosts(this.id, this.hostidFilter);

		if (tmpSearchResults && tmpSearchResults.length > AppConstants.maxLicenseRowsToFetch) {
			tmpSearchResults.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);
			tmpSearchResults.pop(); // remove the last one..
			// this.maxMessage = 'Maximum keys (' + AppConstants.maxLicenseRowsToFetch + ') retrieved.';
		} else {
			// this.maxMessage = '';
		}

		for (const item of tmpSearchResults) {
			const idx = MiscTools.findIndexGeneric(this.allPenTests, 'ip', item.ip);
			if (idx !== -1) {
				item['__penTest'] = this.allPenTests[idx];
				if (this.allPenTests[idx].test_code === 0) {
					const idx2 = MiscTools.findIndexGenericDouble(this.failedPenTests, 'hostid', item.hostid, 'ip', item.ip);
					if (idx2 == -1) this.failedPenTests.push(this.allPenTests[idx]);
					if (!this.failedPenTestIPs.includes(item.ip)) this.failedPenTestIPs.push(item.ip);
				} // if
			} // if
		} // for

		this.searchResults = tmpSearchResults;
		this.searching = false;
		this.showSearchResults = true;

		await MiscTools.delay(100);
		if (this.searchLicensesTable)
			this.searchLicensesTable.updateContent(this.searchResults, 'cp-staff-key-search-licenses', { staffMode: true, showMeters: this.showMeters, protocolReady: this.protocolReady });
	}

	// *********************************************************
	getNewLicenses() {
		const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + this.id + '/zip-new-licenses';
		window.open(url, '_blank');
	}

	// *********************************************************
	clone() {
		if (confirm('Are you sure you want to clone/copy this key?'))
			this.router.navigate(['/' + AppConstants.urls.licensing, this.id, 'clone']);
	}

	// *********************************************************
	async findPotentialOrganizations() {
		this.orgSearching = true;
		if (!this.organization) {
			this.orgLinkMatches = await this.licensingService.findPotentialOrganizations(this.id);
			if (this.orgLinkMatches.length === 0) this.showNoMatches = true;
		}
		this.showOrgLinkButton = false;
		this.orgSearching = false;
	}

	// *********************************************************
	async linkOrganization(orgID: number) {
		if (confirm('Are you confident that this key belongs to this Organization?')) {
			this.orgLinkMatches = [];

			await this.licensingService.linkKeyToOrganization(this.activation.key,
				orgID, 'manually-linked', true, this.activation.id);
			this.activation.zcp_link_status = 'manually-linked';
			this.activation.zcp_org_id = orgID;
			this.organization = this.organizationsService.getOne(this.activation.zcp_org_id);
		}
	}

	// *********************************************************
	async getSalesforceInfoBlocks() {
		this.sfFetching = true;
		if (this.organization && this.organization.salesforce_account_id && this.organization.salesforce_account_id !== '')
			this.salesforceAccountInfoBlock = await this.organizationsService.makeSalesForceObjectBlock('accounts', this.organization.salesforce_account_id);

		if (this.activation.opportunity_id && this.activation.opportunity_id !== '')
			this.salesforceOpportunityInfoBlock = await this.organizationsService.makeSalesForceObjectBlock('opportunities', this.activation.opportunity_id);

		this.sfFetching = false;
	}

	// *********************************************************
	async workoutHostIDProperties() {
		this.hostIdCounts = {};
		this.licenseCounters = {};
		this.licenseMeterNames = {};
		this.licenseMeterInitials = {};

		if (!this.activation || !this.activation.licenses || this.activation.licenses.length === 0)
			return;

		// sort licenses by created date (newest to oldest)
		this.activation.licenses.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);

		this.showMaxHostsExtra = false;
		if (this.activation.licenses.length > AppConstants.maxLicenseRowsToFetch) {
			this.showMaxHostsExtra = true;
			while (this.activation.licenses.length > AppConstants.maxLicenseRowsToFetch) {
				this.activation.licenses.pop();
			} // while
			this.fullLicenseCount = await this.licensingService.getNumLicenses(this.id);
		} // if

		let minRecentLicenseDays = 99;
		let recentCount = 0;

		this.activeLicenses = [];
		let tmpActive: Models.LPLicense[] = [];
		// go through all of them and count each hostid
		for (const item of this.activation.licenses) {
			const days = MiscTools.daysSince(item.created_at);
			if (days < AppConstants.maxDaysForRecentLicenses) recentCount++;
			if (days < minRecentLicenseDays) minRecentLicenseDays = days;

			if (!this.showMaxHostsExtra && SharedLicenseTools.licenseRecentlyReported(item, AppConstants.maxActiveLicenseDays))
				tmpActive.push(item);
		} // for

		if (this.showMaxHostsExtra && this.activation.discard_licenses === 0) {
			// call back end to get active licenses
			this.activeLicenses = await this.licensingService.getKeysActiveLicenses(this.id, AppConstants.maxActiveLicenseDays);
		} else {
			this.activeLicenses = tmpActive;
		} // if

		// go through them again and assign count for ones where count > 1

		// sort oldest to newest
		this.activation.licenses.sort((a, b) => ((new Date(a.created_at)).getTime() > (new Date(b.created_at)).getTime()) ? 1 : -1);

		const hostCounters: any = {};
		for (const item of this.activation.licenses) {
			if (this.hostIdCounts[item.hostid] > 1) {
				if (!hostCounters[item.hostid]) hostCounters[item.hostid] = 0;
				hostCounters[item.hostid]++;
				this.licenseCounters[item.id] = hostCounters[item.hostid];
			}  // if
		} // for

		this.showDownloadRecentButton = (recentCount < 50 && minRecentLicenseDays < AppConstants.maxDaysForRecentLicenses);

		for (const item of this.invalidBillingCodeEntries)
			if (MiscTools.daysSince(item.max_day, false) < 2)
				item['_active'] = true;

		await this.getActivationPenTests();
	}

	// *********************************************************
	async runLicenseReport() {
		await this.reportsService.runReport('KeyLicensesReport', 'id=' + this.id);
	}

	// *********************************************************
	async runKeyUsageSummaryReport() {
		const dataTypes: string[] = this.usageSummaryForm.value.dataTypes;

		if (dataTypes && dataTypes.length > 0) {
			await this.reportsService.runReport('KeyUsageSummaryReport', 'id=' + this.id
				+ '&dataTypes=' + encodeURIComponent(dataTypes.join(',')));
			if (document.getElementById("closeUsageSummaryModalButton"))
				document.getElementById("closeUsageSummaryModalButton").click();
		} // if
	}

	// *********************************************************
	async enableKey() {
		if (confirm('Are you sure you want to enable this key?')) {
			try {
				await this.licensingService.enableActivation(this.activation);
				this.activation.enabled = 1;
				this.uiAlertsService.addMsg('The key has been enabled.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} catch (e) {
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} // if

	}

	// *********************************************************
	async disableKey() {
		let confirmation = '';
		if (this.disableForm.value.confirmation) confirmation = this.disableForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'disable') {
			this.showDisableMsg = true;
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeDisableModalButton"))
			document.getElementById("closeDisableModalButton").click();

		try {
			await this.licensingService.disableActivation(this.activation);
			this.activation.enabled = 0;

			this.uiAlertsService.addMsg('The key has been disabled.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// *********************************************************
	async checkCompliance(templateId: number, doAlert: boolean = true) {
		this.checkingCompliance = true;

		if (templateId === -1 && this.template) templateId = this.template.id;
		if (this.template && templateId === this.template.id) this.isCompliant = false;

		const idx = MiscTools.findIndex(this.allTemplates, templateId);
		if (idx !== -1) {
			const templateComplianceIssues: string[] = LicenseValidationTools.checkActivation(this.activation, this.allTemplates[idx], this.keyProduct, this.keyProductProperties, this.organization, false, true);

			const idx2 = templateComplianceIssues.indexOf('The key\'s template ID does not match the template\'s ID.');
			if (idx2 !== -1) templateComplianceIssues.splice(idx2, 1);

			if (templateComplianceIssues.length === 0 && templateId === this.template.id)
				this.isCompliant = true;

			if (doAlert) {
				if (templateComplianceIssues.length > 0)
					this.uiAlertsService.addMsg('This key is NOT compliant with \'' + this.allTemplates[idx].name + '\'.\n\u2022 ' + templateComplianceIssues.join('\n\u2022 '), 'warning', 'compliance', true, AppConstants.standardMessageAutoCloseTimeSecs * 4);
				else
					this.uiAlertsService.addMsg('This key is compliant with \'' + this.allTemplates[idx].name + '\'.', 'success', 'compliance', true, AppConstants.standardMessageAutoCloseTimeSecs);

				await MiscTools.delay(1000);
			} // if
		}
		this.checkingCompliance = false;
	}

	// *********************************************************
	makeSnoozeTooltip(userId: number) {
		let tip = '';
		if (this.userSnoozes[userId].length > 0) {
			let snoozeTypes: string[] = [];
			for (const snooze of this.userSnoozes[userId]) {
				const exp = new Date(snooze.expires_at);
				let snoozeText = MiscTools.fetchLabel(AppConstants.keySnoozeTypes, snooze.snooze_type);
				if (snooze.expires_at != null && !isNaN(exp.getTime()))
					snoozeText += ' (expires ' + TextTools.formatDateNiceUTC(exp) + ')';
				snoozeTypes.push(snoozeText);
			} // for

			snoozeTypes = MiscTools.removeDuplicates(snoozeTypes)
			snoozeTypes.sort();
			tip = 'This user has snoozes/pauses of the following type(s) for this key: ' + snoozeTypes.join(', ');
		} // if
		return tip;
	}

	// *********************************************************
	getMarketPlaceProductName(log: Models.MarketplaceUsageReport) {
		const key = log.marketplace + ':' + log.product_id;
		if (!this.productCache[key]) {
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', log.marketplace);
			if (idx1 !== -1) {
				const idx2 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections[idx1].selections, 'productCode', log.product_id);
				if (idx2 !== -1)
					this.productCache[key] = AppConstants.marketPlaceSelections[idx1].selections[idx2].label;
			} // if
		} // if

		if (this.productCache[key])
			return this.productCache[key]

		return log.product_id;
	}

	// *********************************************************
	async manualMarketplaceKeyReport() {

		const errors: string[] = [];
		// check form elements to get args

		let amountGB: number = +this.manualMarketplaceForm.value.amountGB;
		let reportStart: Date = new Date(this.manualMarketplaceForm.value.reportStart + ' UTC');
		let reportEnd: Date = new Date(this.manualMarketplaceForm.value.reportEnd + ' UTC');

		if (isNaN(reportStart.getTime())) reportStart = null;
		if (isNaN(reportEnd.getTime())) reportEnd = null;

		if (isNaN(amountGB) || amountGB <= 0)
			errors.push('Amount (GB) must be a postive number.');

		if (reportStart == null)
			errors.push('Start of Report is not a valid date/time.');

		if (reportEnd == null)
			errors.push('End of Report is not a valid date/time.');

		if (errors.length > 0) {
			this.uiAlertsService.addMsgs(errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // if

		if (document.getElementById("closeManualMarketplaceReportModal"))
			document.getElementById("closeManualMarketplaceReportModal").click();

		try {
			const ret = await this.marketplaceService.manualKeyReport(this.id, reportStart, reportEnd, amountGB);

			this.uiAlertsService.addMsg('The usage has been reported.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// --------------------------------------------------------------------
	getCommercialTypeLabel(commercialType: string = '') {
		if (commercialType && commercialType !== '') {
			const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', commercialType);
			if (cIdx !== -1)
				return AppConstants.keyCommercialTypes[cIdx].label;
		} // if
		return commercialType;
	}

	// --------------------------------------------------------------------
	getCommercialTypeInfo(commercialType: string = '') {
		if (commercialType && commercialType !== '') {
			const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', commercialType);
			if (cIdx !== -1)
				return AppConstants.keyCommercialTypes[cIdx].info;
		} // if
		return commercialType;
	}

	// *********************************************************
	// for hostid page mode
	// *********************************************************

	// *********************************************************
	async filterTraffic(activationID: number) {
		if (this.loading) return;
		// if (activationID === this.filterID) activationID = 0; // toggle off

		this.loading = true;
		this.filterID = 0;
		this.filterKey = '';

		this.resetMeterUsage();
		this.resetProtocolUsage();
		this.resetTimeUsage();

		if (activationID > 0) {
			// find the key and  			
			const idx = MiscTools.findIndex(this.keys, activationID);
			if (idx !== -1) {
				this.filterID = activationID;
				this.filterKey = this.keys[idx].key;
			}
		}

		this.monthlyMeterUsage = await this.licensingService.getHostMonthlyUsage(this.hostid, 'meter-data', '', this.filterID);
		await this.workoutMeterUsage();

		this.monthlyProtocolUsage = await this.licensingService.getHostMonthlyUsage(this.hostid, 'protocol-data', AppConstants.groupByBillingCodeToken, this.filterID);
		await this.workoutProtocolUsage();

		this.monthlyChronoUsage = await this.licensingService.getHostMonthlyUsage(this.hostid, 'protocol-time', AppConstants.groupByBillingCodeToken, this.filterID);
		await this.workoutChronoUsage();

		if (!this.showMeterTraffic) this.showMeterTraffic = this.meterProductsToShow.length !== 0;
		if (!this.showProtocolTraffic) this.showProtocolTraffic = this.protocolProducts.length !== 0;

		this.loading = false;
	}

	// *********************************************************
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].label;
	}

	// *********************************************************
	downloadLicenseFiles(id: number, addHostID = false) {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/license/' + id;
		if (addHostID) url += '?addHostID=yes';
		window.open(url, '_blank');
	}

	// *********************************************************
	getMeterProductNamesForActivation(key: Models.LPActivation) {
		const names = [];
		for (const meter of key.meters) {
			const niceName = SharedLicenseTools.niceProtocol(meter.product, true);
			if (!names.includes(niceName)) names.push(niceName);
		}
		names.sort();
		return names.join(', ');
	}

	// *********************************************************
	doesActivationSupportUsage(key: Models.LPActivation) {
		const idx2 = MiscTools.findIndexGeneric(this.keyProducts, 'name', key.product);
		return (idx2 !== -1 && (this.keyProducts[idx2].show_meters > 0 || this.keyProducts[idx2].show_protocols > 0));
	}

	// *********************************************************
	async showLicenseNotes(id: number) {
		this.showSelectedKeyLicNotesCol = false;
		this.showSelectedKeyLicFeatures = false;

		this.selectedLicenseID = 0;

		if (this.selectedNotesLicenseID === id) {
			this.selectedNotesLicenseID = 0;
			return;
		}

		this.selectedNotesLicenseID = id;
		this.selectedKeyLicNotes = '';

		const licIdx = MiscTools.findIndex(this.licenses, id);
		if (licIdx !== -1)
			this.selectedKeyLicNotes = this.licenses[licIdx].notes;

		this.showSelectedKeyLicNotesCol = true;
	}

	// *********************************************************
	async showLicenseFeatures(id: number) {
		this.showSelectedKeyLicNotesCol = false;
		this.selectedNotesLicenseID = 0;
		this.showSelectedKeyLicFeatures = false;

		if (this.selectedLicenseID === id) {
			this.selectedLicenseID = 0;
			return;
		}

		this.selectedLicenseLoading = true;
		this.selectedLicenseID = id;

		// get this license...
		const licIdx = MiscTools.findIndex(this.licenses, id);
		if (licIdx !== -1) {
			const selectedKeyLicParsedParameters: any = SharedLicenseTools.parseRubyHash(this.licenses[licIdx].parameters);

			this.showSelectedKeyLicFeatures = true;
			this.selectedLicenseLoading = false;

			await MiscTools.delay(200);

			if (this.hostFeatures)
				this.hostFeatures.updateContent(selectedKeyLicParsedParameters, this.licenses[licIdx].product, { staffMode: true, hostMode: true });
		} // if
	}

	// *********************************************************
	// for aggregate page mode
	// *********************************************************

	async updateKeysAndHostIds() {

		// get check items...

		let newKeysAndHostIDs = '';

		let counter = 0;
		for (const activationIdAndHostIdsCombo of this.activationIdAndHostIdsCombos) {
			const currCheckValue = this.aggregateSelectionForm.value['select_' + counter];
			if (currCheckValue) {
				if (activationIdAndHostIdsCombo.hostid && activationIdAndHostIdsCombo.hostid !== '') {
					newKeysAndHostIDs += this.getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'key')
						+ ':' + activationIdAndHostIdsCombo.hostid
						+ '\n';
				} else {
					newKeysAndHostIDs += this.getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'key') + '\n';
				}
			}
			counter++;
		} // for

		this.aggregateForm.controls.keysAndHostIds.setValue(newKeysAndHostIDs);
		await this.processKeysAndHostIds();
	}

	// *********************************************************
	async processKeysAndHostIds() {
		this.resolving = true;

		this.uiAlertsService.addMsg('Getting usage for items.', 'info', 'aggregate_resolve', false, AppConstants.standardMessageAutoCloseTimeSecs);

		this.aggregateSelectionForm = null;
		this.protocolBillingCodeToSearch = '';
		this.chronoBillingCodeToSearch = '';
		this.resolveProblems = [];
		this.keysAndHostIdsCombos = [];
		this.activationIdAndHostIdsCombos = [];
		this.resetMeterUsage();
		this.resetProtocolUsage();
		this.resetTimeUsage();
		this.keysAndHostIds = this.aggregateForm.value.keysAndHostIds;

		localStorage.setItem('cp-licensing.keysAndHostIds', this.keysAndHostIds);

		let hasHosts = false;
		let splitList: string[] = this.keysAndHostIds.split('\n');
		splitList = MiscTools.removeDuplicates(splitList);

		for (const splitItem of splitList) {
			if (splitItem.trim() !== '') {
				if (splitItem.includes(':')) {
					this.keysAndHostIdsCombos.push({
						key: TextTools.getLabel(splitItem).trim(),
						hostid: TextTools.getValue(splitItem).trim()
					});
					hasHosts = true;
				} else {
					this.keysAndHostIdsCombos.push({ key: splitItem.trim() });
				} // if
			} // if
		} // for

		// console.log(this.keysAndHostIdsCombos);

		// call backend to resolve keys and hostIDs to get activation objects and licenses
		// build array of { "activation_id": #### } or { "activation_id": ####, "hostid": "aaabbb111" }
		// not any that can't be resolved...
		if (this.keysAndHostIdsCombos.length > 0) {
			try {
				this.resolveData = await this.licensingService.gatherKeyAndHostInfo(this.keysAndHostIdsCombos);

				if (!this.resolveData.activations || this.resolveData.activations.length === 0) {
					this.resolveProblems.push('No resolved keys returned.');
				} else if (hasHosts && (!this.resolveData.licenses || this.resolveData.licenses.length === 0)) {
					this.resolveProblems.push('No resolved licenses returned.');
				} else {
					for (const keysAndHostIdsCombo of this.keysAndHostIdsCombos) {
						const idx1 = MiscTools.findIndexGeneric(this.resolveData.activations, 'key', keysAndHostIdsCombo.key);
						if (idx1 === -1) {
							this.resolveProblems.push('No resolved object for key (' + keysAndHostIdsCombo.key + ').');
						} else {
							const actID = this.resolveData.activations[idx1].id;
							let sortKey = this.resolveData.activations[idx1].product
								+ ':' + this.resolveData.activations[idx1].type
								+ ':' + this.resolveData.activations[idx1].info
								+ ':' + this.resolveData.activations[idx1].id;

							if (keysAndHostIdsCombo.hostid && keysAndHostIdsCombo.hostid !== '') {
								sortKey += ':' + keysAndHostIdsCombo.hostid;
								const idx2 = MiscTools.findIndexGenericDouble(this.resolveData.licenses, 'hostid', keysAndHostIdsCombo.hostid, 'activation_id', actID);
								if (idx2 === -1) {
									this.resolveProblems.push('No resolved object for key (' + keysAndHostIdsCombo.key + ') and hostid (' + keysAndHostIdsCombo.hostid + ').');
								} else {
									this.activationIdAndHostIdsCombos.push({ activation_id: actID, hostid: keysAndHostIdsCombo.hostid, sortKey });
								} // if
							} else {
								this.activationIdAndHostIdsCombos.push({ activation_id: actID, sortKey });
							} // if
						} // if
					} // for
				} // if

				this.activationIdAndHostIdsCombos.sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1);
			} catch (error) {
				this.resolveProblems.push(error.message);
			} // try

			if (this.resolveProblems.length > 0) {
				this.resolving = false;
				this.loading = false;
				return;
			} // if

			// put in check box (checked) for each activationIdAndHostIdsCombos
			const formElements: any = { select_all: new UntypedFormControl(true) };
			let counter = 0;
			for (const activationIdAndHostIdsCombo of this.activationIdAndHostIdsCombos) {
				formElements['select_' + counter] = new UntypedFormControl(true)
				counter++;
			} // for
			this.aggregateSelectionForm = new UntypedFormGroup(formElements);

			this.loading = true;

			this.monthlyMeterUsage = await this.licensingService.getAggregateMonthlyUsage(this.activationIdAndHostIdsCombos, 'meter-data', '');
			await this.workoutMeterUsage();

			this.monthlyProtocolUsage = await this.licensingService.getAggregateMonthlyUsage(this.activationIdAndHostIdsCombos, 'protocol-data', AppConstants.groupByBillingCodeToken);
			await this.workoutProtocolUsage();

			this.monthlyChronoUsage = await this.licensingService.getAggregateMonthlyUsage(this.activationIdAndHostIdsCombos, 'protocol-time', AppConstants.groupByBillingCodeToken);
			await this.workoutChronoUsage();

			if (!this.showMeterTraffic) this.showMeterTraffic = this.meterProductsToShow.length !== 0;
			if (!this.showProtocolTraffic) this.showProtocolTraffic = this.protocolProducts.length !== 0;
			if (!this.showChronoTraffic) this.showChronoTraffic = this.chronoProducts.length !== 0;
		} // if

		this.uiAlertsService.clearMsgByCode('aggregate_resolve');
		this.loading = false;
		this.resolving = false;
	} // processKeysAndHostIds

	// *********************************************************
	getFieldFromResolveData(activationID: number, field: string) {
		if (this.resolveData && this.resolveData.activations) {
			const idx = MiscTools.findIndexGeneric(this.resolveData.activations, 'id', activationID);
			if (idx !== -1) {
				if (field === 'key') {
					return this.resolveData.activations[idx].key;

				} else if (field === 'organization') {
					if (this.resolveData.activations[idx].zcp_org_id && +this.resolveData.activations[idx].zcp_org_id !== 0) {
						const org = this.organizationsService.getOne(+this.resolveData.activations[idx].zcp_org_id);
						if (org) return org.name;
					} // if
				} else if (field === 'info') {
					if (this.resolveData.activations[idx].info && this.resolveData.activations[idx].info !== '')
						return this.resolveData.activations[idx].info;
					else
						return this.resolveData.activations[idx].customer;

				} else if (field === 'product') {
					return this.niceKeyProduct(this.resolveData.activations[idx].product);
				} else if (field === 'type') {
					return TextTools.capitalizeFirstLetter(this.resolveData.activations[idx].type);
				} // if
			} // if
		} // if
		return '';
	}

	// *********************************************************
	getActivationFromResolveData(activationID: number) {
		if (this.resolveData && this.resolveData.activations) {
			const idx = MiscTools.findIndexGeneric(this.resolveData.activations, 'id', activationID);
			if (idx !== -1)
				return this.resolveData.activations[idx];
		} // if
		return null;
	}

	// ***************************************************************
	checkAllToggle() {
		const currCheckAllValue = this.aggregateSelectionForm.value['select_all'];
		// uncheck everything

		let counter = 0;
		for (const activationIdAndHostIdsCombo of this.activationIdAndHostIdsCombos) {
			this.aggregateSelectionForm.controls['select_' + counter].setValue(false);
			counter++;
		} // for

		if (currCheckAllValue) {
			counter = 0;
			for (const activationIdAndHostIdsCombo of this.activationIdAndHostIdsCombos) {
				this.aggregateSelectionForm.controls['select_' + counter].setValue(currCheckAllValue);
				counter++;
			} // for
		} // if
	}

	// *********************************************************
	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}

	// *********************************************************
	canDeleteJournalEntry(journal: Models.JournalEntry): boolean {
		return this.authUser.id === journal.added_by || this.authUser.role === AppConstants.adminUserRole;
	}

	// *********************************************************
	async deleteJournalEntry(journal: Models.JournalEntry) {
		if (confirm('Are you sure you want to delete this journal entry?')) {
			try {
				// this.saving = true;
				await this.adminLogsService.deleteJournal(journal.id);
				this.uiAlertsService.addMsg('The journal entry has been deleted.', 'success', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				const idx = MiscTools.findIndex(this.journals, journal.id);
				if (idx !== -1) this.journals.splice(idx, 1);
			} catch (e) {
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	}

	// *********************************************************
	makeUserNotificationSummary(user: Models.User): string {
		let summary: string = '';

		if (user.is_enabled === 0) {
			summary = 'User account is disabled.'

		} else if (!user.notifications) {
			summary = 'Notifications not configured.'

		} else {
			const settings: string[] = [];
			if (user.notifications.receiveKeyExpirationMessages)
				settings.push(AppConstants.notificationPropLabels.receiveKeyExpirationMessages + ' enabled.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveKeyExpirationMessages + ' <span class="text-danger">NOT enabled</span>.');


			if (user.notifications.receiveKeyUsageMessages)
				settings.push(AppConstants.notificationPropLabels.receiveKeyUsageMessages + ' enabled.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveKeyUsageMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveProjectedKeyUsageMessages)
				settings.push(AppConstants.notificationPropLabels.receiveProjectedKeyUsageMessages + ' enabled.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveProjectedKeyUsageMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveProtocolKeyUsageMessages)
				settings.push(AppConstants.notificationPropLabels.receiveProtocolKeyUsageMessages + ' enabled.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveProtocolKeyUsageMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveOfflineHostIDsMessages)
				settings.push(AppConstants.notificationPropLabels.receiveOfflineHostIDsMessages + ' enabled.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveOfflineHostIDsMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveKeysReportMessages)
				settings.push(AppConstants.notificationPropLabels.receiveKeysReportMessages + ' enabled (' + user.notifications.keysReportFrequency + ').');
			else
				settings.push(AppConstants.notificationPropLabels.receiveKeysReportMessages + ' <span class="text-danger">NOT enabled</span>.');


			summary = '\u2022 ' + settings.join('\n\u2022 ');
		} // if

		return summary;
	}

	// *********************************************************
	async openUsageReport(metricType: string) {
		let args = '';
		if (this.pageMode === 'activation') {
			args = 'id=' + this.id + '&hostid=';
		} else if (this.pageMode === 'hostid') {
			if (this.filterID !== 0)
				args = 'id=' + this.filterID;
			else
				args = 'id=0';
			args += '&hostid=' + encodeURIComponent(this.hostid);
		} // if

		args += '&metricType=' + encodeURIComponent(metricType);

		if (this.activationIdAndHostIdsCombos && this.activationIdAndHostIdsCombos.length > 0)
			await this.reportsService.runReportViaPost('LicenseUsageReport', args, this.activationIdAndHostIdsCombos);
		else
			await this.reportsService.runReport('LicenseUsageReport', args);
	}

	// *********************************************************
	workoutUsageBuffers() {
		if (this.pageMode === 'activation') {
			this.byDayMeterBuffer += 40; // make room for report button
			this.byDayProtocolBuffer += 40; // make room for report button
			this.byDayChronoBuffer += 40; // make room for report button

		} else if (this.pageMode === 'hostid') {
			this.byDayMeterBuffer += 40; // make room for report button
			this.byDayProtocolBuffer += 40; // make room for report button
			this.byDayChronoBuffer += 40; // make room for report button

		} else if (this.pageMode === 'aggregate') {

		} // if

		if (this.usedProtocolBillingCodes.length > 0) {
			this.byMonthProtocolBuffer += 40;
		} // if

		if (this.usedChronoBillingCodes.length > 0) {
			this.byMonthChronoBuffer += 40;
		} // if
	}

	// *********************************************************
	// everything after this point should be identical between the staff and non-staff versions
	// *********************************************************

	// *********************************************************
	async selectMeterDayToFocus(product: string, year: number, month: number) {
		if (this.timeMode !== 'month') return;

		if (this.byDayMeterProduct !== product || this.byDayMeterMonth !== month || this.byDayMeterYear !== year) {
			this.byDayMeterLoading = true;
			this.byDayMeterProduct = product;
			this.byDayMeterMonth = month;
			this.byDayMeterYear = year;
			this.byDayMeterTotal = 0;
			this.byDayMeterAvgAbsChg = 0;
			this.byDayMeterUsage = [];

			if (this.staffMode) {
				if (this.pageMode === 'activation')
					this.byDayMeterUsage = await this.licensingService.getActivationDailyUsageForMonth(this.id, product, year, month);
				else if (this.pageMode === 'hostid')
					this.byDayMeterUsage = await this.licensingService.getHostDailyUsageForMonth(this.hostid, product, year, month, '', this.filterID);
				else if (this.pageMode === 'aggregate')
					this.byDayMeterUsage = await this.licensingService.getAggregateDailyUsage(this.activationIdAndHostIdsCombos, product, year, month, '');
			} else {
				if (this.pageMode === 'activation')
					this.byDayMeterUsage = await this.myKeysService.getActivationDailyUsageForMonth(this.userKey, product, year, month);
				else if (this.pageMode === 'hostid')
					this.byDayMeterUsage = await this.myKeysService.getHostDailyUsageForMonth(this.userKey, this.hostid, product, year, month);
			} // if

			if (this.showNoUseDays) {
				const now = new Date();
				let firstDayOfMonth = 1;
				let lastDayOfMonth = MiscTools.daysInMonth(month, year);

				// adjust if month when key was created...
				if (this.pageMode === 'activation') {
					const createdDate: Date = new Date(this.activation.created_at);
					if (year === createdDate.getUTCFullYear() && month === (createdDate.getUTCMonth() + 1))
						firstDayOfMonth = createdDate.getUTCDate();
				} // if

				// adjust if current month...
				if (year === now.getUTCFullYear() && month === (now.getUTCMonth() + 1))
					lastDayOfMonth = now.getUTCDate() - 1;

				// adjust if last month of reported data...
				if (this.pageMode === 'activation' && this.activation.last_meter_report) {
					const lastReport = new Date(this.activation.last_meter_report);
					if (lastReport && !isNaN(lastReport.getTime())
						&& year === lastReport.getUTCFullYear()
						&& month === (lastReport.getUTCMonth() + 1))
						lastDayOfMonth = lastReport.getUTCDate();
				} // if

				for (let i = firstDayOfMonth; i <= lastDayOfMonth; i++)
					if (MiscTools.findIndexGeneric(this.byDayMeterUsage, 'day', i) === -1)
						this.byDayMeterUsage.push(new Models.LPMeterReportByDay(0, '', product, year, month, i, 0));
			} // if

			this.byDayMeterUsage.sort((a, b) => (a.day > b.day) ? 1 : -1);

			for (const dayRec of this.byDayMeterUsage)
				this.byDayMeterTotal += +dayRec.used;

			if (this.byDayMeterUsage.length > 1) {
				let tempTotal = 0;
				for (let i = 1; i < this.byDayMeterUsage.length; i++)
					if (this.byDayMeterUsage[i - 1].used !== 0)
						tempTotal += Math.abs((this.byDayMeterUsage[i].used - this.byDayMeterUsage[i - 1].used)
							/ this.byDayMeterUsage[i - 1].used);
				this.byDayMeterAvgAbsChg = tempTotal / (this.byDayMeterUsage.length - 1);
			} // if

			this.byDayMeterLoading = false;
		}
	}

	// *********************************************************
	async selectProtocolDayToFocus(product: string, year: number, month: number) {
		if (this.timeMode !== 'month') return;

		let productToFetch = product;
		if (product.startsWith('total')) {
			productToFetch = 'calculated_' + product;
		} // if

		if (this.byDayProtocolProduct !== productToFetch || this.byDayProtocolMonth !== month || this.byDayProtocolYear !== year) {
			this.byDayProtocolLoading = true;
			this.byDayProtocolProduct = product;
			this.byDayProtocolMonth = month;
			this.byDayProtocolYear = year;
			this.byDayProtocolTotal = 0;
			this.byDayProtocolAvgAbsChg = 0;
			this.byDayProtocolUsage = [];

			if (this.staffMode) {
				if (this.pageMode === 'activation')
					this.byDayProtocolUsage = await this.licensingService.getActivationDailyUsageForMonth(this.id, productToFetch, year, month, this.protocolBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayProtocolUsage = await this.licensingService.getHostDailyUsageForMonth(this.hostid, productToFetch, year, month, this.protocolBillingCodeToSearch, this.filterID);
				else if (this.pageMode === 'aggregate')
					this.byDayProtocolUsage = await this.licensingService.getAggregateDailyUsage(this.activationIdAndHostIdsCombos, productToFetch, year, month, this.protocolBillingCodeToSearch);
			} else {
				if (this.pageMode === 'activation')
					this.byDayProtocolUsage = await this.myKeysService.getActivationDailyUsageForMonth(this.userKey, productToFetch, year, month, this.protocolBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayProtocolUsage = await this.myKeysService.getHostDailyUsageForMonth(this.userKey, this.hostid, productToFetch, year, month, this.protocolBillingCodeToSearch);
			} // if

			if (this.showNoUseDays) {
				const now = new Date();
				let firstDayOfMonth = 1;
				let lastDayOfMonth = MiscTools.daysInMonth(month, year);

				// adjust if month when key was created...
				if (this.pageMode === 'activation') {
					const createdDate: Date = new Date(this.activation.created_at);
					if (year === createdDate.getUTCFullYear() && month === (createdDate.getUTCMonth() + 1))
						firstDayOfMonth = createdDate.getUTCDate();
				} // if

				// adjust if current month...
				if (year === now.getUTCFullYear() && month === (now.getUTCMonth() + 1))
					lastDayOfMonth = now.getUTCDate() - 1;

				// adjust if last month of reported data...
				if (this.pageMode === 'activation' && this.activation.last_protocol_report) {
					const lastReport = new Date(this.activation.last_protocol_report);
					if (lastReport && !isNaN(lastReport.getTime())
						&& year === lastReport.getUTCFullYear()
						&& month === (lastReport.getUTCMonth() + 1))
						lastDayOfMonth = lastReport.getUTCDate();
				} // if

				for (let i = firstDayOfMonth; i <= lastDayOfMonth; i++)
					if (MiscTools.findIndexGeneric(this.byDayProtocolUsage, 'day', i) === -1)
						this.byDayProtocolUsage.push(new Models.LPMeterReportByDay(0, '', productToFetch, year, month, i, 0));
			} // if

			this.byDayProtocolUsage.sort((a, b) => (a.day > b.day) ? 1 : -1);

			for (const dayRec of this.byDayProtocolUsage)
				this.byDayProtocolTotal += +dayRec.used;

			if (this.byDayProtocolUsage.length > 1) {
				let tempTotal = 0;
				for (let i = 1; i < this.byDayProtocolUsage.length; i++)
					if (this.byDayProtocolUsage[i - 1].used !== 0)
						tempTotal += Math.abs((this.byDayProtocolUsage[i].used - this.byDayProtocolUsage[i - 1].used)
							/ this.byDayProtocolUsage[i - 1].used);
				this.byDayProtocolAvgAbsChg = tempTotal / (this.byDayProtocolUsage.length - 1);
			}

			this.byDayProtocolLoading = false;
		}
	}

	// *********************************************************
	async selectChronoDayToFocus(product: string, year: number, month: number) {
		if (this.timeMode !== 'month') return;

		let productToFetch = product;
		if (product.startsWith('total')) {
			productToFetch = 'calculated_' + product;
		} // if

		if (this.byDayChronoProduct !== productToFetch || this.byDayChronoMonth !== month || this.byDayChronoYear !== year) {
			this.byDayChronoLoading = true;
			this.byDayChronoProduct = product;
			this.byDayChronoMonth = month;
			this.byDayChronoYear = year;
			this.byDayChronoTotal = 0;
			this.byDayChronoAvgAbsChg = 0;
			this.byDayChronoUsage = [];

			if (this.staffMode) {
				if (this.pageMode === 'activation')
					this.byDayChronoUsage = await this.licensingService.getActivationDailyUsageForMonth(this.id, productToFetch, year, month, this.chronoBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayChronoUsage = await this.licensingService.getHostDailyUsageForMonth(this.hostid, productToFetch, year, month, this.chronoBillingCodeToSearch, this.filterID);
				else if (this.pageMode === 'aggregate')
					this.byDayChronoUsage = await this.licensingService.getAggregateDailyUsage(this.activationIdAndHostIdsCombos, productToFetch, year, month, this.chronoBillingCodeToSearch);

			} else {
				if (this.pageMode === 'activation')
					this.byDayChronoUsage = await this.myKeysService.getActivationDailyUsageForMonth(this.userKey, productToFetch, year, month, this.chronoBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayChronoUsage = await this.myKeysService.getHostDailyUsageForMonth(this.userKey, this.hostid, productToFetch, year, month, this.chronoBillingCodeToSearch);

			} // if

			if (this.showNoUseDays) {
				const now = new Date();
				let firstDayOfMonth = 1;
				let lastDayOfMonth = MiscTools.daysInMonth(month, year);

				// adjust if month when key was created...
				if (this.pageMode === 'activation') {
					const createdDate: Date = new Date(this.activation.created_at);
					if (year === createdDate.getUTCFullYear() && month === (createdDate.getUTCMonth() + 1))
						firstDayOfMonth = createdDate.getUTCDate();
				} // if

				// adjust if current month...
				if (year === now.getUTCFullYear() && month === (now.getUTCMonth() + 1))
					lastDayOfMonth = now.getUTCDate() - 1;

				// adjust if last month of reported data...
				if (this.pageMode === 'activation' && this.activation.last_protocol_report) {
					const lastReport = new Date(this.activation.last_protocol_report);
					if (lastReport && !isNaN(lastReport.getTime())
						&& year === lastReport.getUTCFullYear()
						&& month === (lastReport.getUTCMonth() + 1))
						lastDayOfMonth = lastReport.getUTCDate();
				} // if

				for (let i = firstDayOfMonth; i <= lastDayOfMonth; i++)
					if (MiscTools.findIndexGeneric(this.byDayChronoUsage, 'day', i) === -1)
						this.byDayChronoUsage.push(new Models.LPMeterReportByDay(0, '', productToFetch, year, month, i, 0));
			} // if

			this.byDayChronoUsage.sort((a, b) => (a.day > b.day) ? 1 : -1);

			for (const dayRec of this.byDayChronoUsage)
				this.byDayChronoTotal += +dayRec.used;

			if (this.byDayChronoUsage.length > 1) {
				let tempTotal = 0;
				for (let i = 1; i < this.byDayChronoUsage.length; i++)
					if (this.byDayChronoUsage[i - 1].used !== 0)
						tempTotal += Math.abs((this.byDayChronoUsage[i].used - this.byDayChronoUsage[i - 1].used)
							/ this.byDayChronoUsage[i - 1].used);
				this.byDayChronoAvgAbsChg = tempTotal / (this.byDayChronoUsage.length - 1);
			}

			this.byDayChronoLoading = false;
		}
	}

	// *********************************************************
	async selectProtocolBillingCodeFilter(id: number) {
		this.protocolBillingCodeToSearch = '';
		if (id === -1) {
			this.activeProtocolBillingCodeLabel = 'All usage data';
			this.activeProtocolBillingCodeShortLabel = '';
		} else if (id === 0) {
			this.activeProtocolBillingCodeLabel = 'Usage data with no billing code set';
			this.activeProtocolBillingCodeShortLabel = 'No billing code';
			this.protocolBillingCodeToSearch = AppConstants.noBillingCodeToken;
		} else {
			const idx = MiscTools.findIndex(this.usedProtocolBillingCodes, id)
			if (idx === -1)
				return this.selectProtocolBillingCodeFilter(-1);

			this.protocolBillingCodeToSearch = this.usedProtocolBillingCodes[idx].billing_code;
			this.activeProtocolBillingCodeShortLabel = this.usedProtocolBillingCodes[idx].billing_code;
			this.activeProtocolBillingCodeLabel = 'Usage data for '
				+ this.makeBillingCodeLabel(this.usedProtocolBillingCodes[idx]);
		} // if

		this.activeProtocolBillingCode = id;

		if (document.getElementById("closeProtocolBillingCodesModal"))
			document.getElementById("closeProtocolBillingCodesModal").click();

		this.loading = true;

		// new way - do b.c. filtering in front end
		this.byDayMeterProduct = '';
		this.byDayMeterUsage = [];

		this.byDayProtocolProduct = '';
		this.byDayProtocolUsage = [];

		this.byDayChronoProduct = '';
		this.byDayChronoUsage = [];

		await this.workoutProtocolUsage();
		this.loading = false;
	}

	// *********************************************************
	async selectChronoBillingCodeFilter(id: number) {
		this.chronoBillingCodeToSearch = '';
		if (id === -1) {
			this.activeChronoBillingCodeLabel = 'All usage data';
			this.activeChronoBillingCodeShortLabel = '';
		} else if (id === 0) {
			this.activeChronoBillingCodeLabel = 'Usage data with no billing code set';
			this.activeChronoBillingCodeShortLabel = 'No billing code';
			this.chronoBillingCodeToSearch = AppConstants.noBillingCodeToken;
		} else {
			const idx = MiscTools.findIndex(this.usedChronoBillingCodes, id)
			if (idx === -1)
				return this.selectChronoBillingCodeFilter(-1);

			this.chronoBillingCodeToSearch = this.usedChronoBillingCodes[idx].billing_code;
			this.activeChronoBillingCodeShortLabel = this.usedChronoBillingCodes[idx].billing_code;
			this.activeChronoBillingCodeLabel = 'Usage data for '
				+ this.makeBillingCodeLabel(this.usedChronoBillingCodes[idx]);
		}

		this.activeChronoBillingCode = id;

		if (document.getElementById("closeChronoBillingCodesModal"))
			document.getElementById("closeChronoBillingCodesModal").click();

		this.loading = true;

		// new way - do b.c. filtering in front end
		this.byDayMeterProduct = '';
		this.byDayMeterUsage = [];

		this.byDayProtocolProduct = '';
		this.byDayProtocolUsage = [];

		this.byDayChronoProduct = '';
		this.byDayChronoUsage = [];

		await this.workoutChronoUsage();
		this.loading = false;
	}

	// *********************************************************
	makeBillingCodeLabel(bc: Models.BillingCode, everything: boolean = true) {
		let label = bc.billing_code + ': ' + bc.label;
		if (!everything) label = bc.label;

		if (everything && this.staffMode && ['activation', 'aggregate', 'hostid'].includes(this.pageMode)) {
			const bcOrg = this.organizationsService.getOne(bc.zcp_org_id);
			if (bcOrg) {
				label += ' (' + bcOrg.name;
				// if (this.pageMode === 'activation') {
				// 	if (bcOrg.id === this.activation.zcp_org_id)
				// 		label += ' - Same as key\'s';
				// 	else
				// 		label += ' - Different from key\'s';
				// } // if
				label += ')';
			} else {
				label += ' (unknown organization - deleted? - ' + bc.zcp_org_id + ')';
			} // if
		} // if
		return label;
	}

	// *********************************************************
	resetMeterUsage() {
		this.monthlyMeterUsage = [];
		this.chunkedMeterUsage = [];

		this.byDayMeterProduct = '';
		this.byDayMeterMonth = 0;
		this.byDayMeterYear = 0;
		this.byDayMeterUsage = [];
		this.byDayMeterTotal = 0;
		this.byDayMeterAvgAbsChg = 0;
	}

	// *********************************************************
	resetProtocolUsage() {
		this.monthlyProtocolUsage = [];
		this.chunkedProtocolUsage = [];
		this.protocolProducts = [];
		this.protocolProductsToShow = [];
		this.protocolProductsVisible = [];
		this.protocolTimeChunks = [];
		this.protocolTimeChunksToShow = [];

		this.hasIncomingProtocolData = false;
		this.hasTranscodeProtocolData = false;
		this.hasOutgoingProtocolData = false;

		this.byDayProtocolProduct = '';
		this.byDayProtocolMonth = 0;
		this.byDayProtocolYear = 0;
		this.byDayProtocolUsage = [];
		this.byDayProtocolTotal = 0
		this.byDayProtocolAvgAbsChg = 0;
	}

	// *********************************************************
	resetTimeUsage() {
		this.monthlyChronoUsage = [];
		this.chunkedChronoUsage = [];
		this.chronoProducts = [];
		this.chronoProductsToShow = [];
		this.chronoTimeChunks = [];
		this.chronoTimeChunksToShow = [];
		this.chronoMonthTotals = {};

		this.hasIncomingChronoData = false;
		this.hasTranscodeChronoData = false;
		this.hasOutgoingChronoData = false;

		this.byDayChronoProduct = '';
		this.byDayChronoMonth = 0;
		this.byDayChronoYear = 0;
		this.byDayChronoUsage = [];
		this.byDayChronoTotal = 0
		this.byDayChronoAvgAbsChg = 0;
	}

	// *********************************************************
	async workoutMeterUsage() {
		this.meterProductsToShow = [];
		this.chunkedMeterUsage = [];
		this.monthlyProjectedAmounts = {};

		for (const record of this.monthlyMeterUsage) {
			if (record.used > 0) {
				if (!this.meterProductsToShow.includes(record.product))
					this.meterProductsToShow.push(record.product);

				const my = {
					month: +record.month,
					year: +record.year
				};

				if (['quarter', 'year'].includes(this.timeMode)) {
					if (this.timeMode === 'quarter')
						my.month = Math.ceil(+record.month / 3);
					else if (this.timeMode === 'year')
						my.month = -1;

					const idx = MiscTools.findIndexGenericTriple(this.chunkedMeterUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedMeterUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
					else
						this.chunkedMeterUsage[idx].used += +record.used;
				} else {
					this.chunkedMeterUsage.push(record);
				} // if
			} // if
		} // for

		this.meterProductsToShow.sort();
		if (this.pageMode === 'activation')
			this.meterProductsToShow = SharedLicenseTools.sortMeterTypesBasedOnCommercialType(this.activation.commercial_type, this.meterProductsToShow);

		const labelCounts: any = {};
		for (const product of this.meterProductsToShow) {
			const label = SharedLicenseTools.niceProtocol(product, false);
			if (!labelCounts[label]) labelCounts[label] = 0;
			labelCounts[label]++;
			if (labelCounts[label] > 1) this.showMeterTypeExtra = true;
		} // for

		if (this.timeMode === 'month' && this.pageMode === 'activation') {
			for (const record of this.chunkedMeterUsage) {
				this.monthlyProjectedAmounts[record.product + ':' + record.year + ':' + record.month] = SharedLicenseTools.getMeterProjectedForMonth(this.activation, record.product, record.month, record.year);
			} // for
		} // if
	}

	// *********************************************************
	getUniqueProtocolsWithUsage(usage: Models.LPMeterReportByMonth[], timeChunks: any[], allProtocolsUsed: string[]): string[] {
		const protocols: string[] = [];
		for (const p of allProtocolsUsed) {
			let pTotal: number = 0;

			for (const record of usage) {
				const idx = MiscTools.findIndexGenericDouble(timeChunks, 'year', +record.year, 'month', +record.month);
				if (idx !== -1 && record.product === p && record.used > 0)
					pTotal += +record.used;
			} // for
			if (pTotal > 0)
				protocols.push(p);
		} // for
		return protocols;
	}

	// *********************************************************
	async workoutProtocolUsage() {
		this.protocolProducts = [];
		this.protocolProductsToShow = [];
		this.protocolProductsVisible = [];
		this.protocolTimeChunks = [];
		this.protocolTimeChunksToShow = [];

		this.hasIncomingProtocolData = false;
		this.hasTranscodeProtocolData = false;
		this.hasOutgoingProtocolData = false;

		this.chunkedProtocolUsage = [];

		if (this.usedProtocolBillingCodes.length === 0 && this.activeProtocolBillingCode === -1) {
			const uniqueCodes: string[] = [];
			this.usedProtocolBillingCodes = [];

			for (const record of this.monthlyProtocolUsage)
				if (record.billing_code != null && record.billing_code !== '' && !uniqueCodes.includes(record.billing_code))
					uniqueCodes.push(record.billing_code);

			// for (const record of this.monthlyChronoUsage)
			// 	if (record.billing_code != null && record.billing_code !== '' && !uniqueCodes.includes(record.billing_code))
			// 		uniqueCodes.push(record.billing_code);

			// console.log('uniqueCodes');
			// console.log(uniqueCodes);
			if (uniqueCodes.length > 0) {
				if (this.staffMode)
					this.usedProtocolBillingCodes = await this.licensingService.getBillingCodes(uniqueCodes)
				else
					this.usedProtocolBillingCodes = await this.myKeysService.getBillingCodes(uniqueCodes)
			} // if

			// console.log('this.usedBillingCodes');
			// console.log(this.usedBillingCodes);
			for (const bc of this.usedProtocolBillingCodes)
				this.billingCodesByCode[bc.billing_code] = bc;
		} // if

		const usedSets: number[] = [];
		for (const record of this.monthlyProtocolUsage) {
			let passedFilter = true;
			if (this.activeProtocolBillingCode >= 0) {
				if (this.activeProtocolBillingCode === 0 && record.billing_code != null && record.billing_code !== '')
					passedFilter = false;
				if (this.activeProtocolBillingCode > 0 && this.protocolBillingCodeToSearch !== record.billing_code)
					passedFilter = false;
			} // if

			if (passedFilter && record.used > 0) {
				// based on view-mode : monthly, quarterly, annually
				// setup the my
				const my = {
					month: +record.month,
					year: +record.year
				};

				// if (['quarter', 'year'].includes(this.timeMode)) {
				if (this.timeMode === 'quarter')
					my.month = Math.ceil(+record.month / 3);
				else if (this.timeMode === 'year')
					my.month = -1;

				// do the sub-total for the protocol
				const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
				if (idx === -1)
					this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
				else
					this.chunkedProtocolUsage[idx].used += +record.used;

				// track used protocols
				if (!this.protocolProducts.includes(record.product))
					this.protocolProducts.push(record.product);

				if (MiscTools.findIndexGenericDouble(this.protocolTimeChunks, 'month', +my.month, 'year', +my.year) === -1)
					this.protocolTimeChunks.push(my);

				// do overall total
				const idxT = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total', 'year', +my.year, 'month', +my.month);
				if (idxT === -1)
					this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total', +my.year, +my.month, +record.used));
				else
					this.chunkedProtocolUsage[idxT].used += +record.used;

				// sub total for all in & out
				if (!record.product.includes('transcoded') && (record.product.endsWith('_in') || record.product.endsWith('_out'))) {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_inout', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_inout', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} // if

				// do sub-totals broken down by in/transcode/out
				if (record.product.includes('transcoded')) {
					this.hasTranscodeProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_transcoded', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_transcoded', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;

				} else if (record.product.endsWith('_in')) {
					this.hasIncomingProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_in', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_in', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} else if (record.product.endsWith('_out')) {
					this.hasOutgoingProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_out', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_out', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} // if

				// do protocol set totals...
				for (const protocolSet of this.selectedProtocolSets) {
					if (protocolSet.protocolsArr.includes(record.product)) {
						if (!usedSets.includes(protocolSet.id)) usedSets.push(protocolSet.id);
						const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
						if (idx === -1)
							this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
						else
							this.chunkedProtocolUsage[idx].used += +record.used;
					} // if
				} // for

				// do phantom protocol set totals...
				for (const protocolSet of this.phantomSets) {
					// check to see if this set is part of selectedProtocolSets
					if (MiscTools.findIndex(this.selectedProtocolSets, protocolSet.id) == -1 && protocolSet.protocolsArr.includes(record.product)) {
						const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
						if (idx === -1)
							this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
						else
							this.chunkedProtocolUsage[idx].used += +record.used;
					} // if
				} // for

				// do billing-code based totals...
				if (this.activeProtocolBillingCode === -1 && record.billing_code != null && record.billing_code !== '') {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'billing-code:' + record.billing_code, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + record.billing_code, +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} else {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'billing-code:' + AppConstants.noBillingCodeToken, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + AppConstants.noBillingCodeToken, +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} // if
			} // if
		} // for
		this.protocolProducts.sort(SharedLicenseTools.protocolSort);

		if (this.hasIncomingProtocolData) this.protocolProducts.push('total_in');
		if (this.hasTranscodeProtocolData) this.protocolProducts.push('total_transcoded');
		if (this.hasOutgoingProtocolData) this.protocolProducts.push('total_out');
		if (this.hasIncomingProtocolData || this.hasOutgoingProtocolData) this.protocolProducts.push('total_inout');
		if (this.protocolProducts.length > 0) this.protocolProducts.push('total');

		if (this.activeProtocolBillingCode === -1) {
			if (this.usedProtocolBillingCodes.length > 0)
				this.protocolProducts.push('billing-code:' + AppConstants.noBillingCodeToken);

			for (const bc of this.usedProtocolBillingCodes)
				this.protocolProducts.push('billing-code:' + bc.billing_code);
		} // if

		if (this.protocolSetsPosition === 'bottom') {
			for (const protocolSet of this.selectedProtocolSets)
				if (usedSets.includes(protocolSet.id))
					this.protocolProducts.push('protocol-set:' + protocolSet.id);
		} else {
			for (let i = this.selectedProtocolSets.length - 1; i >= 0; i--)
				if (usedSets.includes(this.selectedProtocolSets[i].id))
					this.protocolProducts.unshift('protocol-set:' + this.selectedProtocolSets[i].id);
		} // if

		if (this.pageMode === 'activation' && this.timeMode === 'month') {
			for (const linkedSet of this.activation.protocolSets) {
				if (linkedSet.projected && linkedSet.projected !== 0) {
					let totalAmount: number = 0;
					for (const timeChunk of this.protocolTimeChunks) {
						// get the amount for this month
						let amount: number = 0;
						const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'protocol-set:' + linkedSet.set_id, 'year', +timeChunk.year, 'month', +timeChunk.month);
						if (idx !== -1) amount = this.chunkedProtocolUsage[idx].used;

						totalAmount += +amount;

						let percentOfProjected: number = (+(amount / +linkedSet.projected * 100).toFixed(0));
						let overage: number = 0;
						if (amount > linkedSet.projected) overage = amount - +linkedSet.projected;

						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'pset-percent-used:' + linkedSet.set_id, +timeChunk.year, +timeChunk.month, percentOfProjected));
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'pset-overage:' + linkedSet.set_id, +timeChunk.year, +timeChunk.month, overage));
					} // for

					if (totalAmount > 0 && linkedSet.projected && linkedSet.projected > 0) {
						if (this.protocolSetsPosition === 'bottom') {
							this.protocolProducts.push('pset-percent-used:' + linkedSet.set_id);
							this.protocolProducts.push('pset-overage:' + linkedSet.set_id);
						} else {
							this.protocolProducts.unshift('pset-overage:' + linkedSet.set_id);
							this.protocolProducts.unshift('pset-percent-used:' + linkedSet.set_id);
						} // if
					} // if
				} // if
			} // for
		} // if

		if (this.timeMode === 'month') {
			if (this.protocolShowAllMonths) {
				this.protocolTimeChunksToShow = this.protocolTimeChunks;
				this.protocolProductsToShow = this.protocolProducts;
			} else {
				if (this.protocolTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
					this.protocolTimeChunksToShow = this.protocolTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
					this.protocolProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedProtocolUsage, this.protocolTimeChunksToShow, this.protocolProducts);
				} else {
					this.protocolTimeChunksToShow = this.protocolTimeChunks;
					this.protocolProductsToShow = this.protocolProducts;
				} // if
			} // if
		} else {
			this.protocolTimeChunksToShow = this.protocolTimeChunks;
			this.protocolProductsToShow = this.protocolProducts;
		} // if


		if (this.onlyShowTotals)
			this.protocolProductsVisible = this.getTotalProtcols(this.protocolProductsToShow);
		else
			this.protocolProductsVisible = this.protocolProductsToShow;

	}

	// *********************************************************
	async workoutChronoUsage() {
		this.chronoProducts = [];
		this.chronoProductsToShow = [];
		this.chronoTimeChunks = [];
		this.chronoTimeChunksToShow = [];
		this.chronoMonthTotals = {};

		this.hasIncomingChronoData = false;
		this.hasTranscodeChronoData = false;
		this.hasOutgoingChronoData = false;

		this.chunkedChronoUsage = [];

		if (this.usedChronoBillingCodes.length === 0 && this.activeChronoBillingCode === -1) {
			const uniqueCodes: string[] = [];
			this.usedChronoBillingCodes = [];

			for (const record of this.monthlyChronoUsage)
				if (record.billing_code != null && record.billing_code !== '' && !uniqueCodes.includes(record.billing_code))
					uniqueCodes.push(record.billing_code);

			// console.log('uniqueCodes');
			// console.log(uniqueCodes);
			if (uniqueCodes.length > 0) {
				if (this.staffMode)
					this.usedChronoBillingCodes = await this.licensingService.getBillingCodes(uniqueCodes)
				else
					this.usedChronoBillingCodes = await this.myKeysService.getBillingCodes(uniqueCodes)
			} // if

			// console.log('this.usedBillingCodes');
			// console.log(this.usedBillingCodes);

			for (const bc of this.usedChronoBillingCodes)
				this.billingCodesByCode[bc.billing_code] = bc;
		} // if

		const usedSets: number[] = [];
		for (const record of this.monthlyChronoUsage) {
			let passedFilter = true;
			if (this.activeChronoBillingCode >= 0) {
				if (this.activeChronoBillingCode === 0 && record.billing_code != null && record.billing_code !== '')
					passedFilter = false;
				if (this.activeChronoBillingCode > 0 && this.chronoBillingCodeToSearch !== record.billing_code)
					passedFilter = false;
			} // if

			if (passedFilter && record.used > 0) {
				const my = {
					month: record.month,
					year: record.year
				};

				// if (['quarter', 'year'].includes(this.timeMode)) {
				if (this.timeMode === 'quarter')
					my.month = Math.ceil(+record.month / 3);
				else if (this.timeMode === 'year')
					my.month = -1;

				const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
				if (idx === -1)
					this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
				else
					this.chunkedChronoUsage[idx].used += +record.used;
				// } else {
				// 	this.chunkedChronoUsage.push(record);
				// } // if

				if (!this.chronoProducts.includes(record.product))
					this.chronoProducts.push(record.product);

				if (MiscTools.findIndexGenericDouble(this.chronoTimeChunks, 'month', +my.month, 'year', +my.year) === -1) this.chronoTimeChunks.push(my);

				// do overall total
				const idxT = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
				if (idxT === -1)
					this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
				else
					this.chunkedChronoUsage[idxT].used += +record.used;

				// sub total for all in & out
				if (!record.product.includes('transcoded') && (record.product.endsWith('_in') || record.product.endsWith('_out'))) {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_inout' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_inout' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} // if

				// do sub-totals broken down by in/transcode/out
				if (record.product.includes('transcoded' + AppConstants.timeProtocolSuffix)) {
					this.hasTranscodeChronoData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_transcoded' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_transcoded' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;

				} else if (record.product.endsWith('_in' + AppConstants.timeProtocolSuffix)) {
					this.hasIncomingChronoData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_in' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_in' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} else if (record.product.endsWith('_out' + AppConstants.timeProtocolSuffix)) {
					this.hasOutgoingChronoData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_out' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_out' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} // if

				// do protocol set totals...
				for (const protocolSet of this.selectedChronoSets) {
					if (protocolSet.protocolsArr.includes(record.product)) {
						if (!usedSets.includes(protocolSet.id)) usedSets.push(protocolSet.id);
						const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
						if (idx === -1)
							this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
						else
							this.chunkedChronoUsage[idx].used += +record.used;
					} // if
				} // for

				// do billing-code based totals...
				if (this.activeChronoBillingCode === -1 && record.billing_code != null && record.billing_code !== '') {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'billing-code:' + record.billing_code, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + record.billing_code, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} // if
			} // if
		} // for

		this.chronoProducts.sort(SharedLicenseTools.protocolSort);

		if (this.hasIncomingChronoData) this.chronoProducts.push('total_in' + AppConstants.timeProtocolSuffix);
		if (this.hasTranscodeChronoData) this.chronoProducts.push('total_transcoded' + AppConstants.timeProtocolSuffix);
		if (this.hasOutgoingChronoData) this.chronoProducts.push('total_out' + AppConstants.timeProtocolSuffix);
		if (this.hasIncomingChronoData || this.hasOutgoingChronoData) this.chronoProducts.push('total_inout' + AppConstants.timeProtocolSuffix);
		if (this.chronoProducts.length > 0) this.chronoProducts.push('total' + AppConstants.timeProtocolSuffix);

		if (this.activeChronoBillingCode === -1)
			for (const bc of this.usedChronoBillingCodes)
				this.chronoProducts.push('billing-code:' + bc.billing_code);

		if (this.protocolSetsPosition === 'bottom') {
			for (const protocolSet of this.selectedChronoSets)
				this.chronoProducts.push('protocol-set:' + protocolSet.id);
		} else {
			for (let i = this.selectedChronoSets.length - 1; i >= 0; i--)
				this.chronoProducts.unshift('protocol-set:' + this.selectedChronoSets[i].id);
		} // if

		if (this.timeMode === 'month') {
			if (this.protocolShowAllMonths) {
				this.chronoTimeChunksToShow = this.chronoTimeChunks;
				this.chronoProductsToShow = this.chronoProducts;
			} else {
				if (this.chronoTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
					this.chronoTimeChunksToShow = this.chronoTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
					this.chronoProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedProtocolUsage, this.chronoTimeChunksToShow, this.chronoProducts);
				} else {
					this.chronoTimeChunksToShow = this.chronoTimeChunks;
					this.chronoProductsToShow = this.chronoProducts;
				} // if
			} // if
		} else {
			this.chronoTimeChunksToShow = this.chronoTimeChunks;
			this.chronoProductsToShow = this.chronoProducts;
		} // if
	}

	// *********************************************************
	pullUsageForMeterProduct(product: string) {
		const usage: Models.LPMeterReportByMonth[] = [];
		for (const record of this.chunkedMeterUsage)
			if (record.product === product && record.used > 0)
				usage.push(record);
		return usage;
	}

	// *********************************************************
	getUsageForProtocolForTimeChunk(product: string, year: number, month: number) {
		const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', product, 'year', year, 'month', month);
		if (idx !== -1)
			return this.chunkedProtocolUsage[idx].used;
		return 0;
	}

	// *********************************************************
	getUsageForChronoForTimeChunk(product: string, year: number, month: number) {
		const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', product, 'year', year, 'month', month);
		if (idx !== -1)
			return this.chunkedChronoUsage[idx].used;
		return 0;
	}

	// *********************************************************
	niceKeyType(activation: Models.LPActivation) {
		return (activation.type);
	}

	// *********************************************************
	toggleAxis() {
		this.rotateAxis = !this.rotateAxis;
		this.saveSettings();
	}

	// *********************************************************
	toggleShowNoUseDays() {
		this.showNoUseDays = !this.showNoUseDays;
		this.saveSettings();
	}

	// *********************************************************
	getDayToDayRatio(metricType: string, idx: number): number {
		if (idx > 0)
			if (metricType === 'meter-data' && this.byDayMeterUsage[idx - 1].used !== 0)
				return (this.byDayMeterUsage[idx].used - this.byDayMeterUsage[idx - 1].used)
					/ this.byDayMeterUsage[idx - 1].used;
			else if (metricType === 'protocol-data' && this.byDayProtocolUsage[idx - 1].used !== 0)
				return (this.byDayProtocolUsage[idx].used - this.byDayProtocolUsage[idx - 1].used)
					/ this.byDayProtocolUsage[idx - 1].used;
			else if (metricType === 'protocol-time' && this.byDayChronoUsage[idx - 1].used !== 0)
				return (this.byDayChronoUsage[idx].used - this.byDayChronoUsage[idx - 1].used)
					/ this.byDayChronoUsage[idx - 1].used;
		return 0;
	}

	// *********************************************************
	dailyRate(sizeMB: number) {
		if (sizeMB == null) return 0;
		const dailyRate = (sizeMB * 8) / (24 * 60 * 60);
		return dailyRate;
		// return Math.max(dailyRate, 0.1).toFixed(1);
	}

	// *********************************************************
	dayLabel(record: Models.LPMeterReportByDay) {
		const theDate: Date = new Date(record.year + '/' + record.month + '/' + record.day + ' 00:00:00 UTC');
		return record.day + ' - ' + TextTools.acronym(AppConstants.daysOfWeek[theDate.getUTCDay()]);
	}

	// *********************************************************
	productClass(product: string): string {
		let classes = 'border';
		// if (product.startsWith('total'))
		// 	classes += ' fw-bold';

		if (product.startsWith('billing-code:'))
			classes += ' cp-table-orange';
		else if (product.startsWith('protocol-set:') || product.startsWith('pset-percent-used:') || product.startsWith('pset-overage:'))
			classes += ' table-warning';
		else if (product.includes('transcoded'))
			classes += ' table-secondary';
		else if (product.endsWith('_in') || product.endsWith('_in' + AppConstants.timeProtocolSuffix))
			classes += ' table-primary';
		else if (product.endsWith('_out') || product.endsWith('_out' + AppConstants.timeProtocolSuffix))
			classes += ' table-info';
		else if (['total', 'total' + AppConstants.timeProtocolSuffix, 'total_inout', 'total_inout' + AppConstants.timeProtocolSuffix].includes(product))
			classes += ' table-success';

		// 1909694129

		return classes;
	}

	// *********************************************************
	hasMeterOfCertainType(meterProduct: string, resets: string) {
		const now = new Date();

		for (const meter of this.activation.meters) {
			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const exp = new Date(meter.expires_at);
			if (meter.product === meterProduct && meter.resets === resets
				&& meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
				return true;
			} // if
		} // for

		return false;
	}

	// *********************************************************
	niceProtocol(protocol: string, useShort: boolean = true) {
		let name = protocol;

		if (protocol.startsWith('billing-code:')) {
			if (protocol === 'billing-code:' + AppConstants.noBillingCodeToken) {
				name = 'No Code';

			} else if (useShort) {
				if (this.billingCodeDisplay === 'label')
					name = this.makeBillingCodeLabel(this.billingCodesByCode[TextTools.getValue(protocol)], false);
				else
					name = TextTools.getValue(protocol);
			} else {
				name = 'Billing Code: ' + TextTools.getValue(protocol);
			} // if

		} else if (protocol.startsWith('protocol-set:') || protocol.startsWith('pset-percent-used:') || protocol.startsWith('pset-overage:')) {
			const setId = +TextTools.getValue(protocol);
			const idx = MiscTools.findIndex(this.availableSets, setId);
			if (idx !== -1)
				name = this.availableSets[idx].name;

			if (!useShort) name = 'Protocol Set: ' + name;

			if (protocol.startsWith('pset-percent-used:'))
				name = '% of Projected for ' + name;
			else if (protocol.startsWith('pset-overage:'))
				name = 'Overage for ' + name;

			if (useShort) name = TextTools.chopString(name, 30, '...');

		} else {
			name = SharedLicenseTools.niceProtocol(protocol, useShort);
		} // if

		if (useShort)
			return name.replace(/\s/g, '\n');
		else
			return name;
	} // 

	// *********************************************************
	showDataValue(amt: number, product: string = '') {
		if (!amt || Math.abs(amt) < 0.1) return '0';

		const isNeg: boolean = amt < 0;
		const absAmt: number = Math.abs(amt);

		let ret: string = '';
		// limit decimal places
		if (this.showRawData)
			ret = TextTools.formatNumber(+Math.max(absAmt, 0.1).toFixed(1));
		else
			ret = TextTools.formattedMB(absAmt);

		if (isNeg)
			return ('-' + ret);
		else
			return (ret);

	}

	// *********************************************************
	makeExpDataTip(product: string, ym: any, amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = this.niceProtocol(product, false)
			+ ' - ' + this.makeTimeChunkHeader(ym.month, ym.year, false)
			+ ' : ';

		if (!product.startsWith('pset-percent-used:'))
			toolTip += this.makeDataTip(amt, canCopy, canClick);

		return toolTip;
	}

	// *********************************************************
	makeDataTip(amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = TextTools.formatNumber(Math.floor(amt)) + ' megabytes';

		if (canCopy) toolTip += ' - click to copy'

		if (canClick && this.timeMode === 'month')
			if (canCopy)
				toolTip += ' and expand'
			else
				toolTip += ' - click to expand'

		return toolTip;
	}

	// *********************************************************
	showChronoValue(amt: number) {
		if (!amt || amt < 0.1) return '0';

		// limit decimal places
		if (this.showRawData)
			return TextTools.formatNumber(+Math.max(amt, 0.1).toFixed(1)) + ' s';
		else
			return TextTools.formattedHours(amt) + ' h';
	}

	// *********************************************************
	makeExpChronoTip(product: string, ym: any, amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = this.niceProtocol(product, false)
			+ ' - ' + this.makeTimeChunkHeader(ym.month, ym.year, false)
			+ ' : ';
		toolTip += this.makeChronoTip(amt, canCopy, canClick);

		return toolTip;
	}

	// *********************************************************
	makeChronoTip(amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = TextTools.formattedHours(amt) + ' hours (' + TextTools.formatNumber(Math.floor(amt)) + ' s)';

		if (canCopy) toolTip += ' - click to copy'

		if (canClick && this.timeMode === 'month')
			if (canCopy)
				toolTip += ' and expand'
			else
				toolTip += ' - click to expand'

		return toolTip;
	}

	// *********************************************************
	toggleShowRaw() {
		this.showRawData = !this.showRawData;
		this.saveSettings();
	}

	// *********************************************************
	toggleTotalsOnly() {
		this.onlyShowTotals = !this.onlyShowTotals;
		this.saveSettings();

		if (this.onlyShowTotals)
			this.protocolProductsVisible = this.getTotalProtcols(this.protocolProductsToShow);
		else
			this.protocolProductsVisible = this.protocolProductsToShow;
	}

	// *********************************************************
	getTotalProtcols(protocols: string[]) {
		const justTotals: string[] = [];
		for (const protocol of protocols)
			if (['total', 'total_inout', 'total_in', 'total_transcoded', 'total_out',].includes(protocol)
				|| protocol.startsWith('billing-code:') || protocol.startsWith('protocol-set:')
				|| protocol.startsWith('pset-percent-used:') || protocol.startsWith('pset-overage:')
			)
				justTotals.push(protocol);
		return justTotals;
	}

	// *********************************************************
	changeTimeMode(mode: string) {
		const idx = MiscTools.findIndexGeneric(this.timeModes, 'value', mode);
		if (idx !== -1) {
			this.byDayMeterProduct = '';
			this.byDayMeterUsage = [];

			this.byDayProtocolProduct = '';
			this.byDayProtocolUsage = [];

			this.byDayChronoProduct = '';
			this.byDayChronoUsage = [];

			this.timeMode = mode;
			this.timeModeLabel = this.timeModes[idx].label;

			this.workoutMeterUsage();
			this.workoutProtocolUsage();
			this.workoutChronoUsage();
		} // if
	}

	// *********************************************************
	makeTimeChunkHeader(m: number, y: number, withBreak: boolean) {
		let header = '';

		if (this.timeMode === 'month')
			header = TextTools.getMonthName(m, true) + ' ' + y;
		else if (this.timeMode === 'quarter')
			header = y + ' Q' + m;
		else if (this.timeMode === 'year')
			header = y + '';

		if (withBreak) header = header.replace(/\s/g, '\n');

		return header;
	}

	// *********************************************************
	getProtocolSuperScripts(product: string, dataType: string) {
		if (product.startsWith('protocol-set:')) {
			const setId = +TextTools.getValue(product);

			if (dataType === 'protocol-data') {
				const idx = MiscTools.findIndex(this.selectedProtocolSets, setId);
				if (idx !== -1)
					return '#' + (idx + 1);
			} else if (dataType === 'protocol-time') {
				const idx = MiscTools.findIndex(this.selectedChronoSets, setId);
				if (idx !== -1)
					return '#' + (idx + 1);
			} // if
		} else if (!product.startsWith('total')) {
			let setsToCheck: Models.ProtocolSet[] = [];
			if (dataType === 'protocol-data')
				setsToCheck = this.selectedProtocolSets;
			else if (dataType === 'protocol-time')
				setsToCheck = this.selectedChronoSets;

			const nums: number[] = [];
			let counter = 0;
			for (const protocolSet of setsToCheck) {
				counter++;
				if (protocolSet.protocolsArr.includes(product))
					nums.push(counter);
			} // for

			if (nums.length > 0)
				return '#' + nums.join(' #');
		} // if
		return '';
	}

	// --------------------------------------------------------------------
	workoutMonthsProjected(record: Models.LPMeterReportByMonth) {
		let amt: number = 0;
		if (this.monthlyProjectedAmounts[record.product + ':' + record.year + ':' + record.month])
			amt = +this.monthlyProjectedAmounts[record.product + ':' + record.year + ':' + record.month];
		return amt
	} // 

	// --------------------------------------------------------------------
	workoutMonthsOverage(record: Models.LPMeterReportByMonth) {
		const proj: number = this.workoutMonthsProjected(record);
		if (proj !== 0 && record.used > proj) return record.used - proj;
		return 0;
	} // 

	// *********************************************************
	getProtocolPopoverLines(product: string, dataType: string): string[] {
		let lines: string[] = [];

		let setsToCheck: Models.ProtocolSet[] = [];
		if (dataType === 'protocol-data')
			setsToCheck = this.selectedProtocolSets;
		else if (dataType === 'protocol-time')
			setsToCheck = this.selectedChronoSets;

		if (product.startsWith('billing-code:')) {
			if (product === 'billing-code:' + AppConstants.noBillingCodeToken) {
				lines.push('Data reported that either has no billing code set OR the billing code\'s authentication code is invalid.');
			} else {
				lines.push('All data reported against this billing code.');
				if (this.billingCodesByCode[TextTools.getValue(product)])
					lines.push(this.makeBillingCodeLabel(this.billingCodesByCode[TextTools.getValue(product)]));
			}
		} else if (product.startsWith('protocol-set:')) {
			const setId = +TextTools.getValue(product);
			const idx = MiscTools.findIndex(setsToCheck, setId);
			if (idx !== -1) {
				lines.push('Protocol Set #' + (idx + 1));
				lines = lines.concat(PopOverTools.getProtocolSetPopoverLines(setsToCheck[idx], false));
			} // if

		} else if (product.startsWith('pset-percent-used:') || product.startsWith('pset-overage:')) {
			const setId = +TextTools.getValue(product);
			const linkedSet: Models.ActivationProtocolSet = MiscTools.pickItem(this.activation.protocolSets, 'set_id', setId);
			if (linkedSet && linkedSet.projected && linkedSet.projected > 0)
				lines.push('Projected Limit: ' + TextTools.formattedMB(linkedSet.projected));
			else
				lines.push('Projected Limit: ???');

		} else if (product.startsWith('total')) {
			lines.push('Calculated Total');

		} else {
			if (product.endsWith(AppConstants.timeProtocolSuffix))
				if (product.startsWith('mediaconnect_'))
					lines.push('MediaConnect related usage amount (time based) reported by ZEN Master');
				else if (product.startsWith('zm_'))
					lines.push('Non-MediaConnect related usage amount (time based) reported by ZEN Master');
				else
					lines.push('Usage amount (time based) reported by Zixi Broadcaster/ZEC');
			else
				if (product.startsWith('mediaconnect_'))
					lines.push('MediaConnect related usage amount (data based) reported by ZEN Master');
				else if (product.startsWith('zm_'))
					lines.push('Non-MediaConnect related usage amount (data based) reported by ZEN Master');
				else
					lines.push('Usage amount (data based) reported by Zixi Broadcaster/ZEC');

			let counter = 0;
			for (const protocolSet of setsToCheck) {
				counter++;
				if (protocolSet.protocolsArr.includes(product))
					lines.push('Included in Protocol Set # ' + counter + ': ' + protocolSet.name);
			} // for
		} // if

		return lines;
	}

	// *********************************************************
	async toggleSetsPosition() {
		if (this.protocolSetsPosition === 'top')
			this.protocolSetsPosition = 'bottom';
		else
			this.protocolSetsPosition = 'top';
		this.saveSettings();
		await this.workoutProtocolUsage();
		await this.workoutChronoUsage();
	}

	// *********************************************************
	async toggleBillingCodeDisplay() {
		if (this.billingCodeDisplay === 'code')
			this.billingCodeDisplay = 'label';
		else
			this.billingCodeDisplay = 'code';
		this.saveSettings();
	}

	// *********************************************************
	filterOutSelected(selectedSets: Models.ProtocolSet[]) {
		const filtered: Models.ProtocolSet[] = [];
		for (const protocolSet of this.availableSets)
			if (MiscTools.findIndex(selectedSets, protocolSet.id) === -1)
				filtered.push(protocolSet);
		return filtered;
	}

	// *********************************************************
	async toggleSessionSet(setId: number, dataType: string) {
		const idx1 = MiscTools.findIndex(this.availableSets, setId);
		if (idx1 !== -1) {
			if (dataType === 'protocol-data') {
				const idx2 = MiscTools.findIndex(this.sessionProtocolSets, setId);
				if (idx2 !== -1)
					this.sessionProtocolSets.splice(idx2, 1);
				else
					this.sessionProtocolSets.push(this.availableSets[idx1]);

				// save session protocol sets for this key
				localStorage.setItem('CLEAN.activationView.sessionProtocolSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionProtocolSets)));

				this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);

				await this.workoutProtocolUsage();

			} else if (dataType === 'protocol-time') {
				const idx2 = MiscTools.findIndex(this.sessionChronoSets, setId);
				if (idx2 !== -1)
					this.sessionChronoSets.splice(idx2, 1);
				else
					this.sessionChronoSets.push(this.availableSets[idx1]);

				// save session chrono sets for this key
				localStorage.setItem('CLEAN.activationView.sessionChronoSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionChronoSets)));

				this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);

				await this.workoutChronoUsage();
			} // if
		} // if
	} //

	// *********************************************************
	async pushToLockedSets(dataType: string) {
		this.loading = true;
		try {
			if (dataType === 'protocol-data') {
				if (this.sessionProtocolSets.length > 0) {
					for (const set of this.sessionProtocolSets) {
						await this.licensingService.addActivationProtocolSet(this.id, set.id, dataType);
						this.linkedProtocolSets.push(set);
					} // for
					this.sessionProtocolSets = [];
					localStorage.setItem('CLEAN.activationView.sessionProtocolSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionProtocolSets)));
					this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);
				} // if
			} else if (dataType === 'protocol-time') {
				if (this.sessionChronoSets.length > 0) {
					this.loading = true;
					for (const set of this.sessionChronoSets) {
						await this.licensingService.addActivationProtocolSet(this.id, set.id, dataType);
						this.linkedProtocolSets.push(set);
					} // for
					this.sessionChronoSets = [];
					localStorage.setItem('CLEAN.activationView.sessionChronoSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionChronoSets)));
					this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);
				} // if
			} // if
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		await this.workoutChronoUsage();
		await this.workoutProtocolUsage();
		this.loading = false;
	} //

	// *********************************************************
	async wipeLockedSets(dataType: string) {

		this.loading = true;
		try {
			if (dataType === 'protocol-data') {
				if (this.linkedProtocolSets.length > 0) {
					for (const set of this.linkedProtocolSets) {
						await this.licensingService.deleteActivationProtocolSet(this.id, set.id, dataType);
					} // for
					this.linkedProtocolSets = [];
					this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);
				} // if
			} else if (dataType === 'protocol-time') {
				if (this.linkedChronoSets.length > 0) {
					for (const set of this.linkedChronoSets) {
						await this.licensingService.deleteActivationProtocolSet(this.id, set.id, dataType);
					} // for
					this.linkedChronoSets = [];
					this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);
				} // if
			} // if
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
		await this.workoutChronoUsage();
		await this.workoutProtocolUsage();
		this.loading = false;
	} //

	// *********************************************************
	pullSetIds(sets: Models.ProtocolSet[]) {
		const ids: number[] = [];
		for (const protocolSet of sets)
			ids.push(protocolSet.id);
		return ids;
	}

	// *********************************************************
	async linkOfflineLicenseToKey() {

		const linkKey: string = this.linkOfflineForm.value.linkKey;

		// latestID: number = 0;
		// showLinkOfflineLicense = false;
		// linkOfflineForm: FormGroup;
		if (document.getElementById("closeLinkOfflineModal"))
			document.getElementById("closeLinkOfflineModal").click();

		try {
			const response: Models.LPActivation = await this.licensingService.linkLegacyLicenseToKey(this.latestID, linkKey);

			this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', response.id]);

		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// --------------------------------------------------------------------
	routeToKey(keyId: number) {
		const tabs = document.getElementsByClassName("nav-item nav-link active");
		let currentTab: string = '';
		if (tabs && tabs[0]) currentTab = tabs[0].id;

		if (currentTab && currentTab !== '')
			this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', keyId], { queryParams: { tabID: currentTab } });
		else
			this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', keyId]);
	}

	// --------------------------------------------------------------------
	toggleShowPhantom() {
		this.showMeterProtocolEquiv = !this.showMeterProtocolEquiv;
		this.saveSettings();
	}

	// --------------------------------------------------------------------
	getPhantomProduct(meterType: string) {
		if (this.phantomSetIds[meterType])
			return 'protocol-set:' + this.phantomSetIds[meterType];
		return '';
	}

	// --------------------------------------------------------------------
	phantomPercent(meterValue: number, protocolValue: number) {
		let percDiff: number = 0;
		if (meterValue === 0 && protocolValue === 0)
			percDiff = 0;
		else if (meterValue === 0 || protocolValue === 0)
			percDiff = 100;
		else
			percDiff = (meterValue - protocolValue) / meterValue * 100;

		if (percDiff !== 0 && percDiff !== 100)
			return percDiff.toFixed(1) + '%';
		else
			return percDiff.toFixed(0) + '%';
	}

	// --------------------------------------------------------------------
	toggleShowAllProtocolMonths() {
		this.protocolShowAllMonths = !this.protocolShowAllMonths;

		if (this.protocolShowAllMonths) {
			this.protocolTimeChunksToShow = this.protocolTimeChunks;
			this.protocolProductsToShow = this.protocolProducts;

			this.chronoTimeChunksToShow = this.chronoTimeChunks;
			this.chronoProductsToShow = this.chronoProducts;
		} else {
			if (this.protocolTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
				this.protocolTimeChunksToShow = this.protocolTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
				this.protocolProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedProtocolUsage, this.protocolTimeChunksToShow, this.protocolProducts);
			} else {
				this.protocolTimeChunksToShow = this.protocolTimeChunks;
				this.protocolProductsToShow = this.protocolProducts;
			} // if

			if (this.chronoTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
				this.chronoTimeChunksToShow = this.chronoTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
				this.chronoProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedChronoUsage, this.chronoTimeChunksToShow, this.chronoProducts);
			} else {
				this.chronoTimeChunksToShow = this.chronoTimeChunks;
				this.chronoProductsToShow = this.chronoProducts;
			} // if
		} // if

		if (this.onlyShowTotals)
			this.protocolProductsVisible = this.getTotalProtcols(this.protocolProductsToShow);
		else
			this.protocolProductsVisible = this.protocolProductsToShow;
	}

	// --------------------------------------------------------------------
	playSound(fileToPlay: string) {
		let volume: number = 5;
		if (localStorage.getItem(AppConstants.volumeStorageKey)) {
			volume = +localStorage.getItem(AppConstants.volumeStorageKey);
			if (isNaN(volume)) volume = 0;
		} // if

		// this.uiAlertsService.addMsg('Play sound.' + fileToPlay, 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		PopOverTools.playSound(fileToPlay, volume);
	}
}
